import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchProjectData,
  fetchProjectsCount,
} from "../store/project/projectActions";
import {
  getSaveStatus,
  getProjectStatus,
} from "../store/project/projectSelectors";
import { getUserData } from "../store/auth/authSelectors";
import OverviewPage from "./pages/overview-page/OverviewPage";
import AttractivenessMapPage from "./pages/view-pages/attractiveness-map-page/AttractivenessMapPage";
import OpportunitySetPage from "./pages/view-pages/opportunity-set-page/OpportunitySetPage";
import AgileFocusPage from "./pages/view-pages/agile-focus-page/AgileFocusPage";
import WorksheetPage1 from "./pages/worksheet-page/WorksheetPage1";
import WorksheetPage2 from "./pages/worksheet-page/WorksheetPage2";
import WorksheetPage3 from "./pages/worksheet-page/WorksheetPage3";
import All from "./pages/pdf/All";
import LoginPage from "./pages/login-page/LoginPage";
import SignupPage from "./pages/login-page/SignupPage";
import HelpPage from "./pages/helper/HelpPage";
import ResetPasswordPage from "./pages/login-page/ResetPasswordPage";
import ForgotPasswordPage from "./pages/login-page/ForgotPasswordPage";
import PrivacyPage from "./pages/login-page/PrivacyPage";
import TermsPage from "./pages/login-page/TermsPage";
import VideoPage from "./pages/video/VideoPage";
import "./App.scss";
import SignupViaInvitePage from "./pages/login-page/SignupViaInvitePage";
import GPTFeature from "./pages/gpt-feature";
import AITermsAndConditions from "./pages/ai-terms-and-conditions";

class App extends Component {
  componentDidMount() {
    window.addEventListener("beforeunload", this.onAppClose());

    const {
      onFetchProjectData,
      user,
      saveStatus,
      projectStatus,
      onFetchProjectCount,
    } = this.props;

    // onFetchProjectCount()

    if (user && user.user.role && user.user.role.type !== "admin") {
      // onFetchProjectData()
    } else if (
      user &&
      user.user.role &&
      user.user.role.type === "admin" &&
      saveStatus === "SAVED" &&
      projectStatus === "SAVED"
    ) {
      onFetchProjectData();
    }
  }

  onAppClose() {
    return function (e) {
      e.preventDefault();
    };
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={OverviewPage} />
        <Route
          exact
          path="/market-opportunity-set"
          component={OpportunitySetPage}
        />
        <Route
          exact
          path="/attractiveness-map"
          component={AttractivenessMapPage}
        />
        <Route exact path="/agile-focus-dartboard" component={AgileFocusPage} />
        <Route exact path="/worksheet-1" component={WorksheetPage1} />
        <Route exact path="/worksheet-2" component={WorksheetPage2} />
        <Route exact path="/worksheet-3" component={WorksheetPage3} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route
          exact
          path="/signup-via-invite/:email"
          component={SignupViaInvitePage}
        />
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/terms-and-conditions" component={TermsPage} />
        <Route exact path="/privacy-policy" component={PrivacyPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/all" component={All} />
        <Route exact path="/video" component={VideoPage} />
        <Route exact path="/gpt-feature" component={GPTFeature} />
        <Route
          exact
          path="/ai-terms-and-conditions"
          component={AITermsAndConditions}
        />
      </Switch>
    );
  }
}

const mapState = (state) => ({
  user: getUserData(state),
  saveStatus: getSaveStatus(state),
  projectStatus: getProjectStatus(state),
});

const mapDispatch = (dispatch) => ({
  onFetchProjectData: () => dispatch(fetchProjectData()),
  onFetchProjectCount: () => dispatch(fetchProjectsCount()),
});

export default withRouter(connect(mapState, mapDispatch)(App));
