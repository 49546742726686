import React from 'react';
import './CircleImage.scss';

const CircleImage = ({ activeBox, imgClass, index }) => {
  return (
    <span className='circle-image'>
      <span className={imgClass}>
        <span
          className={activeBox === index ? 'circle-active' : 'circle'}
        ></span>
        <span
          className={activeBox === index ? 'circle-active' : 'circle'}
          id='circle2'
        ></span>
      </span>
    </span>
  );
};

export default CircleImage;
