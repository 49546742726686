import React from "react";

function TimerBlueBar({ anim }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      version="1.1"
      viewBox="0 0 80 80"
      className="timer-blue-bar"
    >
      <path stroke="#00aeef" strokeWidth="3" d="M40 2a38 38 0 0138 38"></path>
    </svg>
  );
}

export default TimerBlueBar;