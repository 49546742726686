import React, { Component, Fragment } from 'react';
import autosize from 'autosize'

import './OpportunityContainer.scss';

class OpportunityContainer extends Component {
  componentDidMount() {
    if (this.props.opportunity) {
      autosize(document.getElementById(`${this.props.opportunity.title}`))
    } else {
      autosize(document.getElementById('newTexarea'))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunity !== this.props.opportunity) {
      autosize.update(document.getElementById(`${this.props.opportunity.title}`))
    }
  }

  render() {
    const {
      opportunity,
      onDeleteOpportunity,
      onOpportunityChange,
      onUpdateOpportunity,
      onNewOpportunity,
      onToggleNewOpportunity,
      overview,
      isWithoutNavigation,
      readonly
    } = this.props;

    return (
       <div className="opportunity-container">
         <div className="opportunity-container-wrapper">
           {opportunity ?
             <>
               {opportunity?.app && (
                 <div className="opportunity-app-title sticky-note__app">
                   {opportunity.app.title}
                 </div>
               )}
               {!isWithoutNavigation ?
                 <textarea
                   id={opportunity.title}
                   className='opportunity-title sticky-note__title'
                   spellCheck="false"
                   value={opportunity.title}
                   onChange={(e) => {
                     onOpportunityChange(e, opportunity.uid, opportunity.segmendUid)
                   }}
                   onBlur={(e) => {
                     onUpdateOpportunity(e, opportunity.uid, opportunity.segmendUid)
                   }}
                   placeholder="Type your opportunity"
                   maxLength="50"
                   disabled={readonly}
                 />
                 :
                 <div className='opportunity-title sticky-note__title'>
                   {opportunity.title}
                 </div>
               }
               { !overview && !readonly && <img className="delete-opportunity-button" onClick={() => onDeleteOpportunity(opportunity.uid)} src="/assets/icons/delete-icon.png" alt="delete" /> }
             </>
             :
             <>
               <textarea
                 id="newTexarea"
                 className='opportunity-title sticky-note__title empty'
                 spellCheck="false"
                 onBlur={(e) => {
                   onNewOpportunity(e)
                   onToggleNewOpportunity(false)
                   e.target.value = ''
                 }}
                 placeholder="Type your opportunity"
                 disabled={readonly}
               />
               <img className="delete-opportunity-button" onClick={() => onToggleNewOpportunity(false)} src="/assets/icons/delete-icon.png" alt="delete" />
             </>
           }
         </div>
       </div>
    );
  }
}

export default OpportunityContainer
