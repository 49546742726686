export const getCommentOriginName = (state) =>
  state.comment.commentOriginName;

export const getCommentCounts = (state) =>
  state.comment.commentCounts;

export const getCommentListData = (state) =>
  state.comment.commentListData;

export const getReplyListData = (state) =>
  state.comment.replyListData;