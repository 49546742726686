import React from 'react'
import './ProjectMembers.scss'
import Cross from '../../assets/svgs/Cross'
import {getUserData} from '../../store/auth/authSelectors'
import {connect} from 'react-redux'
import MembersMap from './MembersMap'
import {getProjectData} from "../../store/project/projectSelectors";
import {setAndSaveProjectData} from "../../store/project/projectActions";
import {getMemberRole} from "../../utils/helpers";

const ProjectMembers = ({members, setMembers, currentProject, onSetAndSaveProjectData, share, setShare, user}) => {

  const handleMemberRoleChange = (email, e) => {
    const member = currentProject.members.find(m => m.email === email);
    currentProject.members = currentProject.members.filter(m => m.email !== email);

    if (e.target.value !== 'remove') {
      member.role = e.target.value;
      currentProject.members.push(member);
    }

    onSetAndSaveProjectData('members', currentProject.members);
  };

  const canView = getMemberRole(currentProject, user.user.email) === 'canView';
  const canEdit = getMemberRole(currentProject, user.user.email) === 'canEdit';

  return (
    <div className="project-members">
      <div className={`${!members ? '' : 'members-only'}`}/>
      <div className="members-main-container">
        <div className="members-container-column">
          <div className="members-container-row">
            <div className="this-project-members share-this-project-members">Project members
              ({currentProject.members?.length})
            </div>
            <span onClick={() => setMembers(!members)} className="members-cross"><Cross/></span>
          </div>
          <div className="members-container-column">
            <div className="this-project-members project-name-members">
              {currentProject.title}
            </div>
          </div>
        </div>
        <div className="members-container-column members-map">
          {/* for members map function  */}
          {currentProject.members?.filter(m => m.name && m.role && m.email).map((each) => (
            <MembersMap key={each.email} name={each.name} email={each.email} role={each.role}
                        onRoleChange={handleMemberRoleChange}
                        user={user}
                        memberRole={getMemberRole(currentProject, user.user.email)}
            />))}
          {/* for members map function  */}
        </div>
        <div className="fixed-position">
          <div className="members-container-column btn-panel-height-mbr">
            <div className="btn-group">
              <button className={`members-btn-send ${(canView || canEdit) ? 'readonly' : ''}`} onClick={() => {
                setShare(!share);
                setMembers(false)
              }}>Invite other
              </button>
              <button onClick={() => setMembers(!members)} className="members-btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  user: getUserData(state),
  currentProject: getProjectData(state),
})

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
})

export default connect(mapState, mapDispatch)(ProjectMembers)
