import { apiUpdateProjectData } from "../../api/api";
import {
  updateProjectDataStart,
  updateProjectDataSuccess,
} from "./projectActions";
import { getProjectData, getSaveStatus } from "./projectSelectors";

export const SET_CHAT_SESSION_FIELD = "SET_CHAT_SESSION_FIELD";

export const SET_CHAT_FIELD = "SET_CHAT_FIELD";

export const IS_CHAT_TYPING = "isTyping";

export const IS_CHAT_ACTIVE = "isChatActive";

export const IS_CHAT_OPENER_ACTIVE = "isChatOpenerActive";

export const CHAT_INPUT_MSG = "inputMsg";

export const CHAT_SESSION_MESSAGES = "sessionChats";

export const CHAT_OLD_MESSAGES = "chats";

export const CHAT_USED_GPT_TOKENS = "usedGptTokens";

export const SET_INITIAL_SESSION_CHAT = "SET_INITIAL_SESSION_CHAT";

export const SET_INITIAL_CHAT = "SET_INITIAL_CHAT";

export const PROJECT_DELETE_CHATS_AND_SESSION =
  "PROJECT_DELETE_CHATS_AND_SESSION";

export const PROJECT_GENERATE_CHATS_AND_SESSION =
  "PROJECT_GENERATE_CHATS_AND_SESSION";

export const deleteProjectChatsAndSession = (projectId) => ({
  type: PROJECT_DELETE_CHATS_AND_SESSION,
  projectId,
});

export const generateProjectChatsAndSession = () => ({
  type: PROJECT_GENERATE_CHATS_AND_SESSION,
});

export const updateChatSessionField = (payload) => {
  return {
    type: SET_CHAT_SESSION_FIELD,
    payload,
  };
};

export const setInitialChatSession = (payload) => {
  return {
    type: SET_INITIAL_SESSION_CHAT,
    payload,
  };
};

export const updateChatField = (payload) => {
  return {
    type: SET_CHAT_FIELD,
    payload,
  };
};

export const setInitialChat = (payload) => {
  return {
    type: SET_INITIAL_CHAT,
    payload,
  };
};

export const saveChatByProjectId =
  ({ projectId, field, value }) =>
  async (dispatch, getState) => {
    const apiProjectData = { id: projectId };
    const projectData = getProjectData(getState());
    const updatedProjectData = { ...projectData };

    if (field) {
      apiProjectData[field] = value;
    }

    // UPDATE CURRENT_PROJECT ONLY IF THE CURRENT_PROJECT_ID === projectId and the field is not empty
    if (projectId === projectData.id && field) {
      const updatedProjectData = { ...projectData };
      updatedProjectData[field] = value;
      dispatch(updateProjectDataStart(updatedProjectData));
    }

    return apiUpdateProjectData(apiProjectData, getState())
      .then((response) => {
        let projectData = response.data;

        if (projectData.attributes) {
          const updatedProjectChats =
            projectData?.attributes?.chatId?.data?.attributes?.chatItems;

          projectData = {
            ...projectData.attributes,
            id: projectData.id,
            chats:
              updatedProjectChats && updatedProjectChats.length
                ? updatedProjectChats
                : [],
          };
        }

        delete projectData.chatId;

        dispatch(updateProjectDataSuccess(projectData));
      })
      .catch((error) => {
        console.log(error);
      });
  };
