// @flow
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { getUserData } from '../../../store/auth/authSelectors'
import { getProjectTitle, getAllSetOpportunities, getUpdateStatus, getSectionCount, getAbilitiesSectionCount, getSelectOpportunitySectionCount } from '../../../store/project/projectSelectors'
import OverviewPage from '../overview-page/OverviewPage'
import WorksheetPage1 from '../worksheet-page/WorksheetPage1'
import WorksheetPage2 from '../worksheet-page/WorksheetPage2'
import WorksheetPage3 from '../worksheet-page/WorksheetPage3'
import OpportunitySetPage from '../view-pages/opportunity-set-page/OpportunitySetPage'
import AttractivenessMapPage from '../view-pages/attractiveness-map-page/AttractivenessMapPage'
import AgileFocusPage from '../view-pages/agile-focus-page/AgileFocusPage'
import PdfConvertor from './PdfConvertor'

import './Pdf.scss'

class All extends Component {
  getPageNumber = (opportunitiesCount, initialPageNumber, additionalPagesCount ) => {
    if (opportunitiesCount > 0) {
      return initialPageNumber + opportunitiesCount - 1 + additionalPagesCount
    } else {
      return initialPageNumber + additionalPagesCount
    }
  }

  render () {
    const { user, projectTitle, opportunities, sectionCount, abilitiesSectionCount, agileFocusSectionCount } = this.props
    let additionalWorksheet1PagesCount = 0
    let additionalWorksheet3PagesCount = 0
    let additionalPagesCount = 0

    if (sectionCount > 3 || abilitiesSectionCount > 4) additionalWorksheet1PagesCount ++
    if (agileFocusSectionCount > 3) additionalWorksheet3PagesCount ++
    if (agileFocusSectionCount > 6) additionalWorksheet3PagesCount ++

    additionalPagesCount = additionalWorksheet1PagesCount + additionalWorksheet3PagesCount

    return (
      <div className="pdf-content">
        {user &&
          <PdfConvertor filename={`${projectTitle}.pdf`} opportunitiesLength={opportunities.length} additionalPagesCount={additionalPagesCount}>
            {({ convertToPdf, targetRef }) =>  (
              <Fragment>
                <Link to="/" className="btn transparent back" >Back to Home page</Link>
                {navigator.userAgent.indexOf("Chrome") >= 0 && (
                  <button id="download" className="btn download" onClick={convertToPdf}>Download pdf</button>
                )}
                <div id="print-trigger" ref={targetRef} style={{width: 1700, height: 'auto', background: '#ededed'}}>
                  <div id="print-page-1" className="overview-page" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                    <OverviewPage isWithoutNavigation></OverviewPage>
                  </div>
                    <div id="print-page-2" className="worksheet-page-1" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                      <WorksheetPage1 pdf pdfInitialSectionCount={1} pdfSectionCount={3} pdfInitialAbilitiesSectionCount={1} pdfAbilitiesSectionCount={4} isWithoutNavigation></WorksheetPage1>
                    </div>
                  {(sectionCount > 3 || abilitiesSectionCount > 4) &&
                    <div id="print-page-3" className="worksheet-page-1" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                      <WorksheetPage1 pdf pdfInitialSectionCount={4} pdfSectionCount={6} pdfInitialAbilitiesSectionCount={5} pdfAbilitiesSectionCount={8} isWithoutNavigation></WorksheetPage1>
                    </div>
                  }
                  <div id={`print-page-${3 + additionalWorksheet1PagesCount}`} className="view-page-1" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                  <OverviewPage isWithoutNavigation linkType='/market-opportunity-set'></OverviewPage>
                  </div>
                  {opportunities.length > 0 ?
                    opportunities.map((opportunity, id) => (
                      <div id={`print-page-${4 + additionalWorksheet1PagesCount + id}`} className="worksheet-page-2" style={{width: 1700, height: 'auto', background: '#ededed'}} key={id}>
                        <WorksheetPage2 isWithoutNavigation pdfOpportunity={opportunity}></WorksheetPage2>
                      </div>
                    ))
                    : <div id={`print-page-${4 + additionalWorksheet1PagesCount}`} className="worksheet-page-2" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                        <WorksheetPage2 isWithoutNavigation></WorksheetPage2>
                      </div>
                  }
                  <div id={`print-page-${this.getPageNumber(opportunities.length, 5, additionalWorksheet1PagesCount)}`} className="view-page-2" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                    <OverviewPage isWithoutNavigation linkType='/attractiveness-map'></OverviewPage>
                  </div>
                  <div id={`print-page-${this.getPageNumber(opportunities.length, 6, additionalWorksheet1PagesCount)}`} className="worksheet-page-3" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                    <WorksheetPage3 pdfInitialOpportunityCount={1} pdfOpportunityCount={3} isWithoutNavigation></WorksheetPage3>
                  </div>
                  {(agileFocusSectionCount > 3 && agileFocusSectionCount <= 6) ?
                    <div id={`print-page-${this.getPageNumber(opportunities.length, 6, additionalPagesCount)}`} className="worksheet-page-3" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                      <WorksheetPage3 pdfInitialOpportunityCount={4} pdfOpportunityCount={6} isWithoutNavigation></WorksheetPage3>
                    </div>
                    :
                    <>
                      <div id={`print-page-${this.getPageNumber(opportunities.length, 6, additionalPagesCount - 1)}`} className="worksheet-page-3" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                        <WorksheetPage3 pdfInitialOpportunityCount={4} pdfOpportunityCount={6} isWithoutNavigation></WorksheetPage3>
                      </div>
                      <div id={`print-page-${this.getPageNumber(opportunities.length, 6, additionalPagesCount)}`} className="worksheet-page-3" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                        <WorksheetPage3 pdfInitialOpportunityCount={7} pdfOpportunityCount={8} isWithoutNavigation></WorksheetPage3>
                      </div>
                    </>
                  }
                  <div id={`print-page-${this.getPageNumber(opportunities.length, 7, additionalPagesCount)}`} className="view-page-3" style={{width: 1700, height: 'auto', background: '#ededed'}}>
                    <OverviewPage isWithoutNavigation linkType='/agile-focus-dartboard'></OverviewPage>
                  </div>
                </div>
              </Fragment>
            )}
          </PdfConvertor>
        }
      </div>
    );
  }
}

const mapState = (state) => ({
  user: getUserData(state),
  projectTitle: getProjectTitle(state),
  opportunities: getAllSetOpportunities(state),
  updateStatus: getUpdateStatus(state),
  sectionCount: getSectionCount(state),
  abilitiesSectionCount: getAbilitiesSectionCount(state),
  agileFocusSectionCount: getSelectOpportunitySectionCount(state),
})

export default connect(mapState)(All)
