import { sanitize } from "dompurify";
import { store } from "../../store/initializator";
import { createUniqueId } from "../../utils/helpers";
import { chatWelcomeMsg } from "../../utils/constants";

const sanitizeUserInput = (input) => {
  return sanitize(input, {
    ALLOWED_TAGS: ["#text"],
    KEEP_CONTENT: false,
  }).trim();
};

export const trimInputMsg = (msg) => {
  return sanitize(msg).trim();
};

export const validateInputMsg = (msg) => {
  return Boolean(sanitizeUserInput(msg));
};

export const processGptInput = (messages) => {
  return messages.map((message) => {
    const role = message.messageType === "incoming" ? "assistant" : "user";
    return {
      role,
      content: message.messageText,
    };
  });
};

export const tokenUsagePercentage = (usedTokens) => {
  const allowedTokens = process.env.REACT_APP_GPT_PROJECT_TOKEN_LIMIT;

  const usagePercentage = parseFloat(
    ((usedTokens / allowedTokens) * 100).toFixed(2)
  );

  return usagePercentage;
};

export const hasTokenLimitExceeded = ({ usedTokens = 0 }) => {
  const allowedTokens = process.env.REACT_APP_GPT_PROJECT_TOKEN_LIMIT;
  return usedTokens > allowedTokens;
};

export const calculateTotalTokenUsage = ({
  usedTokens = 0,
  currentUsage = 0,
}) => {
  return usedTokens + currentUsage;
};

export const generateClientChatSchema = ({
  messageType,
  message,
  username,
  isValidMessage = true,
}) => {
  return {
    uid: createUniqueId(),
    messageType,
    messageText: messageType === "incoming" ? message : message.trim(),
    user: {
      username,
    },
    isValidMessage,
  };
};

export const getCurrentProjectAbilitiesText = async ({ projectId = 0 }) => {
  const abilitiesTextPrefix =
    process.env.REACT_APP_GPT_ABILITIES_TEXT_PREFIX || "";
  const allProjects = store.getState().project.projects.concat([]);

  const currentProjectIndex = allProjects.findIndex(
    (project) => project.id === projectId
  );

  if (currentProjectIndex === -1) return "";

  const currentProject = allProjects[currentProjectIndex];
  const abilities = [];

  currentProject.abilities.forEach((ability) => {
    const abilityProps = [];
    let abilityPropsStr = "";
    const abilityText = ability.abilityText;

    // PUSH NON-EMPTY PROPS-TITLE IN THE ARRAY
    ability.abilityProperties.forEach((property) => {
      if (!!property.propertyTitle.trim().length) {
        abilityProps.push(property.propertyTitle);
      }
    });

    abilityPropsStr = abilityProps.join(", ");

    // APPEND ABILITY PROPS IN ABILITY TEXT
    if (!!abilityText.trim().length) {
      abilities.push(
        `${abilityText}${abilityPropsStr.length ? `: ${abilityPropsStr}` : ""}`
      );
    } else if (!!abilityPropsStr.trim().length) {
      abilities.push(abilityPropsStr);
    }
  });

  return `${abilitiesTextPrefix}${abilities.join("\n")}`;
};

export const formatUserMessages = ({ messages = [], abilitiesText = "" }) => {
  const formatMsg = process.env.REACT_APP_GPT_RESPONSE_FORMAT_MSG || "";

  // ADD ABILITIES TEXT TO THE 1st USER INPUT IN EVERY CHAT SESSION
  messages[1] = {
    ...messages[1],
    content: `${abilitiesText}. ${messages[1].content}`,
  };

  // UPDATE nth USER INPUT IN EVERY REQUEST
  messages[messages.length - 1] = {
    ...messages[messages.length - 1],
    content: `${messages[messages.length - 1].content} ${formatMsg}`,
  };

  // RETURN FORMATTED MESSAGES THAT'LL BE ONLY SENT IN THE API-REQUEST
  return messages;
};

// RETURN VALID MESSAGES FROM CHAT_SESSION
export const generateValidApiMessages = ({ messages }) => {
  return messages.filter((message) => message.isValidMessage);
};

export const tokenLimitExceededWelcomeMsg = ({ project = {} }) => {
  return {
    ...(hasTokenLimitExceeded({
      usedTokens: project?.usedGptTokens ?? 0,
    }) && {
      sessionChats: [
        {
          ...chatWelcomeMsg,
          isValidMessage: false,
          messageText:
            process.env.REACT_APP_GPT_TOKEN_EXCEEDED_WELCOME_MSG || "",
        },
      ],
    }),
  };
};
