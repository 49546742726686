import { tokenLimitExceededWelcomeMsg } from "../components/chatbot/chat-utils";
import { chatWelcomeMsg, defaultChatSession } from "./constants";

export const computeOverallPotential = (opportunity) => {
  const { reasonToBuy, marketVolume, economicViability } = opportunity;

  return Math.round((reasonToBuy + marketVolume + economicViability) / 3);
};

export const computeOverallChallenge = (opportunity) => {
  const { implementationObstacles, timeToRevenue, externalRisks } = opportunity;

  return Math.round(
    (implementationObstacles + timeToRevenue + externalRisks) / 3
  );
};

export const getOverallIndex = (overall) => {
  if (overall > 0 && overall <= 2) {
    return 0;
  }

  if (overall === 3) {
    return 1;
  }

  if (overall === 4) {
    return 2;
  }

  if (overall === 5) {
    return 3;
  }

  if (overall === 6) {
    return 4;
  }

  if (overall === 7) {
    return 5;
  }

  if (overall >= 8) {
    return 6;
  }
};

export const getAgileFocusItems = (opportunities, type) => {
  let items = [];

  if (opportunities) {
    opportunities.map((opportunity) => {
      if (opportunity.agileFocusOption === type) {
        items.push(opportunity);
      }
    });
  }

  return items;
};

// export const getBoxCount = (opportunities) => {
//   let boxCount = [
//     [0, 0, 0, 0],
//     [0, 0, 0, 0],
//     [0, 0, 0, 0],
//     [0, 0, 0, 0],
//   ]
//   if (opportunities) {
//     opportunities.map(opportunity => {
//       const { overallPotential, overallChallenge } = opportunity
//
//       boxCount[overallPotential][overallChallenge]++
//     })
//   }
//
//   return boxCount
// }

export const getTooltipOpportunities = (opportunities) => {
  let items = [
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
    [[], [], [], [], [], [], []],
  ];

  if (opportunities) {
    opportunities.map((opportunity) => {
      const { overallPotential, overallChallenge } = opportunity;

      items[overallPotential][overallChallenge].push(opportunity);
    });
  }

  return items;
};

export const checkProjectIds = (projectData) => {
  projectData.customerSegment.forEach((segment, id) => {
    if (segment && (segment.uid === null || segment.uid === undefined)) {
      segment.uid = createUniqueId();
    }

    if (segment) {
      segment.opportunities.forEach((opportunity, id) => {
        if (
          opportunity &&
          (opportunity.uid === null || opportunity.uid === undefined)
        ) {
          opportunity.uid = createUniqueId();
        }
      });
    }
  });

  projectData.abilities.forEach((ability, id) => {
    if (ability && (ability.uid === null || ability.uid === undefined)) {
      ability.uid = createUniqueId();
    }

    if (ability) {
      ability.abilityProperties.forEach((property, id) => {
        if (property && (property.uid === null || property.uid === undefined)) {
          property.uid = createUniqueId();
        }
      });
    }
  });

  return projectData;
};

export const getRowCount = (project) => {
  const customerSegments = project.customerSegment;
  const abilities = project.abilities;
  const applications = project.applications;
  let rowCount = 0;
  let biggestRowCount = 0;

  if (customerSegments) {
    customerSegments.map((segment) => {
      if (segment) {
        rowCount++;
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity, id) => {
          if (opportunity) {
            rowCount++;
          }
        });
      }
    });
  }

  biggestRowCount = rowCount;
  rowCount = 0;

  if (abilities) {
    abilities.map((ability, id) => {
      if (ability) {
        rowCount++;
      }

      if (ability && ability.abilityProperties) {
        ability.abilityProperties.map((property, id) => {
          if (property) {
            if (id !== 0) {
              rowCount++;
            }
          }
        });
      }
    });
  }

  if (biggestRowCount < rowCount) {
    biggestRowCount = rowCount;
  }

  rowCount = 0;

  applications.map((application) => {
    if (application) {
      rowCount++;
    }
  });

  if (biggestRowCount < rowCount) {
    biggestRowCount = rowCount;
  }

  return biggestRowCount;
};

export const renameAttractivenessValue = (value) => {
  if (value > 0 && value < 3) {
    return "low";
  } else if (value >= 3 && value < 4) {
    return "low-mid";
  } else if (value >= 4 && value < 5) {
    return "mid";
  } else if (value >= 5 && value < 6) {
    return "mid-high";
  } else if (value >= 6 && value < 7) {
    return "high";
  } else if (value >= 7 && value < 8) {
    return "high-superhigh";
  } else if (value >= 8) {
    return "superhigh";
  }
};

export const renameRelatednessValue = (value) => {
  if (value === 1) {
    return "low";
  } else if (value === 2) {
    return "mid";
  } else if (value === 3) {
    return "high";
  }
};

export const prepareAttractivenessData = (row, opportunity) => {
  row.reasonToBuy = renameAttractivenessValue(opportunity.reasonToBuy);
  row.marketVolume = renameAttractivenessValue(opportunity.marketVolume);
  row.economicViability = renameAttractivenessValue(
    opportunity.economicViability
  );
  row.overallPotential = renameAttractivenessValue(
    opportunity.overallPotential
  );
  row.implementationObstacles = renameAttractivenessValue(
    opportunity.implementationObstacles
  );
  row.timeToRevenue = renameAttractivenessValue(opportunity.timeToRevenue);
  row.externalRisks = renameAttractivenessValue(opportunity.externalRisks);
  row.overallChallenge = renameAttractivenessValue(
    opportunity.overallChallenge
  );
  row.productRelatedness = renameRelatednessValue(
    opportunity.productRelatedness
  );
  row.marketRelatedness = renameRelatednessValue(opportunity.marketRelatedness);
  row.backupOption = opportunity.backupOption ? "Yes" : "No";
  row.growthOption = opportunity.growthOption ? "Yes" : "No";
  row.pursueNow = opportunity.agileFocusOption === "pursueNow" ? "Yes" : "No";
  row.keepOpen = opportunity.agileFocusOption === "keepOpen" ? "Yes" : "No";
  row.placeInStorage =
    opportunity.agileFocusOption === "placeInStorage" ? "Yes" : "No";

  return row;
};

export const prepareData = (projects) => {
  let data = [];

  projects.forEach((project, id) => {
    let isProperty = false;
    let isSegment = false;
    let abilityId = 0;
    let abilityPropertyId = 0;
    let customerSegmentId = 0;
    let opportunityId = 0;
    let newRow = {
      email: "",
      project: "",
      createdAt: "",
      updatedAt: "",
      abilityTitle: "",
      abilityProperty: "",
      application: "",
      customerSegment: "",
      customerSubSegment: "",
      isOnSet: "",
      manuallyAdded: "",
      reasonToBuy: "",
      marketVolume: "",
      economicViability: "",
      overallPotential: "",
      implementationObstacles: "",
      timeToRevenue: "",
      externalRisks: "",
      overallChallenge: "",
      primaryOpportunity: "",
      productRelatedness: "",
      marketRelatedness: "",
      backupOption: "",
      growthOption: "",
      pursueNow: "",
      keepOpen: "",
      placeInStorage: "",
      primaryMarket: "",
    };

    const rowCount = getRowCount(project);
    const customerSegments = project.customerSegment;
    const abilities = project.abilities;
    const currentPrimaryOpportunityId =
      project.currentAgileFocusPrimaryOpportunityId;
    const applications = project.applications;

    for (let i = 0; i < rowCount; i++) {
      let row = { ...newRow };

      if (i === 0) {
        row.email =
          project.user && project.user.email ? project.user.email : "";
        row.project = project.title;
        row.createdAt = project.createdAt;
        row.updatedAt = project.updatedAt;
      }

      if (abilities && abilities[abilityId]) {
        const ability = abilities[abilityId];

        if (!isProperty) {
          row.abilityTitle = ability.abilityText;
        }

        if (
          ability.abilityProperties &&
          ability.abilityProperties[abilityPropertyId]
        ) {
          const abilityProperty = ability.abilityProperties[abilityPropertyId];

          isProperty = true;
          row.abilityProperty = abilityProperty.propertyTitle;
          abilityPropertyId++;
        }

        if (
          !ability.abilityProperties ||
          !ability.abilityProperties[abilityPropertyId]
        ) {
          isProperty = false;
          abilityId++;
          abilityPropertyId = 0;
        }
      }

      if (customerSegments && customerSegments[customerSegmentId]) {
        const customerSegment = customerSegments[customerSegmentId];

        if (!isSegment) {
          const application = applications.find(
            (application) => application.sectionId === customerSegment.sectionId
          );

          row.customerSegment = customerSegment.title;

          if (application) {
            row.application = application.title;
          }

          row.isOnSet = customerSegment.isOnSet ? "Yes" : "No";
          row.manuallyAdded =
            customerSegment.isOnSet && !customerSegment.isInList ? "Yes" : "No";

          if (currentPrimaryOpportunityId === customerSegment.uid) {
            row.primaryMarket = "yes";
          } else {
            row.primaryMarket = "no";
          }

          prepareAttractivenessData(row, customerSegment);

          if (
            customerSegment.opportunities &&
            customerSegment.opportunities[opportunityId]
          ) {
            isSegment = true;
          } else {
            isSegment = false;
            customerSegmentId++;
            opportunityId = 0;
          }
        } else {
          const opportunity = customerSegment.opportunities[opportunityId];

          row.customerSubSegment = opportunity.title;
          row.isOnSet = opportunity.isOnSet ? "Yes" : "No";
          row.manuallyAdded =
            opportunity.isOnSet && !opportunity.isInList ? "Yes" : "No";
          opportunityId++;

          if (currentPrimaryOpportunityId === opportunity.uid) {
            row.primaryMarket = "yes";
          } else {
            row.primaryMarket = "no";
          }

          prepareAttractivenessData(row, opportunity);

          if (
            !customerSegment.opportunities ||
            !customerSegment.opportunities[opportunityId]
          ) {
            isSegment = false;
            customerSegmentId++;
            opportunityId = 0;
          }
        }
      }
      data.push(row);
    }
  });

  return data;
};

export const getMemberRole = (project, email) => {
  const users = project?.members?.filter((m) => m.email === email);

  if (users?.length) {
    return users[0].role;
  }
  return "owner";
};

export const createUniqueId = () => {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const debounce = ({ delay = 200, callback }) => {
  let timerId;

  return function () {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      callback();
    }, delay);
  };
};

export const getTotalNonEmptyAbilitiesCount = ({ project }) => {
  let count = 0;
  project.abilities.forEach((ability) => {
    const abilityText = ability.abilityText;
    if (!!abilityText.trim().length) {
      count++;
    }
  });

  return count;
};

export const getProjectChatIndex = (
  currentProjectId = 0,
  allProjectsChat = []
) => {
  return allProjectsChat.findIndex((projectChat) => {
    return projectChat.projectId === currentProjectId;
  });
};

export const getProjectsChatSession = (chatSessions, projects) => {
  const allProjectChatSessions = chatSessions;

  return projects.map((project) => {
    const currentProjectChatIndex = getProjectChatIndex(
      project.id,
      allProjectChatSessions
    );

    // RETURN DEFAULT CHAT_SESSION OBJECT IF IT'S NOT FOUND IN CHAT_SESSIONS ARRAY
    if (currentProjectChatIndex === -1) {
      return {
        ...defaultChatSession,
        projectId: project.id,
        isChatOpenerActive: getTotalNonEmptyAbilitiesCount({ project })
          ? true
          : false,
        ...tokenLimitExceededWelcomeMsg({ project }),
      };
    } else {
      const currentProjectChatSession =
        allProjectChatSessions[currentProjectChatIndex];

      return {
        ...currentProjectChatSession,
      };
    }
  });
};

export const getProjectsChat = (projects) => {
  return projects.map((project) => {
    return {
      projectId: project.id,
      usedGptTokens: project?.usedGptTokens ?? 0,
      chats:
        project.chats && project.chats.length
          ? project.chats
          : [chatWelcomeMsg],
    };
  });
};
