import React from "react";
import "./index.scss";
import { connect } from "react-redux";
import { 
	getUserData
 } from "../../../store/auth/authSelectors";
import { 
	setCommentOriginName, 
	fetchCommentsFromOrigin, 
	postCommentToOrigin
} from "../../../store/comment/commentActions";
import { 
	getCommentListData, 
	getCommentOriginName 
} from "../../../store/comment/commentSelectors";
import { useEffect, useState } from "react";
import CommentItem from "../Item";
import CommentBubble from "../../../assets/svgs/CommentBubble";
import Cross from "../../../assets/svgs/Cross";

const bem = "comment-list";
const CommentList = ({ 
	user, name, data, fetchComments, postComment, setName 
}) => {
	const [value, setValue] = useState("");

	const handleChange = (e) => {
	  setValue(e.target.value);
	};

	const handleSend = () => {
		postComment(name.name, user.user.id, value);
		setValue("");
	};

	useEffect(() => {
		!!name && fetchComments(name.name);
	}, [name, fetchComments]);

	return (
		<div className={bem} hidden={name === null}>
			<div 
				className={`${bem}__close`} 
				onClick={() => setName(null)}
			/>

			<div className={`${bem}__container`}>
				<div className={`${bem}__content`}>
					<div className={`${bem}__head`}>
						<div 
							className={`${bem}__cross`}
							onClick={() => setName(null)}
						>
							<Cross />
						</div>

						<div className={`${bem}__top`}>
							<div className={`${bem}__bubble`}>
								<CommentBubble />
							</div>
							<div className={`${bem}__title`}>
								Notes <span className={`${bem}__count`}>({data.length})</span>
							</div>
						</div>

						<div className={`${bem}__bottom`}>
							{name?.title || name?.name}
						</div>
					</div>

					<div className={`${bem}__list`}>
						{data.map((comment) => (
							<CommentItem 
								key={comment.id} 
								level={0} 
								{...comment} 
							/>
						))}
					</div>
				</div>

				<div className={`${bem}__input`}>
					<textarea 
						className={`${bem}__textarea`} 
						placeholder="Your note goes here"
						value={value}
						onChange={handleChange}
					/>
					<button 
						className={`${bem}__send`}
						onClick={handleSend}
					>
						Add a note
					</button>
				</div>
			</div>
		</div>
	);
};

const mapState = (state) => ({
	user: getUserData(state),
	name: getCommentOriginName(state),
	data: getCommentListData(state)
});

const mapDispatch = (dispatch) => ({
	setName: (name) => 
		dispatch(setCommentOriginName(name)),
	fetchComments: (name) =>
		dispatch(fetchCommentsFromOrigin(name)),
	postComment: (originName, userId, content) =>
		dispatch(postCommentToOrigin(originName, userId, content))
});

export default connect(mapState, mapDispatch)(CommentList);
