// @flow
import React, {Component} from 'react'
import {connect} from 'react-redux'
import BasePage from '../BasePage'
import {NavigationData} from '../../../utils/constants'
import {fetchProjectData, toggleNotes, fetchProjectsCount} from '../../../store/project/projectActions'
import { getSaveStatus, getProjectStatus } from '../../../store/project/projectSelectors'
import {getUserData} from '../../../store/auth/authSelectors'
import OverviewSection from './OverviewSection'
import './OverviewPage.scss'

class OverviewPage extends Component {
  async componentDidMount() {
    const { onFetchProjectData, user, saveStatus, projectStatus, onFetchProjectCount } = this.props

    // onFetchProjectCount()

    if (user && (saveStatus === 'SAVED' && projectStatus === 'SAVED')) {

      await onFetchProjectData()
    }
  }

  handleOpenNotes = () => {
    const {onToggleNotes} = this.props
    onToggleNotes(false)
  }

  render() {
    const {isWithoutNavigation, linkType} = this.props

    return (
      <BasePage isWithoutNavigation={isWithoutNavigation}>
        <div className="overview-page">
          <div className="overview-container">
            {
              NavigationData.map((navigation, id) => {
                if ((!linkType && navigation.link !== '/') || (linkType && linkType === navigation.link)) {
                  return (
                    <OverviewSection
                      key={id}
                      link={navigation.link}
                      heading={navigation.heading}
                      imageSrc={navigation.bigImageSrc}
                      iconSrc={navigation.iconSrc}
                      textPart1={navigation.textPart1}
                      textPart2={navigation.textPart2}
                      subSection={navigation.subSection}
                      arrowsSrc={navigation.arrowsSrc}
                      isWithoutNavigation={isWithoutNavigation}
                      linkType={linkType}
                    />
                  )
                }
              })
            }
          </div>
        </div>
      </BasePage>
    )
  }
}

const mapState = (state) => ({
  user: getUserData(state),
  saveStatus: getSaveStatus(state),
  projectStatus: getProjectStatus(state),
})

const mapDispatch = (dispatch) => ({
  onFetchProjectData: () => dispatch(fetchProjectData()),
  onToggleNotes: (visibility) => dispatch(toggleNotes(visibility)),
  onFetchProjectCount: () => dispatch(fetchProjectsCount()),
})

export default connect(mapState, mapDispatch)(OverviewPage)
