import React, { Component, Fragment } from 'react'

import './WarningModal.scss'


class WarningModal extends Component {
  render() {
    const { children, id, type, onOkClick, onCancelClick } = this.props

    return (
      <div className="warning-modal">
        <div onClick={onCancelClick} className="close-icon">
          +
        </div>
        <div className="modal-text">
          {children}
        </div>
        {type === 'project' ?
          <Fragment>
            <button className="btn cancel-button" onClick={onCancelClick}>
              Cancel
            </button>
            <button className="btn yes-button" onClick={() => onOkClick(id)}>
              Yes
            </button>
          </Fragment>
          :
          <Fragment>
            <button className="btn ok-button" onClick={onOkClick}>
              OK
            </button>
          </Fragment>
        }
      </div>
    )
  }
}

export default WarningModal
