import React, { useState, useEffect } from 'react';
import Warning from '../../../assets/svgs/Warning'
import Cross from '../../../assets/svgs/Cross'
import TimerBlueBar from '../../../assets/svgs/TimerBlueBar'
import TimerHalfCircle from '../../../assets/svgs/TimerHalfCircle'
import TimerCircle from '../../../assets/svgs/TimerCircle'
import './EditWarning.scss';

const EditWarning = ({ openModal, setOpenModal }) => {

  const [time, setTime] = useState(30);
  const [anim, setAnim] = useState(true)

  const timeHandler = () => {
    if( time > 1 ){
      setTime(num => num -1);
    } else if( time <= 1 || !anim ) {
      setTime(30);
      clearTimeout(timer);
    }   
  }
  const timer = setTimeout(timeHandler, 1000);

return(
  <>
    <div>
      <div className={`${!openModal ? '' : 'edit-only'}`}></div>
      <div className="edit-modal-container">
        <div className="warning-svg"><Warning/></div>
        <div className="modal-name">Warning</div>
        <div onClick={() => {setOpenModal(!openModal)}} className="cross-svg"><Cross/></div>
        <div className="upper-line"></div>
        <div className="warning-title">You cannot edit at the moment</div>
        <div className="warning-intro">Jelly cupcake cake chocolate bar cookie. Carrot cake carrot cake jelly I love. Chocolate bar pudding pudding toffee.</div>
        <div className="warning-reload">Automatic reload in:</div>
        <div className="stable-construct">
          <div className="timer">{time}</div>
          <TimerCircle/>
          {anim && <TimerBlueBar/>} {!anim && <TimerBlueBar/>}
          <TimerHalfCircle/>
        </div>
        <button onClick={() => {setAnim(!anim); clearTimeout(timer); setTime(30);}} className="edit-btn-send">Reload manually</button>
        <button onClick={() => {setOpenModal(!openModal)}} className="edit-btn-cancel">Cancel</button>
      </div>     
    </div>
  </>
)}

export default EditWarning;