import {
  COMMENT_DELETE_SUCCESS,
  COMMENT_FETCH_SUCCESS,
  COMMENT_FROM_COMMENT_FETCH_SUCCESS,
  COMMENT_POST_SUCCESS,
  COMMENT_POST_TO_COMMENT_SUCCESS,
  HIDE_REPLY_SECTION,
  ORIGIN_COMMENT_COUNT_SUCCESS,
  SET_COMMENT_ORIGIN_NAME
} from "./commentActions";

const deleteCommentFromArray = (arr, id, parent = null) => {
  for (let i = 0; i < arr.length; i++) {
    const comment = arr[i];
    const commentId = comment.id;
    const tail = comment?.attributes?.tail?.data;

    if(commentId === id) return {
      data: arr.filter((v) => v.id !== id),
      id: parent,
    };

    else if(tail && tail.length) {
      const tmp = deleteCommentFromArray(tail, id, commentId);
      if(tmp.id) {
        comment.attributes.tail.data = tmp.data;
        return {
          data: arr,
          id: tmp.id
        };
      }
    }
  }

  return {
    data: arr,
    parent: null,
  };
};

const actions = {
  [SET_COMMENT_ORIGIN_NAME]: (state, action) => ({
    ...state,
    commentOriginName: action.data
  }),
  [HIDE_REPLY_SECTION]: (state, action) => ({
    ...state,
    replyListData: {
      ...state.replyListData,
      [action.id]: null
    }
  }),
  [COMMENT_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    commentListData: action.comments
  }),
  [COMMENT_POST_SUCCESS]: (state, action) => ({
    ...state,
    commentListData: [
      ...state.commentListData,
      action.comment
    ],
    commentCounts: {
      ...state.commentCounts,
      [action.name]: (state.commentCounts[action.name] || 0) + 1
    }
  }),
  [COMMENT_FROM_COMMENT_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    replyListData: {
      ...state.replyListData,
      [action.id]: action.comments
    }
  }),
  [COMMENT_POST_TO_COMMENT_SUCCESS]: (state, action) => {
    const commentListData = state.commentListData.map(comment => {
      if(comment.id === action.id) {
        comment.attributes.tail.data.push({ id: action.comment.id });
      }

      return comment;
    });
    const newState = {
      ...state,
      commentListData,
      replyListData: {
        ...state.replyListData,
        [action.id]: [
          ...state.replyListData[action.id] || [],
          action.comment
        ]
      }
    };

    return newState;
  },
  [ORIGIN_COMMENT_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    commentCounts: {
      ...state.commentCounts,
      [action.name]: action.count
    }
  }),
  [COMMENT_DELETE_SUCCESS]: (state, action) => {
    const {data, id} = deleteCommentFromArray(state.commentListData, action.id);
    const originName = state.commentOriginName.name;
    const commentCounts = {
      ...state.commentCounts,
      [originName]: state.commentCounts[originName] - 1
    };

    if(id) {
      return {
        ...state,
        commentListData: data,
        commentCounts,
        replyListData: {
          ...state.replyListData,
          [id]: state.replyListData[id].filter((v) => v.id !== action.id)
        }
      };
    }

    return {
      ...state,
      commentCounts,
      commentListData: data,
    };
  },
};

const initialState = {
  commentOriginName: null,
  commentCounts: {},
  commentListData: [],
  replyListData: {}
};

export default (state = initialState, action) => 
  actions[action.type] ? actions[action.type](state, action) : state;