// @flow
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { resetPassword, clearError } from '../../../store/auth/authActions'
import './AuthorizationPage.scss'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Too Short!')
    .required('Required'),
  passwordConfirmation: Yup.string()
    .min(6, 'Too Short!')
    .required('Required')
})

class ResetPasswordPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  componentDidMount() {
    this.props.onClearError()
  }

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({ error: '' })
    await this.props.onResetPassword(this.props.location.search.replace('?code=', ''), values.password, values.passwordConfirmation)
    if (this.props.resetPasswordStatus === 'SUCCESS') {
      this.setState({ error: 'Password reset successfully' })
    } else {
      this.setState({ error: 'Could not reset password' })
    }
    setSubmitting(false)
  }

  render() {
    return (
      <div className="authorization-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                Reset Password
              </div>
            </div>
          </div>
          <div className="row">
            <div className="authorization-page-content">
              <Formik
                initialValues={{ password: '', passwordConfirmation: '' }}
                validationSchema={ResetPasswordSchema}
                onSubmit={this.onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="password-label" htmlFor="password">Password</label>
                        <Field type="password" name="password" />
                        <ErrorMessage name="password" component="div" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="password-label" htmlFor="passwordConfirmation">Password Confirmation</label>
                        <Field type="password" name="passwordConfirmation" />
                        <ErrorMessage name="passwordConfirmation" component="div" />
                      </div>
                    </div>
                    <div>{ this.props.error || this.state.error }</div>
                    <button className="btn reset-button" type="submit" disabled={isSubmitting}>
                      Reset
                    </button>
                  </Form>
                )}
              </Formik>
              <div className="back-to-login">
                Back to
                {' '}
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  resetPasswordStatus: state.user.fetchStatus,
  error: state.user.error
})

const mapDispatch = (dispatch) => ({
  onResetPassword: (email, password, passwordConfirm) => dispatch(resetPassword(email, password, passwordConfirm)),
  onClearError: () => dispatch(clearError()),
})

export default withRouter(connect(mapState, mapDispatch)(ResetPasswordPage))
