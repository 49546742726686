import React from 'react'
import './NotesComment.scss'

const NotesComment = ({ text, user }) => {

  const splitString = user.split('');
  const firstCharacter = splitString[0];
  const avatar = firstCharacter.toUpperCase()

  return (
    <div className="notes-comment">
      <div className="comment-container">
        <div className="column">
          <div className="comment-data">
            <div className="account-icon">
              <span className="avatar-text">
                {avatar}
              </span>
            </div>
            <div className="notes-name-item">{user}</div>
          </div>
        </div>
        <div className="column text-field">
          <div className="notes-text-item">
            {text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotesComment
