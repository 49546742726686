import React from 'react'
import './MembersMap.scss'

const MembersMap = ({name, email, role, onRoleChange, user, memberRole}) => {

  const splitString = name.split('');
  const firstCharacter = splitString[0];
  const avatar = firstCharacter.toUpperCase()

  return (
    <div className="members-map">
      <div className="member-container">
        <div className="member-row">
          <div className="account-icon">{avatar}</div>
          <div className="info-panel">
            <div className="name-item info-column">{name}</div>
            <div className="email info-column">{email}</div>
          </div>
          <div className="member-column-options">
            {(memberRole === 'owner' && role !== 'owner' && user.user.email !== email) ?
              <select value={role} onChange={(e) => onRoleChange(email, e)}>
                <option value="canEdit">Can edit</option>
                <option value="canView">Can view</option>
                <option value="remove">Remove</option>
              </select> :
              <div>{(role === 'owner' && 'owner') || (user.user.email === email && 'you') || ' '}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembersMap
