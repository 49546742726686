import React from 'react'

const AbilityInput = ({ sectionId, ability, onInputChange, onUpdateAbilitySection, onSubmitFromKeyboard, propertySectionId, readonly}) => (
  <input
    onChange={(e) => onInputChange(e, sectionId, 'properties', propertySectionId)}
    onBlur={(e) => onUpdateAbilitySection(e, sectionId)}
    spellCheck="false"
    value={ability ? ability.propertyTitle : ''}
    onKeyDown={onSubmitFromKeyboard}
    disabled={readonly}
    maxLength="50"
  />
)

export default AbilityInput
