// @flow
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { signup, clearError } from '../../../store/auth/authActions'
import {deleteAllProjects} from '../../../store/project/projectActions'
import { BACKEND_URL } from '../../../utils/constants'
import './AuthorizationPage.scss'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .required('Required'),
  select: Yup.string()
    .required('Required')
})

class SignupPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  componentDidMount() {
    this.props.onClearError()
  }

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({ error: '' })
    const res = await this.props.onSignup(values.email, values.password, values.select)

    if (!this.props.error) {
      this.props.history.push('/login')
      // TODO: VERZE 2
      // this.setState({ error: 'Verification email has been sent to your email.' })
    } else {
      this.setState({ error: 'Signup failed' })
    }
    setSubmitting(false)
  }

  render() {
    return (
      <div className="authorization-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                Sign Up
              </div>
            </div>
          </div>
          <div className="row">
            <div className="authorization-page-content">
              <Formik
                initialValues={{ email: '', password: '', select: '' }}
                validationSchema={SignupSchema}
                onSubmit={this.onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="email-label" htmlFor="email">Email</label>
                        <Field type="email" name="email" />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="password-label" htmlFor="password">Password</label>
                        <Field type="password" name="password" />
                        <ErrorMessage name="password" component="div" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="password-label" htmlFor="select">About you</label>
                        <Field as="select" name="select">
                          <option defaultValue value="">-- Please select --</option>
                          <option value="Entrepreneur">Entrepreneur</option>
                          <option value="Business manager">Business manager</option>
                          <option value="Educator / mentor">Educator / mentor</option>
                          <option value="Want to be entrepreneur">Want to be entrepreneur</option>
                          <option value="Tech transfer team member">Tech transfer team member</option>
                          <option value="Investor">Investor</option>
                          <option value="Consultant">Consultant</option>
                          <option value="Other">Other</option>
                        </Field>
                        <ErrorMessage name="select" component="div" />
                      </div>
                    </div>
                    <div>{ this.props.error || this.state.error }</div>
                    <button className="btn signup-button" type="submit" disabled={isSubmitting}>
                      Signup
                    </button>
                  </Form>
                )}
              </Formik>
              <a
                href={`${BACKEND_URL}/connect/google`}
                onClick={() => deleteAllProjects()}
                >
                <button className="google-button btn">Sign up with Google</button>
              </a>
              <div className="back-to-login">
                Already have an account?
                {' '}
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  signupStatus: state.user.fetchStatus,
  error: state.user.error
})

const mapDispatch = (dispatch) => ({
  onSignup: (email, password, select) => dispatch(signup(email, password, select)),
  onClearError: () => dispatch(clearError()),
})

export default withRouter(connect(mapState, mapDispatch)(SignupPage))
