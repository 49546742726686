import React from "react";
import ChatTooltip from "./ChatTooltip";

const ToggleChatButton = ({ isEnabled, onToggle }) => {
  const tooltipText =
    process.env.REACT_APP_GPT_DISABLED_BUTTON_TOOLTIP_TEXT || "";

  return (
    <button
      onClick={isEnabled ? onToggle : null}
      className={`toggle-chat-btn ${isEnabled ? "active" : "disabled"}`}
      disabled={!isEnabled}
    >
      {!isEnabled && (
        <ChatTooltip className="disabled-tooltip">{tooltipText}</ChatTooltip>
      )}
      <img src={"/assets/images/gpt-btn-icon.png"} alt="wtp-gpt-icon" />
    </button>
  );
};

export default React.memo(ToggleChatButton);
