import React, { useEffect, useRef } from "react";
import ChatTooltip from "./ChatTooltip";

const ChatMessageInput = ({
  children,
  inputMsg,
  setInputMsg,
  onSend,
  onKeyPress,
  isEnabled,
  memberRole,
}) => {
  const textAreaRef = useRef(null);

  const {
    REACT_APP_GPT_CHAT_INPUT_PLACEHOLDER: inputPlaceholder = "",
    REACT_APP_GPT_CHAT_TOKEN_EXCEEDED_INPUT_TOOLTIP: disabledInputTooltip = "",
    REACT_APP_GPT_CHAT_COLLABORATOR_INPUT_TOOLTIP: collaboratorTooltip = "",
  } = process.env;

  const isProjectOwner = memberRole === "owner" ? true : false;
  const isChatInputActive = !isProjectOwner ? false : isEnabled;

  // AUTOFOCUS INPUT
  useEffect(() => {
    textAreaRef?.current?.focus();
  }, []);

  // AUTORESIZE TEXTAREA
  useEffect(() => {
    if (textAreaRef.current) {
      // RESET THE HEIGHT MOMENTARILY TO GET THE CORRECT SCROLLHEIGHT FOR THE TEXTAREA
      textAreaRef.current.style.height = "auto";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [inputMsg]);

  return (
    <div className="chat-message-input">
      {children}
      <textarea
        spellCheck="false"
        placeholder={inputPlaceholder}
        className={`chat-message-input-editor ${
          isChatInputActive ? "active" : "disabled"
        }`}
        disabled={!isChatInputActive}
        ref={textAreaRef}
        value={inputMsg}
        onKeyDown={(e) => onKeyPress(e)}
        onChange={(e) => setInputMsg(e.target.value)}
      />
      {!isChatInputActive && (
        <ChatTooltip className="chat-message-input-tooltip">
          {isProjectOwner ? disabledInputTooltip : collaboratorTooltip}
        </ChatTooltip>
      )}
      <div className="chat-actions">
        <button
          className={`chat-send-btn ${
            isChatInputActive ? "active" : "disabled"
          }`}
          onClick={onSend}
        >
          <img src={"/assets/images/send-icon.png"} alt="send icon" />
        </button>
      </div>
    </div>
  );
};

export default React.memo(ChatMessageInput);
