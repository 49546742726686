// @flow
import { combineReducers } from 'redux'

import projectReducer from './project/projectReducer'
import authReducer from './auth/authReducer'
import commentReducer from './comment/commentReducer'

const rootReducer = combineReducers({
  project: projectReducer,
  user: authReducer,
  comment: commentReducer
})

export default rootReducer
