import "./AnnouncementModal.scss";
import Cross from "../../../assets/svgs/Cross";

const AnnouncementModal = ({ onRequestClose }) => {
  const {
    REACT_APP_GPT_ANNOUNCEMENT_MODAL_TITLE: modalTitle = "",
    REACT_APP_GPT_ANNOUNCEMENT_MODAL_DESCRIPTION: modelDescription = "",
  } = process.env;

  return (
    <div className="announcement-modal">
      <div className="announcement-modal-backdrop"></div>

      <div className="announcement-modal-dialog">
        <header className="announcement-modal-header">
          <button
            aria-label="Close modal"
            className="cross-svg"
            onClick={onRequestClose}
          >
            <Cross />
          </button>
        </header>
        <div className="announcement-modal-body">
          <h3 className="announcement-modal-title">{modalTitle}</h3>
          <div
            className="announcement-modal-desc"
            dangerouslySetInnerHTML={{ __html: modelDescription }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementModal;
