import { formatUserMessages } from "../components/chatbot/chat-utils";
import { BACKEND_URL } from "../utils/constants";
import { createUniqueId } from "../utils/helpers";

// Added a package in the backend for using populate=deep in the request for getting nested fields in the response

export const apiSendEmailToMailchimp = async (email, selectChoice) => {
  const url = `${BACKEND_URL}/subscribe`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      email,
      segmentationChoice: selectChoice ? selectChoice : "",
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return response.json();
  }
};

const getAuthHeader = (state) => {
  const token = state.user.userData.jwt;
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const apiGetProjects = async (state) => {
  const url = `${BACKEND_URL}/projects/find-all`;

  const response = await fetch(url, {
    headers: getAuthHeader(state),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiGetProjectsCount = async (state) => {
  const url = `${BACKEND_URL}/projects/count`;

  const response = await fetch(url, {
    headers: getAuthHeader(state),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiGetProjectData = async (state, email, userId) => {
  const url = `${BACKEND_URL}/projects/all-for-member/${email}/${userId}`;

  const response = await fetch(url, {
    headers: getAuthHeader(state),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response;
  }
};

export const apiUpdateProjectData = async (projectData, state) => {
  const url = `${BACKEND_URL}/projects/${projectData.id}?populate=deep`;

  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify({
      data: {
        ...projectData,
      },
    }),
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response;
  }
};

export const apiUpdateProjectUser = async (project, user, state) => {
  const url = `${BACKEND_URL}/projects/${project.id}?populate=deep`;

  const response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify({
      data: {
        user,
        userId: user.id.toString(),
        members: [
          {
            userId: user.id.toString(),
            role: "owner",
            name: user.username,
            email: user.email,
          },
        ],
      },
    }),
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiCreateProject = async (user, state) => {
  const url = `${BACKEND_URL}/projects?populate=*`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      data: {
        title: "Untitled Project",
        customerSegment: [],
        user: null,
        userId: null,
        sectionCount: 3,
        selectOpportunitySectionCount: 3,
        abilitiesSectionCount: 4,
        abilities: [],
        notes: [],
        applications: [],
        currentAgileFocusPrimaryOpportunityId: null,
        currentAgileFocusOpportunityIds: [],
        comments: [],
        members: [],
        uid: createUniqueId(),
      },
    }),
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiDeleteProject = async (id, state) => {
  const url = `${BACKEND_URL}/projects/${id}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiAddMember = async (projectId, email, role, state) => {
  const url = `${BACKEND_URL}/projects/${projectId}/add-member`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      email,
      role,
    }),
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiSendMail = async (
  email,
  feUrl,
  state,
  projectTitle,
  memberRole,
  ownerEmail
) => {
  const url = `${BACKEND_URL}/send-email`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
    body: JSON.stringify({
      email,
      feUrl,
      projectTitle,
      memberRole,
      ownerEmail,
    }),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiLogin = async (email, password) => {
  const url = `${BACKEND_URL}/auth/local`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiSignup = async (email, password, selectChoice) => {
  const url = `${BACKEND_URL}/auth/local/register`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      username: email,
      email: email,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    apiSendEmailToMailchimp(email, selectChoice);
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiForgotPassword = async (email) => {
  const url = `${BACKEND_URL}/auth/forgot-password`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      email: email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiResetPassword = async (
  code,
  password,
  passwordConfirmation
) => {
  const url = `${BACKEND_URL}/auth/reset-password`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      code,
      password,
      passwordConfirmation,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiLoginGoogle = async (search) => {
  const url = `${BACKEND_URL}/auth/google/callback${search}`;
  const response = await fetch(url);

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiGetUser = async (state) => {
  const url = `${BACKEND_URL}/users/me?populate=role`;

  const response = await fetch(url, {
    headers: getAuthHeader(state),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiUpdateUser = async (state, user = {}) => {
  const url = `${BACKEND_URL}/users-permissions/users/me`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeader(state),
    },
    body: JSON.stringify({ ...user }),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};

export const apiChatCompletion = async ({
  model = process.env.REACT_APP_GPT_VALIDATOR_MODEL,
  messages = [],
  abilitiesText = "",
}) => {
  switch (process.env.NODE_ENV) {
    case "development":
      return await apiChatCompletionDev({ model, messages, abilitiesText });
    case "production":
      return await apiChatCompletionProd({ model, messages, abilitiesText });
    default:
      console.log("METHOD NOT FOUND");
      return;
  }
};

// USED IN DEV
export const apiChatCompletionDev = async ({
  model,
  messages,
  abilitiesText,
}) => {
  const {
    REACT_APP_GPT_API_KEY: gptAPIKey,
    REACT_APP_GPT_API_DEV_URL: devURL,
    REACT_APP_GPT_WTP_MODEL: contentGPTModel,
    REACT_APP_GPT_WTP_SYSTEM_MSG: contentGPTSysMsg,
    REACT_APP_GPT_VALIDATOR_MODEL: validatorGPTModel,
    REACT_APP_GPT_VALIDATOR_SYSTEM_MSG: validatorGPTSysMsg,
  } = process.env;

  let url = devURL;

  // FOR DEV ONLY
  if (model === validatorGPTModel) {
    url = `${devURL}/validator`;
  }

  const apiInput = {
    model,
    messages,
  };

  switch (model) {
    case validatorGPTModel:
      apiInput.messages = [
        {
          role: "system",
          content: validatorGPTSysMsg,
        },
        ...apiInput.messages,
      ];
      break;
    case contentGPTModel:
      apiInput.messages = [
        {
          role: "system",
          content: contentGPTSysMsg,
        },
        ...formatUserMessages({ messages: apiInput.messages, abilitiesText }),
      ];
      break;
    default:
      break;
  }

  // DELAY REQUEST
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gptAPIKey}`,
    },
    body: JSON.stringify(apiInput),
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (response.ok) {
        resolve(response.json());
      } else {
        reject(response.json());
      }
    }, 10000); // 10000
  });
};

// USED IN PROD
export const apiChatCompletionProd = async ({
  model,
  messages,
  abilitiesText,
}) => {
  const {
    REACT_APP_GPT_API_URL: url,
    REACT_APP_GPT_API_KEY: gptAPIKey,
    REACT_APP_GPT_WTP_MODEL: contentGPTModel,
    REACT_APP_GPT_WTP_SYSTEM_MSG: contentGPTSysMsg,
    REACT_APP_GPT_VALIDATOR_MODEL: validatorGPTModel,
    REACT_APP_GPT_VALIDATOR_SYSTEM_MSG: validatorGPTSysMsg,
  } = process.env;

  const apiInput = {
    model,
    messages,
  };

  switch (model) {
    case validatorGPTModel:
      apiInput.messages = [
        {
          role: "system",
          content: validatorGPTSysMsg,
        },
        ...apiInput.messages,
      ];
      break;
    case contentGPTModel:
      apiInput.messages = [
        {
          role: "system",
          content: contentGPTSysMsg,
        },
        ...formatUserMessages({ messages: apiInput.messages, abilitiesText }),
      ];
      break;
    default:
      break;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${gptAPIKey}`,
    },
    body: JSON.stringify(apiInput),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw response.json();
  }
};
