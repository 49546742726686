// @flow
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import './NavigationSection.scss'
import {Tooltip} from "reactstrap";

class NavigationSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: this.props.location.pathname,
      isTooltipOpen: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isTooltipOpen: false,
      })
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  toggleTooltip = () => {
    this.setState({
      isTooltipOpen: !this.state.isTooltipOpen
    })
  }

  render() {
    const { link, heading, title, subSection, imageSrc } = this.props;
    const { pathname, isTooltipOpen } = this.state;
    const navigationClass = pathname === link || (subSection && pathname === subSection.link) ? "active": "";

    return (
      <>
        <span className="line" />
        <div 
          key={heading} 
          className={`navigation-section ${!subSection 
            ? 'overview' 
            : ''} ${navigationClass}`}
        >
          <div className="gray-box" />
          <Link to={subSection?.link || link} style={{
            position: "relative",
            zIndex: 1
          }}>
            <div onClick={() =>  this.setState({ isSelected: true })} className='navigation-item-center'>
              {imageSrc === '/assets/images/overview.png'
                ? <img src={imageSrc} className="overview-fix-height" alt='heading' />
                : <img src={imageSrc} alt='heading' />}

              <div className='navigation-container'>
                {title && (
                  <div className="worksheet-title">
                    {title}
                  </div>
                )}
                <div className='navigation-item navigation-heading'>{heading}</div>

                {pathname === '/' ?
                  <Tooltip
                    placement="right-start"
                    isOpen={isTooltipOpen}
                    target="tooltip"
                    toggle={this.toggleTooltip}
                    trigger="click"
                  >
                    <span ref={this.setWrapperRef}>
                      This dashboard provides an overview of your process. It includes three steps: generating your Market Opportunity Set using Worksheet 1, evaluating these opportunities on the Attractiveness Map using of Worksheet 2, and designing your Agile Focus Strategy using Worksheet 3. The overview is created automatically based on the filled worksheets.
                    </span>
                  </Tooltip>
                  : pathname === '/worksheet-1' ?
                  <Tooltip
                    placement="right-start"
                    isOpen={isTooltipOpen}
                    target="tooltip"
                    toggle={this.toggleTooltip}
                    trigger="click"
                  >
                    <span ref={this.setWrapperRef}>
                      This worksheet provides a structured process to help you identify different market opportunities for your innovation.<br />
                      Any application of your unique abilities to a specific set of customers is one market opportunity. Identifying a varied set of opportunities will eventually increase your chances of success over time.<br />
                      Be broad  and open minded in this discovery process. When done, select few opportunities for further examination. They will automatically appear in your Market Opportunity Set.
                    </span>
                  </Tooltip>
                  : pathname === '/worksheet-2' ?
                    <Tooltip
                      placement="right-start"
                      isOpen={isTooltipOpen}
                      target="tooltip"
                      toggle={this.toggleTooltip}
                      trigger="click"
                    >
                    <span ref={this.setWrapperRef}>
                      This worksheet helps you to comprehensively evaluate your market opportunities. It provides the key questions for assessing the potential of each option, and the challenge in capturing it.
                      Once you find evidence and supporting data, score each factor with the provided scales, and summarize your learning to an overall Potential and Challenge scores. The evaluated opportunity will be automatically located on the Attractiveness Map.
                      Use a separate worksheet for each market opportunity in your set.
                    </span>
                    </Tooltip>
                    :
                    <Tooltip
                      placement="right-start"
                      isOpen={isTooltipOpen}
                      target="tooltip"
                      toggle={this.toggleTooltip}
                      trigger="click"
                    >
                    <span ref={this.setWrapperRef}>
                      This worksheet helps you to design your strategic focus. You will clearly define your primary market opportunity, and few selected options to keep open for backup or future growth.
                      This strategy allows you to focus and remain agile at the same time, avoiding a potentially fatal lock-in. When completed, your strategy will be automatically depicted on the Agile Focus Dartboard.
                    </span>
                    </Tooltip>
                }

                { navigationClass === "active" && (
                  <img className="question-mark-icon" id="tooltip" src="/assets/icons/question-mark.png" alt="question-mark"/>
                ) }
              </div>
            </div>
          </Link>
        </div>
      </>
    )
  }
}

export default withRouter(NavigationSection)
