import React from "react";

function Cross() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M15 5L5 15M5 5l10 10"
      ></path>
    </svg>
  );
}

export default Cross;