import React from "react";

function HeaderArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      fill="none"
      viewBox="0 0 10 6"

    >
      <path fill="#00AEEF" d="M5 6L.67 0h8.66L5 6z"></path>
    </svg>
  );
}

export default HeaderArrow;
