import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  LOGIN_START,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SIGNUP_START,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_START,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  ERROR_CLEAR,
  LOGOUT,
  USER_PROFILE_UPDATE_START,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
} from "./authActions";

const initialState = {
  userData: "",
  fetchStatus: "UNTOUCHED",
  error: "",
  userEmail: "",
  isRememberChecked: false,
  signupStatus: "UNTOUCHED",
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        fetchStatus: "WORKING",
        error: "",
      };

    case LOGIN_SUCCESS: {
      if (action.isRememberChecked) {
        return {
          ...state,
          userData: action.data,
          userEmail: action.data.user.email,
          fetchStatus: "SUCCESS",
          signupStatus: "UNTOUCHED",
          isRememberChecked: true,
        };
      } else {
        return {
          ...state,
          userData: action.data,
          userEmail: "",
          fetchStatus: "SUCCESS",
          signupStatus: "UNTOUCHED",
          isRememberChecked: false,
        };
      }
    }

    case LOGIN_FAIL:
      return {
        ...state,
        fetchStatus: "ERROR",
        error: action.message,
      };

    case LOGOUT: {
      return {
        ...state,
        userData: "",
      };
    }

    case SIGNUP_START:
      return {
        ...state,
        fetchStatus: "WORKING",
        signupStatus: "WORKING",
        error: "",
      };

    case SIGNUP_SUCCESS: {
      return {
        ...state,
        fetchStatus: "SUCCESS",
        signupStatus: "SUCCESS",
      };
    }

    case SIGNUP_FAIL:
      return {
        ...state,
        fetchStatus: "ERROR",
        error: action.message,
      };

    case FORGOT_PASSWORD_START:
      return {
        ...state,
        fetchStatus: "WORKING",
        error: "",
      };

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetchStatus: "SUCCESS",
      };
    }

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        fetchStatus: "ERROR",
        error: action.message,
      };

    case RESET_PASSWORD_START:
      return {
        ...state,
        fetchStatus: "WORKING",
        error: "",
      };

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        fetchStatus: "SUCCESS",
      };
    }

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        fetchStatus: "ERROR",
        error: action.message,
      };

    case USER_PROFILE_UPDATE_START:
      return {
        ...state,
        error: "",
        fetchStatus: "WORKING",
      };

    case USER_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData?.user,
            dismissedAnnouncementModal: action.data.dismissedAnnouncementModal,
          },
        },
        fetchStatus: "SUCCESS",
      };
    }

    case USER_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        fetchStatus: "ERROR",
        error: action.message,
      };

    case ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };

    default:
      break;
  }

  return state;
};

/**
 * Configuration of redux-persist used for serialization of redux state to local storage.
 */
const persistConfig = {
  key: "user",
  blacklist: ["signupStatus"],
  storage,
};

const persistableReducer: (CartState, CartAction) => CartState = persistReducer(
  persistConfig,
  projectReducer
);

export default persistableReducer;
