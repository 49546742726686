import React from "react";

function Warning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      fill="none"
      version="1.1"
      viewBox="0 0 24 21"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.29 2.86L1.82 17a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 2.86a2 2 0 00-3.42 0v0z"
      ></path>
      <g fill="none" transform="translate(11 8)">
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1v4"
        ></path>
      </g>
      <g fill="none" transform="translate(10.99 15)">
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1h.01"
        ></path>
      </g>
    </svg>
  );
}

export default Warning;