import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './Footer.scss'


class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Link to="/terms-and-conditions" className="link" target="_blank" rel="noopener noreferrer">
          Terms
        </Link>
        <Link to="/privacy-policy" className="link" target="_blank" rel="noopener noreferrer">
          Privacy
        </Link>
        <a href="https://wheretoplay.co/copyrights/" className="link" target="_blank" rel="noopener noreferrer">
          Copyright
        </a>
        <a href="https://wheretoplay.co/contact-us/" className="link" target="_blank" rel="noopener noreferrer">
          Contact us
        </a>
        <a href="https://wheretoplay.co" className="link" target="_blank" rel="noopener noreferrer">
          www.wheretoplay.co
        </a>
      </div>
    )
  }
}

export default Footer
