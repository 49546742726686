import Rollbar from "rollbar";
import {
  apiLogin,
  apiLoginGoogle,
  apiSignup,
  apiForgotPassword,
  apiResetPassword,
  apiSendEmailToMailchimp,
  apiGetUser,
  apiUpdateUser,
} from "../../api/api";
import { deleteAllProjects } from "../project/projectActions";

{
  /*
  const rollbar = new Rollbar({
  accessToken: "410f5891ac834964b241cd457a965b3a",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
      environment: "production"
  }
})
*/
}

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const USER_PROFILE_UPDATE_START = "USER_PROFILE_UPDATE_START";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";

export const ERROR_CLEAR = "ERROR_CLEAR";
export const LOGOUT = "LOGOUT";

const loginStart = () => ({ type: LOGIN_START });
const loginSuccess = (data, isRememberChecked) => ({
  type: LOGIN_SUCCESS,
  data,
  isRememberChecked,
});
const loginFail = (message) => ({ type: LOGIN_FAIL, message });
const logoutSuccess = () => ({ type: LOGOUT });

const signupStart = () => ({ type: SIGNUP_START });
const signupSuccess = (data) => ({ type: SIGNUP_SUCCESS, data });
const signupFail = (message) => ({ type: SIGNUP_FAIL, message });

const forgotPasswordStart = () => ({ type: FORGOT_PASSWORD_START });
const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  data,
});
const forgotPasswordFail = (message) => ({
  type: FORGOT_PASSWORD_FAIL,
  message,
});

const resetPasswordStart = () => ({ type: RESET_PASSWORD_START });
const resetPasswordSuccess = (data) => ({ type: RESET_PASSWORD_SUCCESS, data });
const resetPasswordFail = (message) => ({ type: RESET_PASSWORD_FAIL, message });

export const clearError = () => ({ type: ERROR_CLEAR });

export const logout = () => (dispatch, getState) => {
  dispatch(logoutSuccess());
};

export const login =
  (email, password, isRememberChecked) => (dispatch, getState) => {
    dispatch(loginStart());

    dispatch(deleteAllProjects());

    return apiLogin(email, password)
      .then((data) => dispatch(loginSuccess(data, isRememberChecked)))
      .catch(async (error) => {
        // rollbar.info(error)
        const errorObj = await error;
        const message = errorObj.error.message;
        dispatch(signupFail(message));
      });
  };

export const loginGoogle = (search) => (dispatch, getState) => {
  dispatch(loginStart());

  dispatch(deleteAllProjects());

  return apiLoginGoogle(search)
    .then((data) => {
      apiSendEmailToMailchimp(data.user.email);
      dispatch(loginSuccess(data));
    })
    .catch(async (error) => {
      // rollbar.info(error)
      const message =
        "Signup by google account has failed. Email is already taken or server error has occured.";
      dispatch(signupFail(message));
    });
};

export const signup =
  (email, password, selectChoice) => (dispatch, getState) => {
    dispatch(signupStart());

    return apiSignup(email, password, selectChoice)
      .then((data) => dispatch(signupSuccess(data)))
      .catch(async (error) => {
        // rollbar.info(error)
        const errorObj = await error;
        const message = errorObj.message[0].messages[0].message;
        dispatch(signupFail(message));
      });
  };

export const forgotPassword = (email) => (dispatch, getState) => {
  dispatch(forgotPasswordStart());

  return apiForgotPassword(email)
    .then((data) => dispatch(forgotPasswordSuccess(data)))
    .catch(async (error) => {
      // rollbar.info(error)
      const errorObj = await error;
      const message = errorObj.message[0].messages[0].message;
      dispatch(signupFail(message));
    });
};

export const resetPassword =
  (code, password, passwordConfirm) => (dispatch, getState) => {
    dispatch(resetPasswordStart());

    return apiResetPassword(code, password, passwordConfirm)
      .then((data) => dispatch(resetPasswordSuccess(data)))
      .catch(async (error) => {
        // rollbar.info(error)
        const errorObj = await error;
        const message = errorObj.message[0].messages[0].message;
        dispatch(signupFail(message));
      });
  };

const updateProfileStart = () => ({ type: USER_PROFILE_UPDATE_START });

const updateProfileSuccess = (data) => ({
  type: USER_PROFILE_UPDATE_SUCCESS,
  data,
});

const updateProfileFail = (message) => ({
  type: USER_PROFILE_UPDATE_FAIL,
  message,
});

export const updateUserProfile =
  (updatedUser) => async (dispatch, getState) => {
    dispatch(updateProfileStart());

    return apiUpdateUser(getState(), updatedUser)
      .then((data) => {
        dispatch(updateProfileSuccess(data));
      })
      .catch((error) => {
        const message = error?.message || "Something went wrong....";
        dispatch(updateProfileFail(message));
      });
  };
