import React from "react";

const CloudSaved = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 497 355"
  >
    <g>
      <path d="M439.274,148.209c-0.285,2.93 1.17,5.757 3.721,7.226c32.987,19.002 54.005,53.912 54.005,92.165c0,59.032 -48.443,107.1 -108.036,107.1c-0,-0 -281.864,-0 -281.864,-0c-50.957,-0 -95.132,-36.232 -105.046,-86.142c0,-0 0,-0 0,-0c-1.362,-6.859 -2.054,-13.91 -2.054,-20.958c0,-38.177 21.103,-73.212 54.177,-92.238c2.041,-1.175 3.412,-3.239 3.702,-5.576c3.736,-30.026 19.967,-57.043 45.601,-74.688c-0,-0 -0,-0 0,-0c24.926,-17.16 54.569,-22.765 84.1,-16.343c2.908,0.633 5.914,-0.514 7.663,-2.922c24.845,-34.222 65.006,-55.797 109.442,-55.797c80.127,0 142.261,69.435 134.589,148.173Zm-50.31,160.847c34.371,0 62.392,-27.537 62.392,-61.456c0,-25.917 -17.191,-49.104 -42.619,-57.813c0,-0 0,-0 0,-0c-3.789,-1.298 -5.639,-2.323 -16.894,-8.166c-3.718,-1.93 -5.554,-6.218 -4.383,-10.241c3.505,-12.045 6.775,-21.125 6.775,-36.151c-0,-49.377 -40.172,-89.549 -89.55,-89.549c-34.66,0 -66.532,20.306 -81.199,51.728l-5.876,12.589c-2.034,4.357 -7.196,6.266 -11.576,4.28l-12.653,-5.737c-21.457,-9.73 -44.91,-9.002 -64.019,4.153c0,0 0,0 0,0c-17.942,12.352 -27.868,32.691 -26.548,54.409c-0,-0.001 0.641,10.561 0.641,10.561c0.238,3.906 -2.14,7.498 -5.829,8.804c-0,0 -9.974,3.532 -9.974,3.532c-25.062,8.876 -42.008,31.978 -42.008,57.601c-0,34.223 27.86,61.456 61.456,61.456l281.864,0Z" />
      <path d="M189.525,239.564l36.278,25c4.529,3.121 10.143,4.239 15.522,3.092c5.379,-1.148 10.048,-4.46 12.908,-9.157l58.975,-96.841c5.742,-9.427 2.749,-21.743 -6.679,-27.484c-9.427,-5.742 -21.743,-2.749 -27.484,6.679l-47.969,78.767c0,0 -18.854,-12.992 -18.854,-12.992c-9.089,-6.264 -21.554,-3.97 -27.817,5.119c-6.264,9.089 -3.97,21.554 5.12,27.817Z" />
    </g>
  </svg>
);

export default CloudSaved;
