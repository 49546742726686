import React, { Component } from 'react';
import Checkbox from './Checkbox';
import './CheckboxWrapper.scss';

class CheckboxWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isChecked: false,
    }
  }

  componentDidMount() {
    this.setState({
      isChecked: this.props.currentSelectionValue,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSelectionValue !== this.props.currentSelectionValue) {
      this.setState({
        isChecked: this.props.currentSelectionValue,
      })
    }
  }

  handleCheck = (name) => {
    const { onClick } = this.props
    const { isChecked } = this.state

    onClick(name, !isChecked)

    this.setState({ isChecked: !this.state.isChecked })
  };

  render() {
    const { isChecked } = this.state;
    const { name, label, readonly } = this.props;
    return (
      <div className={`checkbox-wrapper ${readonly ? 'readonly' : ''}`}>
        <span>
          <Checkbox isChecked={isChecked} onCheckboxClick={() => this.handleCheck(name)} />
          <span className='label'>{label}</span>
        </span>
      </div>
    );
  }
}

export default CheckboxWrapper;
