import React, { Component } from 'react'
import CommentOrigin from "../../comment/Origin";

import ArrowComponent from './ArrowComponent'

import './AttractivenessSection.scss'
import ArrowHead from "../../../assets/svgs/ArrowHead";

class AttractivenessSection extends Component {
  render() {
    const { heading, infoText, iconSrc, name, onClick, currentSectionId, previousSectionId, readonly, isWithoutNavigation } = this.props

    let arrowComponent = []

    for (var i = 1; i <= 8; i++) {
      arrowComponent.push(<ArrowComponent key={i} currentSectionId={currentSectionId && currentSectionId[name]} previousSectionId={previousSectionId} name={name} id={i} onClick={onClick} readonly={readonly}/>)
    }

    return (
      <div className="attractiveness-section">
        <div className="section-icon">
          <img src={iconSrc} alt="heading" />
        </div>
        <div className="section-evaluate">
          <div className="arrow-component">
            {arrowComponent}
            <ArrowHead stroke={2} className="arrow-icon" />
            <div className="levels-text">
              <div className="align-mid">
                Low
              </div>
              <div className="align-mid">
                Mid
              </div>
              <div className="align-mid">
                High
              </div>
              <div className="align-mid">
                Super High
              </div>
            </div>
          </div>
          <div className="section-heading">
            {heading}
          </div>
          {
            infoText.map((text, id) => (
              <div key={id} className="info-text">
                {text.text}
              </div>
            ))
          }
        </div>
        {!isWithoutNavigation && currentSectionId
          ? (
            <div className="section-comment">
              <CommentOrigin opportunity={currentSectionId} name={name} title={heading} />
            </div>
          )
          : null}
      </div>
    )
  }
}

export default AttractivenessSection
