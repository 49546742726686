import React, {Component, Fragment} from 'react'
import {Tooltip} from 'reactstrap'

class TooltipItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipOpen: false,
    }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleTooltip = () => {
    this.setState({
      isTooltipOpen: !this.state.isTooltipOpen
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isTooltipOpen: false,
      })
    }
  }

  render() {
    const {isTooltipOpen} = this.state
    const {
      type,
      onClick,
      segmentId,
      opportunities,
      opportunity,
      agileFocusId,
      children,
      typeId,
      tooltipClassName,
      readonly
    } = this.props

    if (type === "toggle-sub-segment") {
      return (
        <Fragment>
          <Tooltip
            placement="right"
            isOpen={isTooltipOpen}
            target={"Tooltip-" + segmentId}
            toggle={this.toggleTooltip}
            trigger="hover"
          >
            Add sub-segment
          </Tooltip>
          <img id={"Tooltip-" + segmentId} className={`add-sub-segment-icon ${readonly ? 'readonly' : ''}`} src="/assets/icons/add-icon.png" alt="add"
               onClick={onClick} />

        </Fragment>
      );
    } else if (type === "add-section") {
      return (
        <Fragment>
          <Tooltip
            placement="right"
            isOpen={isTooltipOpen}
            target={"Tooltip-" + typeId}
            toggle={this.toggleTooltip}
            trigger="hover"
          >
            Add a new column
          </Tooltip>
          <img id={"Tooltip-" + typeId} className={`add-icon ${readonly ? 'readonly' : ''}`} src="/assets/icons/add-icon.png" alt="add"
               onClick={onClick}/>
        </Fragment>
      )
    } else if (type === "delete-section") {
      return (
        <Fragment>
          <Tooltip
            placement="right"
            isOpen={isTooltipOpen}
            target="deleteTooltip"
            toggle={this.toggleTooltip}
            trigger="hover"
          >
            Delete column
          </Tooltip>
          <img id="deleteTooltip" className={`delete-icon ${readonly ? 'readonly' : ''}`} src="/assets/icons/add-icon.png" alt="delete"
               onClick={onClick}/>
        </Fragment>
      )
    } else if (type === 'boxes') {
      if (opportunities) {
        const {overallChallenge, overallPotential} = opportunity
        const tooltipOpportunities = opportunities[overallPotential][overallChallenge]

        if (tooltipOpportunities.findIndex(opp => opp.title === opportunity.title) === tooltipOpportunities.length - 1) {
          return (
            <Fragment>
              {tooltipOpportunities.length > 1 ?
                <Tooltip
                  placement="top"
                  isOpen={isTooltipOpen}
                  target={"Tooltip-" + overallPotential + overallChallenge}
                  toggle={this.toggleTooltip}
                  trigger="click"
                  className="box-tooltip"
                >
                  <div className="tooltip-container container" ref={this.setWrapperRef}>
                    <div className="row">
                      {tooltipOpportunities.map((opp, key) => (
                        <div key={key} className={`${tooltipOpportunities.length === 2 ? 'col-sm-6' : 'col-sm-4'}`}>
                          <div className="box">
                            <div className="title">
                              {opp.title}
                            </div>
                          </div>
                        </div>
                      ))
                      }
                      <img className={`close-tooltip-icon ${readonly ? 'readonly' : ''}`} onClick={this.toggleTooltip}
                           src="/assets/icons/delete-icon.png" alt="close"/>
                    </div>
                  </div>
                </Tooltip>
                : null
              }
              {children}
            </Fragment>
          )
        } else return children
      }
    } else if (type === 'agile-focus-boxes') {
      if (opportunities) {
        const {agileFocusOption} = opportunity
        const tooltipOpportunities = opportunities

        if (agileFocusId === tooltipOpportunities.length - 1) {
          return (
            <Fragment>
              {tooltipOpportunities.length > 1 ?
                <Tooltip
                  placement="top"
                  isOpen={isTooltipOpen}
                  target={"Tooltip-" + agileFocusOption}
                  toggle={this.toggleTooltip}
                  trigger="click"
                  className="box-tooltip"
                >
                  <div className="tooltip-container container" ref={this.setWrapperRef}>
                    <div className="row">
                      {tooltipOpportunities.map((opp, id) => {
                        if ((agileFocusOption === 'keepOpen' && id >= 8) || (agileFocusOption === 'placeInStorage' && id >= 14)) {
                          return (
                            <div key={id}
                                 className={`${(tooltipOpportunities.slice(8, tooltipOpportunities.length).length === 2) ||
                                 (tooltipOpportunities.slice(14, tooltipOpportunities.length).length === 2) ? 'col-sm-6' : 'col-sm-4'}`}>
                              <div className="box">
                                <div className="title">
                                  {opp.title}
                                </div>
                              </div>
                            </div>
                          )
                        } else if (agileFocusOption !== 'placeInStorage' && agileFocusOption !== 'keepOpen') {
                          return (
                            <div key={id} className={`${tooltipOpportunities.length === 2 ? 'col-sm-6' : 'col-sm-4'}`}>
                              <div className="box">
                                <div className="title">
                                  {opp.title}
                                </div>
                              </div>
                            </div>
                          )
                        } else return null
                      })
                      }
                      <img className={`close-tooltip-icon  ${readonly ? 'readonly' : ''}`} onClick={this.toggleTooltip}
                           src="/assets/icons/delete-icon.png" alt="close"/>
                    </div>
                  </div>
                </Tooltip>
                : null
              }
              {children}
            </Fragment>
          )
        } else return children
      }
    } else {
      return (
        <Fragment>
          <Tooltip
            placement="right"
            isOpen={isTooltipOpen}
            target={"Tooltip-" + typeId}
            toggle={this.toggleTooltip}
            trigger="click"
            className={tooltipClassName ? tooltipClassName : ''}
          >
            <span ref={this.setWrapperRef}>
              {children}
            </span>
          </Tooltip>
          <img id={"Tooltip-" + typeId} className={`question-mark-icon  ${readonly ? 'readonly' : ''}`} src="/assets/icons/question-mark.png"
               alt="question-mark"/>
        </Fragment>
      )
    }
  }
}

export default TooltipItem
