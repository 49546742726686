// @flow
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import './OverviewSection.scss'

import {
  getAllSetOpportunities,
  getAllAgileFocusOpportunities,
  getAllAttractivenessOpportunities,
  getProjectData, getProjectCustomerSegments
} from '../../../store/project/projectSelectors'
import {createUniqueId, getAgileFocusItems, getMemberRole, getTooltipOpportunities} from '../../../utils/helpers'
import TooltipItem from '../../common/worksheet-1/TooltipItem'

import OpportunityContainer from '../view-pages/opportunity-set-page/OpportunityContainer'
import {getUserData} from "../../../store/auth/authSelectors";
import {createAndUpdateSegmentData} from "../../../store/project/projectActions";

const OverviewSection = ({link, heading, imageSrc, iconSrc, textPart1, textPart2, subSection, arrowsSrc, isWithoutNavigation, linkType }) => {
  const [isNextOpportunityVisible, setIsNextOpportunityVisible] = useState(false);
  const dispatch = useDispatch();
  const opportunities = useSelector(state => getAllSetOpportunities(state));
  const agileOpportunities = useSelector(state => getAllAgileFocusOpportunities(state));
  const currentProject = useSelector(state => getProjectData(state));
  const customerSegments = useSelector(state => getProjectCustomerSegments(state));
  const user = useSelector(state => getUserData(state));
  let keepOpenItems = getAgileFocusItems(agileOpportunities, 'keepOpen');
  let pursueNowItems = getAgileFocusItems(agileOpportunities, 'pursueNow');
  let placeInStorageItems = getAgileFocusItems(agileOpportunities, 'placeInStorage');

  const attractivenessOpportunities = useSelector(state => getAllAttractivenessOpportunities(state));
  const tooltipOpportunities = getTooltipOpportunities(attractivenessOpportunities);

  const readonly = getMemberRole(currentProject, user?.user?.email) === 'canView';

  let opportunityCount = [
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
  ];

  const toggleNewOpportunity = (value) => {
    setIsNextOpportunityVisible(value);
  };

  const handleDeleteOpportunity = (opptId) => {
    let segmentId = customerSegments.findIndex(segment => segment && segment.uid === opptId)
    let opportunityId = -1

    if (segmentId !== -1) {
      if (!customerSegments[segmentId].isInList) {
        customerSegments.splice(segmentId, 1);
      } else {
        customerSegments[segmentId].isOnSet = false;
      }
    } else {
      customerSegments.map(segment => {
        if (opportunityId === -1) {
          opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === opptId));
          segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid);
        }
      })

      if (customerSegments[segmentId].opportunities[opportunityId]) {
        if (!customerSegments[segmentId].opportunities[opportunityId].isInList) {
          customerSegments[segmentId].opportunities.splice(opportunityId, 1);
        } else {
          customerSegments[segmentId].opportunities[opportunityId].isOnSet = false;
        }
      }
    }

    dispatch(createAndUpdateSegmentData(customerSegments));
  };

  const handleOpportunityChange = ({ target: { value } }, opptId, segId) => {
    if(value === "") handleDeleteOpportunity(opptId);

    else {
      if(segId) {
        const segIndex = customerSegments.findIndex(seg => seg && seg.uid === segId);
        const opptIndex = segIndex >= 0 ?
          customerSegments[segIndex].opportunities.findIndex(oppt => oppt && oppt.uid === opptId):
          null;

        if(opptIndex !== null) {
          customerSegments[segIndex].opportunities[opptIndex].title = value;
        }
      }

      else {
        const opptIndex = customerSegments.findIndex(seg => seg && seg.uid === opptId);

        if(opptIndex >= 0) {
          customerSegments[opptIndex].title = value;
        }
      }

      dispatch(createAndUpdateSegmentData(customerSegments));
    }
  };

  const handleUpdateOpportunity = ({ target: { value } }, opptId) => {
    let segmentId = customerSegments.findIndex(segment => segment && segment.uid === opptId);
    let opportunityId = -1;

    if (value === '') handleDeleteOpportunity(opptId);

    else {
      if (segmentId !== -1) {
        customerSegments[segmentId].title = value
      }

      else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === opptId))
            segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        customerSegments[segmentId].opportunities[opportunityId].title = value
      }

      dispatch(createAndUpdateSegmentData(customerSegments));
    }
  };

  const handleNewOpportunity = ({ target: { value } }) => {
    if (value !== '') {
      let newOpportunity = {
        title: value,
        isInList: false,
        isOnSet: true,
        opportunities: [],
        sectionId: -1,
        uid: createUniqueId(),
        originNameId: createUniqueId(),
      }

      dispatch(createAndUpdateSegmentData([ ...customerSegments, newOpportunity ]));
    }
  };

  return (
    <div className={`overview-section ${
      link === '/market-opportunity-set' ? 'set' :
        link === '/attractiveness-map' ? 'map' :
          link === '/agile-focus-dartboard' ? 'agile' : ''}`}>
        {linkType &&
        <>
          <div className="section-heading">
            {heading}
          </div>
          <div className="details top">
            <div className={`section-icon ${link === '/agile-focus-dartboard' ? 'smaller' : ''}`}>
              <img src={iconSrc} alt={'sheet-icon'}/>
            </div>
            <div className="section-text">
              {textPart1}
              {' '}
              <Link className="link" to={subSection.link}>
                {subSection.heading}
              </Link>
              {' '}
              {textPart2}
            </div>
          </div>
        </>
      }
      <Link to={""}>
        <div className={`section-image ${link === '/attractiveness-map' ? 'map' : ''}`}>
          <img src={imageSrc} alt={heading}/>
          {link === '/market-opportunity-set' && (
            (opportunities.length === 0 && !isNextOpportunityVisible) ? (
              <div className="opportunity-set-controls empty">
                <div className="no-opportunity">
                  <div className="no-opportunity-text">
                    Select the market opportunities from
                    <br/>
                    <Link className="no-opportunity-text link" to={'/worksheet-1'}>Worksheet 1</Link>
                    {' '}
                    to appear here. You can also add opportunities or edit them manually
                  </div>

                  <div
                    onClick={() => toggleNewOpportunity(true)}
                    className={`plus-sign empty ${readonly ? 'readonly' : ''}`}>
                    +
                  </div>
                </div>
              </div>
            ) : (
              <div className="opportunity-set-controls">
                <div className="opportunity-wrapper">
                  <div className="has-opportunity">
                    {opportunities.map((opportunity, key) => opportunity.isOnSet && key < 9 && (
                        <OpportunityContainer
                          key={key}
                          opportunity={opportunity}
                          onDeleteOpportunity={handleDeleteOpportunity}
                          onOpportunityChange={handleOpportunityChange}
                          onUpdateOpportunity={handleUpdateOpportunity}
                          isWithoutNavigation={isWithoutNavigation}
                          readonly={readonly}
                        />
                      )
                    )}

                    {isNextOpportunityVisible && (
                      <OpportunityContainer
                        opportunity={null}
                        onDeleteOpportunity={handleDeleteOpportunity}
                        onOpportunityChange={handleOpportunityChange}
                        onUpdateOpportunity={handleUpdateOpportunity}
                        onNewOpportunity={handleNewOpportunity}
                        onToggleNewOpportunity={toggleNewOpportunity}
                        isWithoutNavigation={isWithoutNavigation}
                        readonly={readonly}
                      />
                    )}

                    {((isNextOpportunityVisible && opportunities.length < 8)
                      || (!isNextOpportunityVisible && opportunities.length < 9)) && (
                      <div
                        onClick={() => toggleNewOpportunity(true)}
                        className={`plus-sign ${readonly ? 'readonly' : ''}`}>
                        +
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          )}

          {
            link === '/attractiveness-map'
              ? (
                <div className="attractiveness-map-element">
                  {attractivenessOpportunities.map((opportunity, key) => {
                    const {overallChallenge, overallPotential, app, title} = opportunity;
                    const application = app && app?.title ? app.title: null;

                    opportunityCount[overallPotential][overallChallenge]++;

                    return (
                      <TooltipItem
                        key={key}
                        type="boxes"
                        onClick={null}
                        segmentId={null}
                        opportunities={tooltipOpportunities}
                        opportunity={opportunity}
                      >
                        <div
                          className={`
                        ${overallChallenge === 1 ? 'xlowmid' :
                            overallChallenge === 2 ? 'xmid' :
                              overallChallenge === 3 ? 'xmidhigh' :
                                overallChallenge === 4 ? 'xhigh' :
                                  overallChallenge === 5 ? 'xhighsuper' :
                                    overallChallenge === 6 ? 'xsuper' : ''
                          }
                        ${overallPotential === 1 ? 'ylowmid' :
                            overallPotential === 2 ? 'ymid' :
                              overallPotential === 3 ? 'ymidhigh' :
                                overallPotential === 4 ? 'yhigh' :
                                  overallPotential === 5 ? 'yhighsuper' :
                                    overallPotential === 6 ? 'ysuper' : ''
                          }
                        box
                        ${opportunityCount[overallPotential][overallChallenge] === 2 ? 'second' :
                            opportunityCount[overallPotential][overallChallenge] === 3 ? 'third' : ''
                          }`}
                          id={"Tooltip-" + overallPotential + overallChallenge}
                        >
                          <div className="title">
                            {application && <span className={"sticky-note__app"}>{application}</span>}
                            <span className={"sticky-note__title"}>{title}</span>
                          </div>
                        </div>
                      </TooltipItem>
                    )
                  })}
                </div>
              ) : null

          }

          {
            link === '/agile-focus-dartboard'
              ? (
                <div className="agile-focus-element">
                  {placeInStorageItems.map((item, id) => {
                    let positionClassName

                    if (id < 5) {
                      positionClassName = 'left'
                    } else if (id >= 5 && id < 9) {
                      positionClassName = 'bottom'
                    } else if (id >= 9 && id < 14) {
                      positionClassName = 'right'
                    } else {
                      positionClassName = `top-right-${id - 13}`
                    }

                    const application = item?.app?.title || null;

                    if (id >= 14) {
                      return (
                        <TooltipItem
                          key={id}
                          type="agile-focus-boxes"
                          onClick={null}
                          segmentId={null}
                          opportunities={placeInStorageItems}
                          opportunity={item}
                          agileFocusId={id}
                        >
                          <div id={"Tooltip-" + item.agileFocusOption}
                               className={`box place-in-storage ${positionClassName}`}>
                            <div className="title">
                              <span className={"sticky-note__app"}>{application}</span>
                              <span className={"sticky-note__title"}>{item.title}</span>
                            </div>
                          </div>
                        </TooltipItem>
                      )
                    } else {
                      return (
                        <div key={id} className={`box place-in-storage ${positionClassName}
                          ${id === 1 || id === 6 || id === 10 ? 'second' :
                          id === 2 || id === 7 || id === 11 ? 'third' :
                            id === 3 || id === 8 || id === 12 ? 'fourth' :
                              id === 4 || id === 13 ? 'fifth' : ''
                        }
                        `}
                        >
                          <div className="title">
                            <span className={"sticky-note__app"}>{application}</span>
                            <span className={"sticky-note__title"}>{item.title}</span>
                          </div>
                        </div>
                      )
                    }
                  })}
                  {keepOpenItems.map((item, id) => {
                    let positionClassName

                    if (id < 3) {
                      positionClassName = 'left'
                    } else if (id >= 3 && id < 5) {
                      positionClassName = 'bottom'
                    } else if (id >= 5 && id < 8) {
                      positionClassName = 'right'
                    } else {
                      positionClassName = `top-right-${id - 7}`
                    }

                    const application = item?.app?.title || null;

                    if (id >= 8) {
                      return (
                        <TooltipItem
                          key={id}
                          type="agile-focus-boxes"
                          onClick={null}
                          segmentId={null}
                          opportunities={keepOpenItems}
                          opportunity={item}
                          agileFocusId={id}
                        >
                          <div id={"Tooltip-" + item.agileFocusOption} className={`box keep-open ${positionClassName}`}>
                            <div className="title">
                              <span className={"sticky-note__app"}>{application}</span>
                              <span className={"sticky-note__title"}>{item.title}</span>
                            </div>
                          </div>
                        </TooltipItem>
                      )
                    } else {
                      return (
                        <div key={id} className={`box keep-open ${positionClassName}
                          ${id === 1 || id === 4 || id === 6 ? 'second' :
                          id === 2 || id === 7 ? 'third' : ''
                        }
                        `}
                        >
                          <div className="title">
                            <span className={"sticky-note__app"}>{application}</span>
                            <span className={"sticky-note__title"}>{item.title}</span>
                          </div>
                        </div>
                      )
                    }
                  })}
                  {pursueNowItems.map((item, id) => {
                    const positionClassName = `middle-${id + 1}`
                    const application = item?.app?.title || null;

                    return (
                      <TooltipItem
                        key={id}
                        type="agile-focus-boxes"
                        onClick={null}
                        segmentId={null}
                        opportunities={pursueNowItems}
                        opportunity={item}
                        agileFocusId={id}
                      >
                        <div id={"Tooltip-" + item.agileFocusOption} className={`box pursue-now ${positionClassName}`}>
                          <div className="title">
                            <span className={"sticky-note__app"}>{application}</span>
                            <span className={"sticky-note__title"}>{item.title}</span>
                          </div>
                        </div>
                      </TooltipItem>
                    )
                  })}
                </div>
              ) : null
          }
        </div>
      </Link>
      {!linkType &&
        <>
          <div className="section-heading">
            {heading}
          </div>
          <div className="details">
            <div className={`section-icon ${link === '/agile-focus-dartboard' ? 'smaller' : ''}`}>
              <img src={iconSrc} alt={'sheet-icon'}/>
            </div>
            <div className="section-text">
              {textPart1}
              {' '}
              <Link className="link" to={subSection.link}>
                {subSection.heading}
              </Link>
              {' '}
              {textPart2}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default OverviewSection
