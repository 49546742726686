import React, { Component } from 'react';
import { connect } from 'react-redux'

import Projects from '../Dropdown';
import TooltipItem from '../worksheet-1/TooltipItem'
import {
  getUpdateStatus,
  getAgileFocusDropdownOpportunities,
  getCurrentAgileFocusPrimaryOpportunityId,
  getProjectCustomerSegments,
  getNonTakenAgileFocusDropdownOpportunities,
} from '../../../store/project/projectSelectors'
import { createAndUpdateSegmentData, setAndSaveProjectData } from '../../../store/project/projectActions'
import '../../pages/worksheet-page/WorksheetPage3.scss';

class PrimaryOpportunitySection extends Component  {
  constructor(props) {
    super(props)

    this.state = {
      opportunities: props.opportunities,
      currentOpportunity: props.opportunities.find(opp => opp && opp.uid === props.currentPrimaryOpportunityId),
      customerSegments: props.customerSegments,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({
        opportunities: this.props.opportunities,
        currentOpportunity: this.props.opportunities.find(opp => opp && opp.uid === this.props.currentPrimaryOpportunityId),
        customerSegments: this.props.customerSegments,
      })
    }
  }

  handleUpdateOpportunity = (name, value, opportunity) => {
    const { onCreateAndUpdateSegmentData, onSetAndSaveProjectData } = this.props
    const { currentOpportunity, customerSegments } = this.state

    let currentSegmentId = -1
    let nextSegmentId = -1
    let opportunityId = -1

    if (currentOpportunity) {
      currentSegmentId = customerSegments.findIndex(segment => segment && segment.uid === currentOpportunity.uid)
      currentOpportunity[name] = null

      if (currentSegmentId !== -1) {
        customerSegments[currentSegmentId][name] = null
      } else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === currentOpportunity.uid))
            currentSegmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        if (customerSegments[currentSegmentId]) {
          customerSegments[currentSegmentId].opportunities[opportunityId][name] = null
        }
      }
    }

    opportunityId = -1

    if (opportunity) {
      nextSegmentId = customerSegments.findIndex(segment => segment && segment.uid === opportunity.uid)
      opportunity[name] = value

      if (nextSegmentId !== -1) {
        customerSegments[nextSegmentId][name] = value
      } else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === opportunity.uid))
            nextSegmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        if (customerSegments[nextSegmentId]) {
          customerSegments[nextSegmentId].opportunities[opportunityId][name] = value
        }
      }

      this.setState({
        currentOpportunity: opportunity,
      })
      onSetAndSaveProjectData("currentAgileFocusPrimaryOpportunityId", opportunity.uid)
    } else {
      this.setState({
        currentOpportunity: null,
      })
      onSetAndSaveProjectData("currentAgileFocusPrimaryOpportunityId", null)
    }

    onCreateAndUpdateSegmentData(customerSegments)
  }

  handleDropdownClick = (opportunity) => {
    this.handleUpdateOpportunity('agileFocusOption', 'pursueNow', opportunity)
  }

  render() {
    const { currentOpportunity } = this.state
    const { nonTakenOpportunities } = this.props

    return (
      <div className='row section-row-sm'>
        <div className="col-sm-3">
          <TooltipItem
            typeId="agile-1"
            tooltipClassName="agile-focus-tooltip"
          >
            The <b>primary market opportunity</b> is the option that you want to focus on now, investing most of your resources in pursuing it.<br />
            <a href="assets/examples/flyability_example_Worksheet_3.pdf" className="tooltip-link" target="_blank">See example</a>
          </TooltipItem>
          <div className='section-text'>
            <div className="row">
              <div className="col-sm-1 number">
                I.
              </div>
              <div className="col-sm-11 text">
                Choose a Primary Market Opportunity to focus on (based on the
                Attractiveness Map).
              </div>
            </div>
          </div>
        </div>
        <div className='selection-container col-sm-9 white-bg radius-bl radius-br pt-3'>
          <img className="box-icon" src='/assets/icons/box-icon.png' alt='opportunity' />
          <Projects
            title='Select a primary opportunity'
            items={nonTakenOpportunities}
            color='primary'
            onDropdownClick={this.handleDropdownClick}
            currentItem={currentOpportunity}
            className='h-min-width'
            isResetOption
            primary
          />
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  updateStatus: getUpdateStatus(state),
  opportunities: getAgileFocusDropdownOpportunities(state),
  currentPrimaryOpportunityId: getCurrentAgileFocusPrimaryOpportunityId(state),
  customerSegments: getProjectCustomerSegments(state),
  nonTakenOpportunities: getNonTakenAgileFocusDropdownOpportunities(state),
})

const mapDispatch = (dispatch) => ({
  onCreateAndUpdateSegmentData: (segments) => dispatch(createAndUpdateSegmentData(segments)),
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
})

export default connect(mapState, mapDispatch)(PrimaryOpportunitySection)
