import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ isChecked, onCheckboxClick }) => {
  return (
    <div className='checkbox-element'>
      <input
        type='checkbox'
        className='checkbox'
        checked={isChecked}
        readOnly
      />
      <span onClick={onCheckboxClick}></span>
    </div>
  );
};

export default Checkbox;
