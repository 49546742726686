import React from "react"
import ReactPlayer from "react-player"

import BasePage from '../BasePage';
import './VideoPage.scss'

function VideoPage() {
  return (
    <BasePage>
      <div className="video-page">
        <div id={"tooltip"} />
        <div className="heading">
          <span>
            <b>If you see this video in poor quality, please change its resolution by clicking on the Youtube Settings button and selecting a higher quality</b>
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className='player-wrapper'>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=-UxLgf-te-0&feature=youtu.be&vq=hd1080"
                width="100rem"
                height="55rem"
                controls={true}
              />
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  )
}

export default VideoPage
