import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import './ShareContainer.scss'
import Cross from '../../assets/svgs/Cross'
import {addMember, sendMail} from "../../store/project/projectActions";
import {connect} from "react-redux";
import {getProjectData} from "../../store/project/projectSelectors";
import {FRONTEND_URL} from "../../utils/constants";
import { Spinner } from "reactstrap";


const ShareContainer = ({share, setShare, sendMail, addMember, currentProject}) => {
  const {register, errors, handleSubmit} = useForm();
  const [radioError, setRadioError] = useState(false);
  const [sucessful, setSucessful] = useState(false);
  const [pending, setPending] = useState(false);


  const onSubmit = async (data) => {
    if (data.driver === null || (errors && errors.email) || data.email === '') {
      setRadioError(true);
    } else if (data.driver !== null && (!errors || !errors.email)) {
      try {
        setPending(true);

        await addMember(currentProject.id, data.email, data.driver);
        await sendMail(data.email, FRONTEND_URL, currentProject.title, data.driver );

        setRadioError(false);
        setSucessful(true);
        setTimeout(() => setShare(!share), 2000);
      } catch (e) {
        setPending(false);
        setRadioError(true);
      }
    }
  }

  return (
    <div className="share-container">
      <div className={`${!share ? '' : 'share-only'}`}></div>
      <div className="share-main-container">
        <div className=" this-project">
          <div className="share-container-row">
            <div className="shared">
              Share this project
            </div>
            <span onClick={() => setShare(!share)} className="share-cross"><Cross/></span>
          </div>
          <div className="">
            <div className="project-named">
              {currentProject.title}
            </div>
          </div>
        </div>
        <div className="">
          <div className="invite-text-fixed">
            Invite by email address
          </div>
          {sucessful === true &&
          <p className="share-sucessful">
            Share was sucessful
          </p>
          }
          <div className="emailsh">
            {errors && errors.email ? 'Email is invalid!' : 'Email*'} {radioError ? 'Please, select option and type a valid Email' : ''}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register('email', {
                pattern: {
                  required: true,
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                }
              })}
              className={`email-field ${errors && errors.email ? `error-email` : `success-email`}`}
              placeholder='Enter email address'
            />
            <input type="radio" value='canView' {...register('driver')} className=" checkboxes view"/>
            <div className="check-text view">
              Can view
            </div>
            <input type="radio" value='canEdit' {...register('driver')} className="checkboxes edit edit-right"/>
            <div className="check-text-edit edit">
              Can edit
            </div>
            <div className="bottom-buttons">
              {sucessful === true ?
                <button style={{pointerEvents: 'none'}} className="share-btn-send invited">
                  Invited!
                </button>
                :
                <button type="submit" className="share-btn-send">
                  {pending ? <Spinner color="light" />: "Send invite"}
                </button>
              }
              <button onClick={() => setShare(!share)} className="share-btn-cancel">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  currentProject: getProjectData(state),
})

const mapDispatch = (dispatch) => ({
  sendMail: (email, feUrl, projectTitle, role) => dispatch(sendMail(email, feUrl, projectTitle, role)),
  addMember: (projectId, email, role) => dispatch(addMember(projectId, email, role)),
})


export default connect(mapState, mapDispatch)(ShareContainer)
