// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Footer from '../common/Footer'
import './AuthorizationPage.scss'
import './PrivacyPage.scss'

class TermsPage extends Component {

  render() {

    return (
      <div className="privacy-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="heading">
              <span className="title">
                Terms and Conditions
              </span>
            </div>
            
          </div>
          <div className="row overview-wrapper">
            <div className="overview-block">
              <ul className="overview-list no-bullet">
                <li>
                  The following terms and conditions govern all use of the ENTC-EPFL market opportunities navigator (the “<b>Service</b>“) and all content and services available at or through <a href="https://app.wheretoplay.co" target="_blank">www.app.wheretoplay.co</a> (the “<b>Website</b>“). 
                  <br /><br />
                  The Service is owned and operated by the Entrepreneurship and Technology Commercialization, ENTC-EPFL, Station 5, CH-1015 Lausanne (“<b>ENTC</b>”, also referred to as ”we”, “us”, “our”). The Service is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other associated features (including, without limitation, Website's Privacy Policy and procedures that may be published from time to time on the Website by ENTC (collectively, the “<b>Terms</b>”). 
                </li>
                <li>
                  If you are entering into these Terms on behalf of a company or other legal entity, you represent that you have the authority to bind such entity, its affiliates and all users who access the Service through your account to these terms and conditions, in which case the terms “you” or “your” shall refer to such entity, its affiliates and users associated with it. If you do not have such authority, or if you do not agree with these Terms you must not accept this agreement and may not use the Service.
                </li>
                <li>
                  Please read these Terms carefully before accessing or using the Service. By accessing or using any part of the Website, you agree to become bound by these Terms. If you do not agree to all of the Terms, then you may not access the Service and Website.
                </li>
                <li>
                  ENTC reserves the right to modify these Terms at any time and modified terms are in effect upon posting to this Website. Your continued use of or access to the Service following the posting of any changes to these Terms constitutes acceptance of those changes. ENTC may also, in the future, offer new services and/or features including the release of new tools and products. Such new features and/or services shall be subject to the terms and conditions of these Terms.
                </li>
                <li>
                  If you are not eligible by law of your residency to contract with us with regard to the use of this Website or any of its parts or features, please do not use the Website. If we found out that you are not eligible as mentioned we may prevent your use of the Website.
                </li>
                <li>
                  <b>
                  Please note: the Website and Service are provided "as is" without warranties of merchantability, fitness for a particular purpose; Service does not provide any analysis or conclusions for market opportunities or any other business conclusions, but only allows users to use worksheets as a tool for conducting their own market opportunities analysis.
                  </b>
                </li>
                <li>
                  We reserve the right to redesign the Website, add features, detract from or alter the Website and make any changes in its features at our discretion.
                </li>
                <li>
                  By supplying data to the Website, you represent and confirm that you have all the necessary rights, including intellectual property rights, required to submit and use the data in accordance with and subject to these Terms.
                </li>

                <li>
                  <b>
                    You hereby grant us a transferable, worldwide, royalty-free right to utilize, analyze, process, copy, store, modify and create derivative works of the data you submit to the Website and to the Service on an aggregated and non-identifiable forms (“Aggregated Anonymous Data") for ENTC's research purposes, including without limitation for testing, analytics, and development purposes.
                  </b>
                <br /><br />
                  <b>
                    For the avoidance of doubt, Aggregated Anonymous Data may include data derived from your account data, provided that Aggregated Anonymous Data does not identify you or your business.  
                  </b>
                </li>
                <li>
                  We encourage you to share and use our Market Opportunity Navigator for non-commercial purposes while making appropriate credit to our Website all according to Creative Commons Attribution Non-Commercial ShareAlike 4.0 International Public License.
                </li>
                <li>
                  Subject to the above, you can use Market Opportunity Navigator for your own work or to support others in understanding, analyzing or changing their market opportunity strategy. This includes people who use the tools for teaching or consulting. The requirement is full identification and credit of the source of the tool: www.wheretoplay.co.
                </li>

                <li>
                  All intellectual property rights relating to the Website and Services, including its content, the source code of the Website, title, user interfaces, presentations, assays, analysis, evaluations, platforms, music, videos, images, digital files, logos, artwork, marks, selection, arrangement, and graphic design (the “Content”) and placement of the Content, are ours or belong to the entities which gave us permission to use them and are protected by various intellectual property rights laws. You hereby agree not to change, interfere, decompile, reverse-engineer, transfer, assign, rent, resell, imbed, record, broadcast, transmit, post, publicly display, the Website and its Content. Nevertheless, as mentioned above you can use our Market Opportunity Navigator according to the Creative Commons License as provided under section 7 above.
                  <br /><br />
                  We disclaim all responsibilities and liabilities for the use of the content in the Website.
                  <br /><br />
                  “Where To Play” is a trademark owned by us. You can use our logo in a fair and respectable way for credit the site for its Content. We can revoke this permission by sending you a notice.
                </li>

                <li>
                  You should not try to make unauthorized access to the Website or assist others in making such attempts, or distributing instructions, software or tools for that purpose. You are strictly prohibited from using the Website for cyber-attacks, distribution of viruses, distribution of malicious software, or for any illegal purpose or action prohibited by these Terms.
                </li>
                <li>
                  We reserve the right, at any time, to condition the access to the Website, or any part of, with payment of fees.
                </li>
                <li>
                  You are not allowed to use any programmed device, algorithm or system, or any similar or equivalent manual or automatic process, to access, gain, copy, investigate, test, intrude or monitor any part of the Website or of any Content, or in any way duplicate or circumvent the navigational structure or appearance of the Website or of any Content, to acquire or try to acquire any materials, documents or information through any way not intentionally made obtainable by us through the Website. 
                </li>
                <li>
                  You agree to use the Website in a reasonable manner as a single and regular user and not to take any action that might intervene or disable or adversely affect the proper use of other users in the Website's features and its routine functionality or might create malfunction or disturbances in the operation of the Website, its features and other related systems. For the abovementioned purposes we may block, limit and/or control your use in the Website and its features and set rules for time to time with that regard and you shall have no claim, suit or demand against us in connection with the above. 
                </li>
                <li>
                  Although we take various measures to secure Your user account information and any other information about you in the Website or in its related systems, we do not guarantee that there won’t be any unauthorized access by third party to the information and you assume all risks relating to this and we make no warranty, express, implied, or otherwise, in that regard. IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES (WHETHER CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR OTHERWISE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, A THIRD PARTY’S UNAUTHORIZED ACCESS TO THE AFOREMENTIONED INFORMATION, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT OR OTHER THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER WE WERE GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE.
                </li>
                <li>
                  You grant us a permission to send you promotional content pertaining the Service by e-mail, or by any other mean of communications that your various addresses are linked to, including your mobile phone. To stop receiving promotional content, send us an e-mail to: <a href="mailto:info@wheretoplay.co">info@wheretoplay.co</a> or use the unsubscribe methods included in the message sent to you.
                </li>
                <li>
                  You shall indemnify, defend, and hold harmless us and our affiliates and their directors, officers, and employees from and against all costs, damages, losses and expenses, including reasonable attorneys’ fees and other legal expenses, arising from a third-party claim due to or arising out of any use of the Website by you. We shall make reasonable efforts to notify you of any such claim made against it. If you fail to assume such defense, then we may defend ourselves in any manner that we deem appropriate, and you agree to bear all costs, including reasonable attorneys’ fees, that we incur in effecting such defense in addition to any sum that we may be required to pay by reason of any settlement or judgment against us. The provisions of this Section, and the indemnity hereunder, shall survive these Terms and any performance hereunder. 
                </li>
                <li>
                  THE WEBSITE, SERVICE, FEATURES, CONTENT, ARTWORK, DATA, AND INFORMATION WHICH ARE PART OF THE WEBSITE ARE PROVIDED ON AN “AS-IS” BASIS WITHOUT ANY WARRANTY. WE DO NOT GUARANTY THAT THEY SHALL WORK PERFECTLY, OR THAT THEY WILL BE AVAILABLE AT ALL TIMES OR THAT THEY ARE ACCURATE NOR THAT MALFUNCTION, DISTURBANCE, INTERRUPTION, SHUT DOWNS WILL NOT OCCUR. WE DISCLAIM ALL WARRANTIES AND/OR CONDITIONS, EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO OR REFERENCED BY THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY OR QUALITY OF DATA AND FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY, TITLE, NON-INFRINGEMENT, LACK OF VIRUSES OR CORRESPONDENCE TO DESCRIPTION.
                </li>
                <li>
                  THE ENTIRE RISK ARISING OUT OF THE USE OF THE WEBSITE, THE USE OF ANY FEATURES OFFERED ON OR IN CONNECTION WITH THE WEBSITE, AND/OR THE USE OF ANY CONTENT IS SOELY YOURS. IN NO EVENT SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR AFFILIATES BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER PECUNIARY LOSS) ARISING OUT OF THESE TERMS OR THE USE OF: ANY CONTENT IN THE WEBSITE, ITS FEATURES OR IN CONNECTION WITH THEM, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OR POSTED ON THE WEBSITE, THE MAXIMUM LIABILITY THAT WE SHALL HAVE IS LIMITED TO ANY AMOUNTS ACTUALLY PAID TO US BY YOU. YOU ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS OF LIABILITY, DISCLAIMERS OF WARRANTIES AND LIMITED REMEDIES SET FORTH HEREIN REPRESENT AN INSEPARABLE ALLOCATION OF RISK (INCLUDING, WITHOUT LIMITATION, IN THE EVENT OF A TOTAL AND FUNDAMENTAL BREACH OF THESE TERMS) THAT IS AN ESSENTIAL BASIS OF THE UNDERSTANDING BETWEEN THE PARTIES.
                </li>
                <li>
                  We are not responsible for any information or content uploaded, posted, and transferred through the Website, Service or its features by you or by other users. We don’t promise to store it or make it available at all times.
                </li>
                <li>
                  We also do not warrant that the Content is completely and entirely correct, accurate or up-to-date. YOU AGREE TO WAIVE RIGHTS OR REMEDIES YOU MAY HAVE AGAINST US WITH RESPECT THERETO. ANY USE OR RELIANCE ON ANY CONTENT IN THE WEBSITE OR OBTAINED BY YOU THROUGH THE WEBSITE IS SOLELY AT YOUR OWN RISK.
                </li>
                <li>
                  Any downloading of files through the Website is the sole responsibility of the user, including the effect of this download on the user’s devices.
                </li>
                <li>
                  If you are under the age of 18 do not use the Website features without parent guidance.
                </li>
                <li>
                  Governing law of the Terms will be the Swiss law, and the exclusive jurisdiction regarding any dispute regarding the use of the Website shall be determined solely by the courts in Lausanne, CH, without derogating from our right to seek relief in any other court, in order to achieve injunctions or restraining orders or other order to enforce its rights.
                </li>
                <li>
                  These Terms constitute the entire agreement and understandings between us with regard to the use of the Website and supersedes all prior oral or written understandings, communications or agreements not specifically incorporated herein.
                </li>
                <li>
                  No delay or failure by us to take action under these Terms shall constitute any waiver by us of any provision of these Terms. If any provision of these terms is invalid or unenforceable under applicable law, it is, to that extent, deemed enforceable to the fullest extent possible (and severable in the event such provision is completely unenforceable) and the remaining provisions of this these Terms will continue in full force and effect. These Terms will bind and inure to the benefit of our successors and assigns. 
                </li>
                <li>
                  <b>Contact Us</b> - You can address us by sending us email to: <a href="mailto:entc@epfl.ch">entc@epfl.ch</a>.
                </li>
               </ul>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default withRouter(TermsPage)
