import React from "react";
import ChatTooltip from "./ChatTooltip";

const ChatTokenLimit = ({ tokenUsage = 0 }) => {
  let progressBarColor;

  const tokenUsageText = () => {
    const usage = tokenUsage <= 100 ? tokenUsage : 100;
    const msg = process.env.REACT_APP_GPT_PROJECT_TOKEN_LIMIT_MSG;
    return msg.replace("{tokenUsage}", usage.toString());
  };

  if (tokenUsage >= 90) {
    progressBarColor = "red";
  } else if (tokenUsage > 83) {
    progressBarColor = "orange";
  } else {
    progressBarColor = "blue";
  }

  return (
    <div className="chat-token-limit">
      <ChatTooltip className="token-limit-tooltip">
        {tokenUsageText()}
      </ChatTooltip>
      <div className="progress">
        <div
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-valuenow={tokenUsage}
          className={`progress-bar ${progressBarColor}`}
          style={{ width: `${tokenUsage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(ChatTokenLimit);
