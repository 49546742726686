import React, { Fragment } from 'react';

import TooltipItem from '../worksheet-1/TooltipItem'
import '../../pages/worksheet-page/WorksheetPage3.scss';

const TextSection = () => (
  <Fragment>
    <div className='row section-row bb-md bt-lg'>
      <div className='col-sm-12'>
        <TooltipItem
          typeId="agile-2"
          tooltipClassName="agile-focus-tooltip"
        >
          Pick other market opportunities to examine possible backup and growth options. <br />
          A <b>backup option</b> answers the question: if we are not succesful, what shall we do next? <br />
          A <b>growth option</b> answers the question: if we are successful, what shall we do next?<br />
          Options should be as tightly related as possible to your primary opportunity, so you can effectively leverage existing resources and capabilities. Yet, backup options should not share the same major risks with your primary opportunity. <br />
          For each candidate option, use this section to assess <b>product and market relatedness</b> with the primary opportunity, and its suitability for backup or future growth. <br/>
          <a href="assets/examples/flyability_example_Worksheet_3.pdf" className="tooltip-link" target="_blank">See example</a>
        </TooltipItem>
        <div className='section-text'>
          <div className="row">
            <div className="col-sm-1 number">
              II.
            </div>
            <div className="col-sm-11 text">
              Pick other attractive market opportunities from your set to
              examine possible Backup and Growth Options.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='row section-row bb-sm'>
      <div className='col-sm-12 white-bg br-md v-align'>
        <span className='section-text'>
          <p className='section-text-intro'>Relatedness to your Primary Market Opportunity:</p>
          <span className='section-heading'>
            PRODUCT RELATEDNESS
          </span>
          <br />
          To what extent do the products share:
          <br /> technological competences, required resources, necessary
          networks
        </span>
      </div>
    </div>
    <div className='row section-row bb-md'>
      <div className='col-sm-12 white-bg br-md v-align'>
        <span className='section-text'>
          <span className='section-heading'>
            MARKET RELATEDNESS
          </span>
          <br /> To what extent do the customers share:
          <br /> values and benefits, sales channels, word-of-mouth
        </span>
      </div>
    </div>
    <div className='row section-row bb-sm'>
      <div className='col-sm-12 white-bg br-md v-align'>
        <span className='section-text'>
          <p className='section-text-intro'>Suitable as:</p>
          <span className='section-heading'>
            BACKUP OPTION
          </span>
          <br /> Attractive market opportunities that do not share major risks
          with your Primary Market Opportunity to allow for a change in
          direction
        </span>
      </div>
    </div>
    <div className='row section-row'>
      <div className='col-sm-12 white-bg br-md d-flex v-align radius-bl'>
        <span className='section-text'>
          <span className='section-heading'>
            GROWTH OPTION
          </span>
          <br /> Attractive market opportunities that allow your business to
          create additional value
        </span>
      </div>
    </div>
    <div className='row section-row bt-lg'>
      <div className='col-sm-12'>
        <TooltipItem
          typeId="agile-3"
          tooltipClassName="agile-focus-tooltip"
        >
          Keep at least one backup and one growth option open. <b>Keeping options open</b> means that you currently allocate little resources and attention to keep it ‘breathing’ and not to lock yourself out of it.<br />
          Other opportunities should be <b>placed in storage</b>- so that you do nothing about them for now.<br />
          In some circumstances, you can also decide that an option is worth <b>pursuing now</b>- in parallel with your primary market.<br />
          <a href="assets/examples/flyability_example_Worksheet_3.pdf" className="tooltip-link" target="_blank">See example</a>
        </TooltipItem>
        <div className='section-text'>
          <div className="row">
            <div className="col-sm-1 number with-line-height">
              III.
            </div>
            <div className="col-sm-11 text">
              <span className="section-text-title">Design your Agile Focus Strategy:</span>{' '}
              <ul>
                <li>Keep at least one Backup and one Growth Option open</li>
                <li>Decide if any option is worth pursuing now</li>
                <li>Place the rest in storage</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default TextSection;
