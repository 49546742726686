import React, { Fragment, useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import ChatTypingIndicator from "./ChatTypingIndicator";

const ChatMessageList = ({
  isLoading,
  messages = [],
  showTokenExceededMessage = false,
}) => {
  const scrollRef = useRef(null);

  // SCROLL TO THE BOTTOM OF THE LIST
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages.length]);

  return (
    <div className="chat-message-list" ref={scrollRef}>
      {messages.map((message, index) => (
        <Fragment key={message.uid}>
          <ChatMessage message={message} />
          {showTokenExceededMessage && index === messages.length - 1 && (
            <ChatMessage
              message={{
                messageType: "incoming",
                messageText:
                  process.env.REACT_APP_GPT_CHAT_TOKEN_EXCEEDED_MSG || "",
              }}
            />
          )}
        </Fragment>
      ))}

      {isLoading && <ChatTypingIndicator />}
    </div>
  );
};

export default React.memo(ChatMessageList);
