import React, {Component} from 'react'

import AbilityInput from './AbilityInput'
import TooltipItem from './TooltipItem'

import './AbilitySection.scss'

class AbilitySection extends Component {
  render() {

    const {
      sectionId,
      sectionAbility,
      onInputChange,
      onUpdateAbilitySection,
      onSubmitFromKeyboard,
      onAddSection,
      onDeleteSection,
      isLastSection,
      readonly
    } = this.props

    let abilityPropertiesComponent = []

    for (var i = 1; i <= 4; i++) {
      let abilityProperty = null

      if (sectionAbility) {
        abilityProperty = sectionAbility.abilityProperties.filter(ability => ability && ability.propertyInputId === i)[0]
      }

      abilityPropertiesComponent.push(
        <AbilityInput
          sectionId={sectionId}
          ability={abilityProperty}
          onInputChange={onInputChange}
          onUpdateAbilitySection={onUpdateAbilitySection}
          onSubmitFromKeyboard={onSubmitFromKeyboard}
          propertySectionId={i}
          key={i}
          readonly={readonly}
        />
      )
    }

    return (
      <div className="ability-section">
        {/* {isLastSection &&
          <TooltipItem
            type="add-section"
            onClick={() => onAddSection()}
            typeId="abilities"
          />
        } */}
        {sectionId > 4 &&
        <img className="delete-icon" src="/assets/icons/add-icon.png" alt="delete" onClick={() => onDeleteSection()}/>
        }
        <div className="ability-title-section">
          <img src="/assets/icons/gear.png" alt="gear"/>
          <input
            className="ability-title"
            onChange={(e) => onInputChange(e, sectionId)}
            onBlur={(e) => onUpdateAbilitySection(e, sectionId)}
            spellCheck="false"
            value={sectionAbility ? sectionAbility.abilityText : ''}
            onKeyDown={onSubmitFromKeyboard}
            disabled={readonly}
            maxLength="50"
          />
        </div>
        <div className="bottom-border"/>
        {abilityPropertiesComponent}
      </div>
    )
  }
}

export default AbilitySection
