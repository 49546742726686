import React, { Component } from 'react'
import { connect } from 'react-redux'

import WorksheetPage from './WorksheetPage'
import AttractivenessSection from '../../common/worksheet-2/AttractivenessSection'
import TooltipItem from '../../common/worksheet-1/TooltipItem'
import { PotentialData, ChallengeData } from '../../../utils/constants'
import {
  getProjectCustomerSegments,
  getUpdateStatus,
  getAllSetOpportunities,
  getCurrentAttractivenessOpportunityId,
  getProjectData
} from '../../../store/project/projectSelectors'
import { setAndSaveProjectData, createAndUpdateSegmentData, setCurrentOpportunity } from '../../../store/project/projectActions'
import ArrowComponent from '../../common/worksheet-2/ArrowComponent'
import './WorksheetPage2.scss'
import Projects from '../../common/Dropdown'
import {getMemberRole} from "../../../utils/helpers";
import {getUserData} from "../../../store/auth/authSelectors";
import ArrowHead from "../../../assets/svgs/ArrowHead";

class WorksheetPage2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customerSegments: props.customerSegments,
      opportunities: props.opportunities,
      currentOpportuntity: props.pdfOpportunity ? props.pdfOpportunity : props.opportunities.find(opp => opp && opp.uid === props.currentAttractivenessOpportunityId),
      previousSectionId: null,
      previousName: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerSegments !== this.props.customerSegments) {
      this.setState({
        customerSegments: this.props.customerSegments,
        opportunities: this.props.opportunities,
        currentOpportuntity: this.props.pdfOpportunity ? this.props.pdfOpportunity : this.props.opportunities.find(opp => opp && opp.uid === this.props.currentAttractivenessOpportunityId),
      })
    }
  }

  handleDropdownClick = (opportunity) => {
    const { onSetCurrentOpportunity } = this.props

    this.setState({
      currentOpportuntity: opportunity,
    })

    onSetCurrentOpportunity(opportunity.uid)
  }

  handleArrowSectionClick = (name, id) => {
    const { onCreateAndUpdateSegmentData } = this.props
    const { currentOpportuntity, customerSegments } = this.state

    if (currentOpportuntity) {
      let previousSectionId = currentOpportuntity[name]
      let segmentId = customerSegments.findIndex(segment => segment && segment.uid === currentOpportuntity.uid)
      let opportunityId = -1

      if (previousSectionId > id && id !== 0) {
        currentOpportuntity[name] = id + 1
      } else {
        currentOpportuntity[name] = id
      }
      // currentOpportuntity.overallChallenge = computeOverallChallenge(currentOpportuntity)
      // currentOpportuntity.overallPotential = computeOverallPotential(currentOpportuntity)

      this.setState({
        currentOpportuntity,
        previousSectionId,
        previousName: name,
      })


      if (segmentId !== -1) {
        customerSegments[segmentId] = currentOpportuntity
      } else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === currentOpportuntity.uid))
            segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        customerSegments[segmentId].opportunities[opportunityId] = currentOpportuntity
      }

      onCreateAndUpdateSegmentData(customerSegments)
    }
  }

  render() {
    const { opportunities, currentOpportuntity, previousSectionId, previousName } = this.state
    const { isWithoutNavigation } = this.props

    const readonly = getMemberRole(this.props.currentProject, this.props?.user?.user?.email) === 'canView';

    let challengeArrowComponent = []
    let potentialArrowComponent = []

    for (var i = 1; i <= 8; i++) {
      challengeArrowComponent.push(
        <ArrowComponent
          currentSectionId={currentOpportuntity && currentOpportuntity.overallChallenge}
          name="overallChallenge"
          id={i}
          onClick={this.handleArrowSectionClick}
          previousSectionId={previousName === 'overallChallenge' && previousSectionId}
          key={i}
          readonly={readonly}
        />
      )
      potentialArrowComponent.push(
        <ArrowComponent
          currentSectionId={currentOpportuntity && currentOpportuntity.overallPotential}
          name="overallPotential"
          id={i}
          onClick={this.handleArrowSectionClick}
          previousSectionId={previousName === 'overallPotential' && previousSectionId}
          key={i}
          readonly={readonly}
        />
      )
    }

    return (
      <WorksheetPage isWithoutNavigation={isWithoutNavigation} link="/worksheet-2">
        <div className="worksheet-page2">
          <div className="select-section">
            <img src="/assets/icons/box-icon.png" alt="opportunity" />
            <span>
              Market Opportunity:
            </span>
            <Projects
              title="Select an opportunity"
              items={opportunities}
              color="primary"
              onDropdownClick={this.handleDropdownClick}
              currentItem={currentOpportuntity}
              className='h-padding-left h-min-width'
            />
          </div>
          <div
            className="evaluate-section"
            data-transparent={currentOpportuntity === undefined}
          >
            <div className="row">
              <div className="col-sm-6 potential-section section-border">
                <div className="section-title">
                  Potential
                </div>
                <TooltipItem
                  typeId="attract-1"
                  tooltipClassName="attractiveness-tooltip"
                >
                  There are three key factors that shape the Potential of a market opportunity:<br />
                  <b>Compelling reason to buy:</b> Will someone really want your offer and be willing to pay for it?<br />
                  <b>Market volume:</b> how big is this market, now and in the near future?<br />
                  <b>Economic viability:</b> is it worthwhile from a business perspective  to pursue this market?<br />
                  Evaluate each factor separately and then combine your scores into an overall Potential rating.<br />
                  <a href="assets/examples/flyability_example_Worksheet_2.pdf" className="tooltip-link" target="_blank">See example</a>
                </TooltipItem>
                {
                  PotentialData.map((data, id) => (
                    <AttractivenessSection
                      heading={data.heading}
                      infoText={data.infoText}
                      iconSrc={data.iconSrc}
                      name={data.name}
                      onClick={this.handleArrowSectionClick}
                      currentSectionId={currentOpportuntity}
                      previousSectionId={previousName === data.name && previousSectionId}
                      key={id}
                      readonly={readonly}
                      isWithoutNavigation={isWithoutNavigation}
                    />
                  ))
                }
              </div>
              <div className="col-sm-6 challenge-section section-border-right">
                <div className="section-title">
                  Challenge
                </div>
                <TooltipItem
                  typeId="attract-2"
                  tooltipClassName="attractiveness-tooltip"
                >
                  There are three key factors that shape the Challenge of a market opportunity:<br />
                  <b>Implementation obstacles:</b> How difficult will it be for you to create and deliver your offer?<br />
                  <b>Time to revenue:</b> How long will it take until you can generate cash flow through sales?<br />
                  <b>External risks:</b> what obstacles in the business environment can stand in your way?<br />
                  Evaluate each factor separately and then combine your scores into an overall Challenge rating.<br />
                  <a href="assets/examples/flyability_example_Worksheet_2.pdf" className="tooltip-link" target="_blank">See example</a>
                </TooltipItem>
                {
                  ChallengeData.map((data, id) => (
                    <AttractivenessSection
                      key={id}
                      heading={data.heading}
                      infoText={data.infoText}
                      iconSrc={data.iconSrc}
                      name={data.name}
                      onClick={this.handleArrowSectionClick}
                      currentSectionId={currentOpportuntity}
                      previousSectionId={previousName === data.name && previousSectionId}
                      readonly={readonly}
                      isWithoutNavigation={isWithoutNavigation}
                    />
                  ))
                }
              </div>
            </div>
            <div className="overall-section">
              <div className="row h-padding-bottom">
                <div className="col-sm-6">
                  <div className="overall">
                    <div className="section-title">
                      Overall potential
                    </div>
                    <div className="arrow-component">
                      {potentialArrowComponent}
                      <ArrowHead stroke={2} className="arrow-icon" />
                      <div className="levels-text">
                        <div className="align-mid">
                          Low
                        </div>
                        <div className="align-mid">
                          Mid
                        </div>
                        <div className="align-mid">
                          High
                        </div>
                        <div className="align-mid">
                          Super High
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="overall-image">
                    <img src="/assets/icons/overall-potential.png" alt="overall-potential" />
                  </span>
                </div>
                <div className="col-sm-6">
                  <div className="overall">
                    <div className="section-title">
                      Overall challenge
                    </div>
                    <div className="arrow-component">
                      {challengeArrowComponent}
                      <ArrowHead stroke={2} className="arrow-icon" />
                      <div className="levels-text">
                        <div className="align-mid">
                          Low
                        </div>
                        <div className="align-mid">
                          Mid
                        </div>
                        <div className="align-mid">
                          High
                        </div>
                        <div className="align-mid">
                          Super High
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="overall-image">
                    <img src="/assets/icons/overall-challenge.png" alt="overall-challenge" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WorksheetPage>
    )
  }
}

const mapState = (state) => ({
  customerSegments: getProjectCustomerSegments(state),
  updateStatus: getUpdateStatus(state),
  opportunities: getAllSetOpportunities(state),
  currentAttractivenessOpportunityId: getCurrentAttractivenessOpportunityId(state),
  currentProject: getProjectData(state),
  user: getUserData(state),
})

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
  onCreateAndUpdateSegmentData: (segments) => dispatch(createAndUpdateSegmentData(segments)),
  onSetCurrentOpportunity: (id) => dispatch(setCurrentOpportunity(id, 'attractiveness')),
})

export default connect(mapState, mapDispatch)(WorksheetPage2)
