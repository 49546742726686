import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'


import BasePage from '../BasePage';
import { NavigationSubSectionData } from '../../../utils/constants';
import { toggleNotes } from '../../../store/project/projectActions'

import './WorksheetPage.scss';

class WorksheetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTooltipOpen: false,
    };
  }

  handleOpenNotes = () => {
    const { onToggleNotes } = this.props
    onToggleNotes(false)
  }

  render() {
    const pathname = this.props.location.pathname
    const { isWithoutNavigation } = this.props
    let subSection;

    NavigationSubSectionData.map(subSectionData => {
      if (this.props.link === subSectionData.link) {
        subSection = subSectionData;
      }
    });

    return (
      <BasePage isWithoutNavigation={isWithoutNavigation}>
        <div className='worksheet-page'>
          <div className='page-title'>
            {subSection.infoText && (
              <div className='info-text'>{subSection.infoText}</div>
            )}
          </div>
          {this.props.children}
          <div className='bottom-line'>
            <Link to={"/"}>
              <img src={subSection.bottomImageSrc} alt={subSection.heading} />
            </Link>
            <div className='bottom-text'>{subSection.bottomText}</div>
          </div>
        </div>
      </BasePage>
    );
  }
}

const mapDispatch = (dispatch) => ({
  onToggleNotes: (visibility) => dispatch(toggleNotes(visibility)),
})

export default withRouter(connect(null, mapDispatch)(WorksheetPage));
