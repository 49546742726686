import React from "react";

const CommentReply = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    fill="current"
    viewBox="0 0 16 14"
  >
    <path
      fill="current"
      d="M15 13.5a.5.5 0 01-.447-.276C13.627 11.372 12.75 9.62 8 9.506V13a.5.5 0 01-.84.367l-6.5-6a.498.498 0 010-.734l6.5-6A.501.501 0 018 1v3.506c6.925.172 7.5 3.95 7.5 8.494a.5.5 0 01-.5.5zM1.737 7L7 11.858V9a.5.5 0 01.5-.5c4.146 0 5.911 1.157 6.947 2.548C14.213 7.636 13.024 5.5 7.5 5.5A.5.5 0 017 5V2.142L1.737 7z"
    />
  </svg>
);

export default CommentReply;