import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getComments } from '../../store/project/projectSelectors'
import { setAndSaveProjectData, setUpdateStatus } from '../../store/project/projectActions'
import { createUniqueId } from '../../utils/helpers'
import { getUserData } from '../../store/auth/authSelectors'
import MsgCircle from '../../assets/svgs/MessagePage'
import Cross from '../../assets/svgs/Cross'
import Comment from './Comment'
import './CommentsContainer.scss'

const CommentsContainer = ({ msgClose, setMsgClose, user, projectNotes, comments, onSetAndSaveProjectData  }) => {
  const [allComments, setComments] = useState(comments)
  const [comment, setComment] = useState('');

  const userName = user.user.email;

  const handleCommentChange = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  }

  const handleSubmitButton = (e) => {
    e.preventDefault();
    const updatedComments = [...allComments]

    if (comment !== '') {
      updatedComments.push({
        commentText: comment,
        replies: [],
        user: {
          userName,
        },
        uid: createUniqueId()
      })

      setComments(updatedComments)
      setComment('');
      onSetAndSaveProjectData('comments', updatedComments)
    }
  }

  const handleCommentUpdate = (comment, id) => {
    const updatedComments = [...allComments]
    const commentId = updatedComments.findIndex(comment => comment && comment.id === id)

    updatedComments[commentId] = comment

    onSetAndSaveProjectData('comments', updatedComments)
  }

  const handleCommentDelete = (id, commentText) => {
    const updatedComments = id
      ? allComments.filter((comment) => comment.id !== id)
      : allComments
        .filter((comment) => comment.commentText !== commentText)
        .map((comment) => ({
          ...comment,
          replies: comment.replies.filter((v) => v.commentText !== commentText)
        }));

    setComments(updatedComments);
    onSetAndSaveProjectData("comments", updatedComments);
  };


  return (
    <div className="comments-container">
      <div className={`${!msgClose ? '' : 'msg-only'}`}></div>
      <div className={`msg-container ${!msgClose ? 'hidden-msg' : ''}`}>
        <div className="cross">
          <div className="close-notes__btn">
            <span onClick={() => setMsgClose(!msgClose)} >
              <Cross />
            </span>
          </div>
        </div>
        <div className="notes-flex-base-container">
          <div className="notes-flex-item note">
            <MsgCircle />
          </div>
          <div className="notes-flex-item textmsg">
            All comments ({allComments.length})
          </div>
        </div>
        <div className="rect-container">
          <div className="rect" />
        </div>
        <div className="notes-content-comment">
          {allComments.map((comment) => (
            <>
              <Comment
                key={comment.id}
                userName={userName}
                comment={comment}
                replies={comment.replies}
                onUpdateComment={handleCommentUpdate}
                onDeleteComment={handleCommentDelete}
                lineIndent={-4}
              />
              <div className="rect-container in-list">
                <div className="rect" />
              </div>
            </>
          )
        )}
        </div>
        <div className="notes-content-textarea">
          <div className="notes-content">
            <textarea
              onChange={(e) => handleCommentChange(e)}
              name="comments"
              value={comment}
              id="comments"
              cols="40"
              rows="10"
              placeholder="Your comment goes here">
            </textarea>
            <button onClick={(e) => handleSubmitButton(e)} className="styled-button">Add a comment</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  user: getUserData(state),
  comments: getComments(state),
})

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
  onSetUpdateStatus: (status) => dispatch(setUpdateStatus(status)),
})

export default connect(mapState, mapDispatch)(CommentsContainer)
