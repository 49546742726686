import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'

import {
  getProjectCustomerSegments,
  getUpdateStatus,
  getAgileFocusDropdownOpportunities,
  getCurrentAgileFocusOpportunityIds,
  getCurrentAgileFocusPrimaryOpportunityId,
  getNonTakenAgileFocusDropdownOpportunities,
  getCurrentOpportunityId,
} from '../../../store/project/projectSelectors'
import { createAndUpdateSegmentData } from '../../../store/project/projectActions'
import '../../pages/worksheet-page/WorksheetPage3.scss';

import SelectionBoxes from './SelectionBoxes';
import CheckboxWrapper from './CheckboxWrapper';
import Projects from '../Dropdown';
import WarningModal from '../warning/WarningModal'

const boxText = [
  { type: 'text', text: 'Pursue now', name: 'pursueNow', id: '1' },
  { type: 'text', text: 'Keep open', name: 'keepOpen', id: '2', },
  { type: 'text', text: 'Place\u00A0in storage', name: 'placeInStorage', id: '3' }
];

const boxImg = [
  { type: 'img', imgClass: 'circle-image-1' },
  { type: 'img', imgClass: 'circle-image-2' },
  { type: 'img', imgClass: 'circle-image-3' }
];

class WorksheetSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customerSegments: props.customerSegments,
      opportunities: props.opportunities,
      currentOpportuntity: props.opportunities.find(opp => opp && opp.uid === getCurrentOpportunityId(props.currentAgileFocusOpportunityIds, props.sectionId)),
      isModalOpen: false,
      isPrimaryOpportunityWarning: false,
      isPrimaryOpportunityWarningHandled: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerSegments !== this.props.customerSegments) {
      this.setState({
        customerSegments: this.props.customerSegments,
        opportunities: this.props.opportunities,
        currentOpportuntity: this.props.opportunities.find(opp => opp && opp.uid === getCurrentOpportunityId(this.props.currentAgileFocusOpportunityIds, this.props.sectionId)),
        isModalOpen: false,
        isPrimaryOpportunityWarning: false,
        isPrimaryOpportunityWarningHandled: false,
      })
    }
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      isPrimaryOpportunityWarning: false,
    })
  }

  handleWarning = () => {
    this.setState({
      isModalOpen: true,
      isPrimaryOpportunityWarning: true,
      isPrimaryOpportunityWarningHandled: true,
    })
  }

  handleDropdownClick = (opportunity) => {
    const { onSetCurrentOpportunity, sectionId } = this.props;

    this.setState({
      currentOpportuntity: opportunity,
    })

    onSetCurrentOpportunity(opportunity, sectionId)
  }

  handleUpdateOpportunity = (name, value) => {
    const { onCreateAndUpdateSegmentData } = this.props
    const { currentOpportuntity, customerSegments } = this.state

    if (currentOpportuntity) {
      let segmentId = customerSegments.findIndex(segment => segment && segment.uid === currentOpportuntity.uid)
      let opportunityId = -1

      currentOpportuntity[name] = value

      this.setState({
        currentOpportuntity
      })


      if (segmentId !== -1) {
        customerSegments[segmentId][name] = value
      } else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === currentOpportuntity.uid))
            segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        customerSegments[segmentId].opportunities[opportunityId][name] = value
      }

      onCreateAndUpdateSegmentData(customerSegments)
    } else {
      this.setState({
        isModalOpen: true,
      })
    }
  }

  render() {
    const { sectionId, isLastSection, onAddSection, onDeleteSection, currentPrimaryOpportunityId, nonTakenOpportunities, readonly } = this.props
    const { opportunities, currentOpportuntity, isModalOpen, isPrimaryOpportunityWarning } = this.state
    let agileFocusOptionIndex

    if (currentOpportuntity && currentOpportuntity.agileFocusOption) {
      agileFocusOptionIndex = boxText[boxText.findIndex(element => element.name === currentOpportuntity.agileFocusOption)].id
    }

    return(
      <Fragment>
        {isModalOpen &&
          <WarningModal onOkClick={this.toggleModal} onCancelClick={this.toggleModal} >
            {!isPrimaryOpportunityWarning ?
              'You need to select an opportunity in the dropdown first.'
              :
              <>
                <div>Start by selecting a primary opportunity,</div>
                <div className="second-row">then design a portfolio of backup and growth options</div>
              </>
            }
          </WarningModal>
        }
        <div className="row section-row bb-md bt-lg">
          <div className='selection-container col-sm-12 white-bg br-md pt-3'>
            <div className='arrow'></div>
            {sectionId > 3 &&
              <img className="delete-icon" src="/assets/icons/add-icon.png" alt="delete" onClick={() => onDeleteSection(sectionId)}/>
            }
            <span>
              <img style={{ verticalAlign: 'top' }} className="box-icon" src='/assets/icons/box-icon.png' alt='opportunity' />
              <Projects
                title='Select opportunity'
                items={nonTakenOpportunities}
                color='black'
                onDropdownClick={this.handleDropdownClick}
                currentItem={currentOpportuntity}
                onHandleWarning={this.handleWarning}
                isCurrentPrimarySelected={currentPrimaryOpportunityId ? true : false}
                isResetOption
              />
            </span>
          </div>
        </div>
        <div className='row section-row bb-sm'>
          <div className='col-sm-12 white-bg br-md v-align h-align'>
            <SelectionBoxes
              name='productRelatedness'
              data={boxImg}
              onClick={this.handleUpdateOpportunity}
              currentSelectionValue={currentOpportuntity && currentOpportuntity.productRelatedness}
              readonly={readonly}
            />
          </div>
        </div>
        <div className='row section-row bb-md'>
          <div className='col-sm-12 white-bg br-md v-align h-align'>
            <SelectionBoxes
              name='marketRelatedness'
              data={boxImg}
              onClick={this.handleUpdateOpportunity}
              currentSelectionValue={currentOpportuntity && currentOpportuntity.marketRelatedness}
              readonly={readonly}
            />
          </div>
        </div>
        <div className='row section-row bb-sm'>
          <div className='col-sm-12 white-bg br-md v-align'>
            <CheckboxWrapper
              name='backupOption'
              label='Backup'
              onClick={this.handleUpdateOpportunity}
              currentSelectionValue={currentOpportuntity && currentOpportuntity.backupOption}
              readonly={readonly}
            />
          </div>
        </div>
        <div className='row section-row'>
          <div className='col-sm-12 white-bg br-md v-align'>
            <CheckboxWrapper
              name="growthOption"
              label='Growth'
              onClick={this.handleUpdateOpportunity}
              currentSelectionValue={currentOpportuntity && currentOpportuntity.growthOption}
              readonly={readonly}
            />
          </div>
        </div>
        <div className='row section-row bt-lg'>
          <div className='col-sm-12 white-bg br-md v-align h-align'>
            <div className='arrow'></div>
            <span>
              <SelectionBoxes
                name='agileFocusOption'
                data={boxText}
                onClick={this.handleUpdateOpportunity}
                currentSelectionValue={currentOpportuntity && agileFocusOptionIndex}
                readonly={readonly}
              />
            </span>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapState = (state) => ({
  customerSegments: getProjectCustomerSegments(state),
  updateStatus: getUpdateStatus(state),
  opportunities: getAgileFocusDropdownOpportunities(state),
  currentAgileFocusOpportunityIds: getCurrentAgileFocusOpportunityIds(state),
  currentPrimaryOpportunityId: getCurrentAgileFocusPrimaryOpportunityId(state),
  nonTakenOpportunities: getNonTakenAgileFocusDropdownOpportunities(state),
})

const mapDispatch = (dispatch) => ({
  onCreateAndUpdateSegmentData: (segments) => dispatch(createAndUpdateSegmentData(segments)),
})

export default connect(mapState, mapDispatch)(WorksheetSection)
