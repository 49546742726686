import { createUniqueId } from "./helpers";

export const BACKEND_URL = process.env.REACT_APP_URL_BACKEND;
export const GRAPHQL_BACKEND_URL = process.env.REACT_APP_GRAPHQL_URL;
export const FRONTEND_URL = process.env.REACT_APP_URL_FRONTEND;

export const NavigationSubSectionData = Object.freeze([
  {
    heading: "Worksheet 1",
    link: "/worksheet-1",
    headingImageSrc: "/assets/images/worksheet-1-heading-image.png",
    mainHeading: "Generate your market opportunity set",
    bottomText:
      "Click on  the markets that you would like to further investigate to send them into your Market Opportunity Set.",
    bottomImageSrc: "/assets/images/opportunity-set.png",
  },
  {
    heading: "Worksheet 2",
    link: "/worksheet-2",
    headingImageSrc: "/assets/images/worksheet-2-heading-image.png",
    mainHeading: "Evaluate market opportunity attractiveness",
    infoText:
      "Use this worksheet for every market opportunity you would like to evaluate. Navigate between opportunities through the drop down menu.",
    bottomText:
      "Once you have rated the overall Potential and Challenge of a market opportunity, it will automatically be positioned on the Attractiveness Map",
    bottomImageSrc: "/assets/images/attractiveness-map.png",
  },
  {
    heading: "Worksheet 3",
    link: "/worksheet-3",
    headingImageSrc: "/assets/images/worksheet-3-heading-image.png",
    mainHeading: "Design your agile focus strategy",
    infoText:
      "Build a smart portfolio around your Primary Market Opportunity to mitigate your risk and increase your value.",
    bottomText:
      "Once you complete this worksheet, your strategy will be automatically depicted on the Agile Focus Dartboard",
    bottomImageSrc: "/assets/images/agile-focus.png",
  },
]);

export const NavigationData = Object.freeze([
  {
    heading: "MARKET OPPORTUNITY NAVIGATOR",
    link: "/",
    imageSrc: "/assets/images/overview.png",
  },
  {
    title: "Worksheet 1",
    heading: "MARKET OPPORTUNITY SET",
    link: "/market-opportunity-set",
    imageSrc: "/assets/images/opportunity-set.png",
    bigImageSrc: "/assets/images/opportunity-set-big.png",
    iconSrc: "/assets/icons/sheet-icon-1.png",
    subSection: NavigationSubSectionData[0],
    textPart1: "Use",
    textPart2:
      "to identify potential market opportunities. Your selected options will be placed here automatically",
  },
  {
    title: "Worksheet 2",
    heading: "ATTRACTIVENESS MAP",
    link: "/attractiveness-map",
    imageSrc: "/assets/images/attractiveness-map.png",
    bigImageSrc: "/assets/images/attractiveness-map-big.png",
    arrowsSrc: "/assets/images/attractiveness-map-arrows.png",
    iconSrc: "/assets/icons/sheet-icon-2.png",
    subSection: NavigationSubSectionData[1],
    textPart1: "Use",
    textPart2:
      "to evaluate the attractiveness of each opportunity. It will then be automatically positioned on the map",
  },
  {
    title: "Worksheet 3",
    heading: "AGILE FOCUS DARTBOARD",
    link: "/agile-focus-dartboard",
    imageSrc: "/assets/images/agile-focus.png",
    bigImageSrc: "/assets/images/agile-focus-big.png",
    iconSrc: "/assets/icons/sheet-icon-3.png",
    subSection: NavigationSubSectionData[2],
    textPart1: "Use",
    textPart2:
      "to design your Agile Focus strategy.  It will then be automatically depicted on the dartboard",
  },
]);

export const PotentialData = Object.freeze([
  {
    name: "reasonToBuy",
    heading: "Compelling reason to buy",
    iconSrc: "/assets/icons/potential-1.png",
    infoText: [
      {
        text: "Unmet need",
      },
      {
        text: "Effective solution",
      },
      {
        text: "Better than current solutions",
      },
    ],
  },
  {
    name: "marketVolume",
    heading: "Market volume",
    iconSrc: "/assets/icons/potential-2.png",
    infoText: [
      {
        text: "Current market size",
      },
      {
        text: "Expected growth",
      },
    ],
  },
  {
    name: "economicViability",
    heading: "Economic viability",
    iconSrc: "/assets/icons/potential-3.png",
    infoText: [
      {
        text: "Margins (value vs. cost)",
      },
      {
        text: "Customers’ ability to pay",
      },
      {
        text: "Customer stickiness",
      },
    ],
  },
]);

export const ChallengeData = Object.freeze([
  {
    name: "implementationObstacles",
    heading: "Implementation obstacles",
    iconSrc: "/assets/icons/challenge-1.png",
    infoText: [
      {
        text: "Product development difficulties",
      },
      {
        text: "Sales and distribution difficulties",
      },
      {
        text: "Funding challenges",
      },
    ],
  },
  {
    name: "timeToRevenue",
    heading: "Time to revenue",
    iconSrc: "/assets/icons/challenge-2.png",
    infoText: [
      {
        text: "Development time",
      },
      {
        text: "Time between product and market readiness",
      },
      {
        text: "Length of sale cycle",
      },
    ],
  },
  {
    name: "externalRisks",
    heading: "External risks",
    iconSrc: "/assets/icons/challenge-3.png",
    infoText: [
      {
        text: "Competitive threat",
      },
      {
        text: "3rd party dependencies",
      },
      {
        text: "Barriers to adoption",
      },
    ],
  },
]);

export const defaultProject = Object.freeze({
  title: "",
  customerSegment: [],
  user: null,
  userId: null,
  sectionCount: 3,
  selectOpportunitySectionCount: 3,
  abilitiesSectionCount: 4,
  abilities: [],
  notes: [],
  applications: [],
  currentAgileFocusPrimaryOpportunityId: null,
  currentAgileFocusOpportunityIds: [],
  comments: [],
  members: [],
  chats: [],
  usedGptTokens: 0,
});

export const chatWelcomeMsg = Object.freeze({
  uid: createUniqueId(),
  messageType: "incoming",
  messageText: process.env.REACT_APP_GPT_WELCOME_MSG,
  user: {
    username: "bot",
  },
  isValidMessage: true,
});

export const defaultChat = Object.freeze({
  chats: [],
  projectId: 0,
  usedGptTokens: 0,
});

export const defaultChatSession = Object.freeze({
  projectId: 0,
  inputMsg: "",
  isTyping: false,
  isChatActive: false,
  isChatOpenerActive: false,
  sessionChats: [chatWelcomeMsg],
});
