import React from "react";

function ChatTooltip({ children, className = "" }) {
  return (
    <div
      aria-hidden="true"
      className={`chat-tooltip ${className}`}
      dangerouslySetInnerHTML={{ __html: children }}
    ></div>
  );
}

export default React.memo(ChatTooltip);
