// Import CSS
import "./chatbot.scss";

// Helpers
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { apiChatCompletion } from "../../api/api";
import { chatWelcomeMsg } from "../../utils/constants";
import { getMemberRole, getProjectChatIndex } from "../../utils/helpers";
import {
  processGptInput,
  validateInputMsg,
  tokenUsagePercentage,
  hasTokenLimitExceeded,
  calculateTotalTokenUsage,
  generateClientChatSchema,
  getCurrentProjectAbilitiesText,
  generateValidApiMessages,
} from "./chat-utils";

// STORE
import { getUserData } from "../../store/auth/authSelectors";

import {
  getAllProjectsChatSessionState,
  getAllProjectsChatState,
  getCurrentChat,
  getCurrentChatSession,
  getProjectData,
} from "../../store/project/projectSelectors";

import {
  CHAT_INPUT_MSG,
  IS_CHAT_ACTIVE,
  IS_CHAT_TYPING,
  CHAT_OLD_MESSAGES,
  CHAT_USED_GPT_TOKENS,
  CHAT_SESSION_MESSAGES,
  setInitialChat,
  updateChatField,
  saveChatByProjectId,
  setInitialChatSession,
  updateChatSessionField,
} from "../../store/project/chatActions";

// Components
import ChatHeader from "./components/ChatHeader";
import ChatDownload from "./components/ChatDownload";
import ChatTokenLimit from "./components/ChatTokenLimit";
import ChatMessageList from "./components/ChatMessageList";
import ToggleChatButton from "./components/ToggleChatButton";
import ChatMessageInput from "./components/ChatMessageInput";

const ChatBot = ({
  loggedInUser,
  currentProject,
  allProjectsChat,
  currentProjectChat,
  allProjectsChatSession,
  currentProjectChatSession,
  onSetInitialChat,
  onUpdateChatField,
  onSaveChatByProjectId,
  onSetInitialChatSession,
  onUpdateChatSessionField,
}) => {
  const validatedMsg = "success";
  const currentProjectId = currentProject.id;
  const memberRole = getMemberRole(currentProject, loggedInUser.user.email);

  const [tokenUsage, setTokenUsage] = useState(0);
  const [inputMsg, setInputMsg] = useState(currentProjectChatSession.inputMsg);
  const [isTyping, setIsTyping] = useState(currentProjectChatSession.isTyping);
  const [messages, setMessages] = useState([...currentProjectChat.chats]);
  const [isChatActive, setIsChatActive] = useState(
    currentProjectChatSession.isChatActive
  );
  const [usedGptTokens, setUsedGptTokens] = useState(
    currentProjectChat.usedGptTokens
  );
  const [sessionMessages, setSessionMessages] = useState(
    currentProjectChatSession.sessionChats
  );
  const [isChatOpenerActive, setIsChatOpenerActive] = useState(
    currentProjectChatSession.isChatOpenerActive
  );

  useEffect(() => {
    const currentProjectId = currentProject.id;

    const currentChatSessionIndex = getProjectChatIndex(
      currentProjectId,
      allProjectsChatSession
    );

    const currentChatIndex = getProjectChatIndex(
      currentProjectId,
      allProjectsChat
    );

    // GENERATE SESSION IF THE CHAT_SESSION IS NOT PRESENT IN THE STORE AND THE PROJECT_ID IS NOT FALSY
    if (currentChatSessionIndex === -1 && !!currentProjectId) {
      onSetInitialChatSession(currentProject);
    }

    // GENERATE CHAT IF THE PROJECT_CHAT IS NOT PRESENT IN THE STORE AND THE PROJECT_ID IS NOT FALSY
    if (currentChatIndex === -1 && !!currentProjectId) {
      onSetInitialChat(currentProject);
    }
  }, [currentProject.id]);

  /*REDUX DISPATCH METHODS*/
  const handleIsTyping = (payload) => {
    setIsTyping(payload);
    onUpdateChatSessionField({
      projectId: currentProjectId,
      field: IS_CHAT_TYPING,
      value: payload,
    });
  };

  const handleIsChatActive = () => {
    const payload = !isChatActive;

    setIsChatActive(payload);
    onUpdateChatSessionField({
      projectId: currentProjectId,
      field: IS_CHAT_ACTIVE,
      value: payload,
    });
  };

  const handleUsedGptTokens = (usage) => {
    const totalUsage = calculateTotalTokenUsage({
      usedTokens: usedGptTokens,
      currentUsage: usage,
    });

    setUsedGptTokens(totalUsage);
    onUpdateChatField({
      projectId: currentProjectId,
      field: CHAT_USED_GPT_TOKENS,
      value: totalUsage,
    });
  };

  const handleInputMsg = (inputMsg) => {
    setInputMsg(inputMsg);
    onUpdateChatSessionField({
      projectId: currentProjectId,
      field: CHAT_INPUT_MSG,
      value: inputMsg,
    });
  };

  const handleSessionMessages = (sessionMessages) => {
    setSessionMessages(sessionMessages);
    onUpdateChatSessionField({
      projectId: currentProjectId,
      field: CHAT_SESSION_MESSAGES,
      value: sessionMessages,
    });
  };

  const handleMessages = (messages) => {
    setMessages(messages);
    onUpdateChatField({
      projectId: currentProjectId,
      field: CHAT_OLD_MESSAGES,
      value: messages,
    });
  };
  /*REDUX DISPATCH METHOD ENDS*/

  useEffect(() => {
    setIsTyping(currentProjectChatSession.isTyping);
    setUsedGptTokens(currentProjectChat.usedGptTokens);

    if (currentProjectChatSession.isChatOpenerActive !== isChatOpenerActive) {
      setIsChatOpenerActive(currentProjectChatSession.isChatOpenerActive);
    }

    // APPEND CHAT_WELCOME_MESSAGE IN EVERY NEW CHAT_SESSION IN THE UI
    setMessages(() => {
      const messages =
        currentProjectChatSession.sessionChats.length === 1 &&
        currentProjectChat.chats.length > 1
          ? [...currentProjectChat.chats, chatWelcomeMsg]
          : [...currentProjectChat.chats];

      return messages;
    });

    setSessionMessages(currentProjectChatSession.sessionChats);
  }, [currentProjectChatSession]);

  useEffect(() => {
    handleTokenUsagePercentage(usedGptTokens);
  }, [usedGptTokens]);

  useEffect(() => {
    if (!isChatOpenerActive && isChatActive) {
      handleIsChatActive();
    }
  }, [isChatOpenerActive, isChatActive]);

  // METHODS
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  const handleTokenUsagePercentage = (usedTokens) => {
    const usagePercentage = tokenUsagePercentage(usedTokens);
    setTokenUsage(usagePercentage);
  };

  const sendMsgToValidatorGpt = async (chatMessages) => {
    handleIsTyping(true);

    const gptInputMessages = processGptInput(chatMessages);

    try {
      // SEND ONLY THE LAST MESSAGE[Latest user input] TO THE API
      const botApiResponse = await apiChatCompletion({
        messages: [gptInputMessages[gptInputMessages.length - 1]],
      });

      handleIsTyping(false);

      // RETURN IF THE VALIDATOR RETURNS EMPTY STRING
      if (botApiResponse.choices[0].message.content?.length <= 10) {
        return validatedMsg;
      }

      // CONVERT THE API RESONSE TO CLIENT OBJECT
      const botResponseMessage = generateClientChatSchema({
        messageType: "incoming",
        username: "bot",
        message: botApiResponse.choices[0].message.content,
        isValidMessage: false,
      });

      return botResponseMessage;
    } catch (err) {
      console.log(err);
      handleIsTyping(false);
      return false;
    }
  };

  const sendMsgToGpt = async (chatMessages) => {
    handleIsTyping(true);

    const gptInputMessages = processGptInput(chatMessages);
    try {
      const abilitiesText = await getCurrentProjectAbilitiesText({
        projectId: currentProjectId,
      });

      const botApiResponse = await apiChatCompletion({
        model: process.env.REACT_APP_GPT_WTP_MODEL,
        abilitiesText,
        messages: gptInputMessages,
      });

      const updatedTokenUsage = botApiResponse.usage.total_tokens;

      handleUsedGptTokens(updatedTokenUsage);

      onSaveChatByProjectId({
        projectId: currentProjectId,
        field: CHAT_USED_GPT_TOKENS,
        value: calculateTotalTokenUsage({
          usedTokens: usedGptTokens,
          currentUsage: updatedTokenUsage,
        }),
      });

      handleIsTyping(false);

      // CONVERT THE API RESONSE TO CLIENT OBJECT
      const botResponseMessage = generateClientChatSchema({
        messageType: "incoming",
        username: "bot",
        message: botApiResponse.choices[0].message.content,
      });

      return botResponseMessage;
    } catch (err) {
      console.log(err);
      handleIsTyping(false);
      return false;
    }
  };

  const handleSend = async () => {
    // RETURN IF CURRENT USER IS NOT THE PROJECT OWNER
    if (!(memberRole === "owner")) return;

    // RETURN AND SHOW ALERT IF TOKEN LIMIT IS EXCEEDED
    if (hasTokenLimitExceeded({ usedTokens: usedGptTokens })) {
      alert(process.env.REACT_APP_GPT_PROJECT_TOKEN_LIMIT_EXCEEDED_MSG);
      return;
    }

    // RETURN IF USER INPUT IS NOT VALID
    if (!validateInputMsg(inputMsg)) return;

    // RETURN IF ALREADY PROCESSING
    if (isTyping) return;

    // GENERATE CHAT OBJECT
    const newMessage = generateClientChatSchema({
      message: inputMsg,
      messageType: "outgoing",
      username: loggedInUser.user.email,
    });

    let newSessionMessages = [];

    newSessionMessages = [...sessionMessages, newMessage]; // WELCOME_MESSAGE + CURRENT_USER_INPUT_MSG

    handleSessionMessages(newSessionMessages);

    handleInputMsg(""); // RESET INPUT MESSAGE

    let newMessages = [...messages, newMessage]; // oldMessages + current_session_messages

    handleMessages(newMessages); // UPDATE UI MESSAGES

    // SEND MESSAGE TO THE VALIDATOR AND CONTENT GPT
    let apiResponse;
    const validatorResponse = await sendMsgToValidatorGpt(newSessionMessages);

    if (validatorResponse === validatedMsg) {
      // SEND VALID SESSION MESSAGES TO GPT TO MAINTAIN THE CURRENT SESSION CONTEXT
      apiResponse = await sendMsgToGpt(
        generateValidApiMessages({ messages: newSessionMessages })
      );
    } else {
      newMessage.isValidMessage = false; // SET USER_INPUT TO INVALID

      apiResponse = validatorResponse; // SET THE API-RESPONSE AS THE VALIDATOR RESPONSE
    }

    // RETURN IF REQUEST IS UNSUCCESSFULL [outgoing and incoming]
    if (typeof apiResponse === "boolean") return;

    // UPDATE UI MESSAGES
    newMessages = [...newMessages, apiResponse];
    handleMessages(newMessages);

    // UPDATE SESSION MESSAGES
    newSessionMessages = [...newSessionMessages, apiResponse];
    handleSessionMessages(newSessionMessages);

    // SAVE CONVERSATION IN THE DB
    onSaveChatByProjectId({
      projectId: currentProjectId,
      field: CHAT_OLD_MESSAGES,
      value: newMessages,
    });
  };

  return (
    <>
      <div className="chat-main-container">
        {isChatActive && isChatOpenerActive && (
          <>
            <div className="chat-container">
              <ChatTokenLimit tokenUsage={tokenUsage} />
              <div className="chat-container-body">
                <ChatHeader />
                <ChatMessageList
                  isLoading={isTyping}
                  messages={sessionMessages}
                  showTokenExceededMessage={hasTokenLimitExceeded({
                    usedTokens: usedGptTokens,
                  })}
                />
                <ChatMessageInput
                  onKeyPress={onKeyPress}
                  onSend={handleSend}
                  inputMsg={inputMsg}
                  setInputMsg={handleInputMsg}
                  chats={messages}
                  isEnabled={
                    !hasTokenLimitExceeded({
                      usedTokens: usedGptTokens,
                    })
                  }
                  memberRole={memberRole}
                >
                  <ChatDownload chats={sessionMessages} />
                </ChatMessageInput>
              </div>
            </div>
          </>
        )}
        <ToggleChatButton
          isEnabled={isChatOpenerActive}
          onToggle={handleIsChatActive}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentProject: getProjectData(state),
  allProjectsChatSession: getAllProjectsChatSessionState(state),
  allProjectsChat: getAllProjectsChatState(state),
  loggedInUser: getUserData(state),
  currentProjectChatSession: getCurrentChatSession(state),
  currentProjectChat: getCurrentChat(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetInitialChatSession: (projectId) =>
    dispatch(setInitialChatSession(projectId)),
  onSetInitialChat: (projectId) => dispatch(setInitialChat(projectId)),
  onSaveChatByProjectId: ({ projectId, field, value }) =>
    dispatch(saveChatByProjectId({ projectId, field, value })),
  onUpdateChatSessionField: ({ projectId, field, value }) =>
    dispatch(updateChatSessionField({ projectId, field, value })),
  onUpdateChatField: ({ projectId, field, value }) =>
    dispatch(updateChatField({ projectId, field, value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot);
