import React from "react";

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 384 384"
    className="on-mouse-over-fill"
  >
    <g>
      <path d="M0,303.947l0,80l80,0l236.053,-236.054l-80,-80l-236.053,236.054Z"/>
      <path d="M377.707,56.053l-49.814,-49.813c-8.32,-8.32 -21.867,-8.32 -30.187,-0l-39.04,39.04l80,80l39.04,-39.04c8.321,-8.32 8.321,-21.867 0.001,-30.187Z"/>
    </g>
  </svg>
);

export default EditIcon;