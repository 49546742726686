import React from "react";

function TimerCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
      className="timer-circle"
    >
      <circle cx="40" cy="40" r="38" stroke="#CED1D7" strokeWidth="4"></circle>
    </svg>
  );
}

export default TimerCircle;