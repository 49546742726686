// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Faq from "react-faq-component";

import BasePage from '../BasePage'
import { getUserData } from '../../../store/auth/authSelectors'
import './HelpPage.scss'

class HelpPage extends Component {
  componentDidMount() {}

  render() {
    const { isWithoutNavigation } = this.props


    const data = {
      title: "Frequently asked questions",
      rows: [
          {
            title: "How to name and save my work?",
            content: `Name your project on the top left bar and click the Add button. Your new project is now created.  It will be saved automatically every few seconds, and you can come back to it anytime you login.`,
          },
          {
            title: "How to download my work?",
            content: "You can download your project and save it as PDF on your computer. To do that, open the web-app on Chrome and click Download PDF on the upper right menu. Note: currently, this feature does not work on Safari and other browsers.",
          },
          {
            title: "How to collaborate with others?",
            content: `This new version allows you to share your project and collaborate with others. Once you create a project, you can invite unlimited peers to view or edit it. All they need to do is sign-up and your project will automatically appear in their dashboard, available from the dropdown menu. If multiple members are working simultaneously on a project, wait for the ‘saved’ indication (on the upper left menu) before you can see each other’s revisions. You can also use the comment function on the upper left menu to communicate and discuss with your peers.`,
          },
          {
            title: "Can I work on more than one project?",
            content: "You can create several projects under your account. Click the + sign on the upper left bar, name your project and click Add. The drop down menu allows you to navigate between your projects.",
          },
          {
            title: "Can I keep old versions of my project?",
            content: "The only way to keep and track old versions is to download and save them locally. Otherwise, changes are automatically saved.",
          },
          {
            title: "How long can I work on a specific project?",
            content: "You can work on a project as long as you wish. Your last version will be available whenever you login.",
          },
          {
            title: "Privacy issues",
            content: `This application works according to the GDPR regulation. For more information see <a href='https://wheretoplay.co/privacy/' target='_blank' >Privacy</a>`,
         },
        ],
    };

    const styles = {
      width: "200px",
      bgColor: '#ededed',
      titleTextColor: "#574f4f",
      rowTitleColor: "#574f4f",
      rowContentColor: '#574f4f'
    };

    const config = {
      animate: true
    }

    return (
      <BasePage isWithoutNavigation={isWithoutNavigation}>
        <div className="help-page">
          <div id={"tooltip"} />

          <div className="row h-column">
            <div className="heading">
              <span className="title">
              Help Center
              </span>
            </div>

          </div>
          <div className="row h-column">
            <div className="heading">
              <h2 className="section-heading">
                Market Opportunity Navigator
              </h2>
            </div>
            <div className="overview-wrapper">
              <div className="overview-block">
                <div className="overview-heading">
                  <h3>What is the Market Opportunity Navigator?</h3>
                </div>
                <div className="overview-text">
                Choosing which opportunities to focus on is a crucial and ongoing process throughout the life of a business, yet it is often one of the trickiest questions that managers face when striving to start or grow the company. The Market Opportunity Navigator is a simple and solid framework to help innovators identify, evaluate and prioritize market opportunities for their business, so they can set their strategic focus and move forward with confidence. It covers three essential steps, with three dedicated worksheets:
                </div>
                <ul className="overview-list no-bullet">
                  <li>Identifying valuable market opportunities stemming from the venture's core competences</li>
                  <li>Evaluating possible market opportunities in a comprehensive manner, to reveal the most attractive options</li>
                  <li>Designing a smart strategy that allows you to focus on a clear target without losing your agility</li>
                </ul>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>How to manage your learning process?</h3>
                </div>
                <div className="overview-text">
                Applying the Market Opportunity Navigator requires time and attention, yet it is a critical learning and validation process. Here are few key tips to make it as effective as possible:
                </div>
                <ul className="overview-list">
                  <li>Beware of your biases: don't use the Navigator to mirror your own prior assumptions</li>
                  <li>Turn hypothesis into data: talk with potential customers and bring the right evidence to evaluate your opportunities</li>
                  <li>Make it a team decision: bring other perspectives to the table, use the notepad option to share your thoughts and evidence</li>
                  <li>Make it iterative: use the Navigator to reflect on your learning over time</li>
                </ul>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>How to work with this app? </h3>
                </div>
                <div className="overview-text">
                Watch this step-by-step <Link to="/video">video</Link> to learn how this app works.
                </div>

              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Where can I learn more about this framework?</h3>
                </div>
                <div className="overview-text">
                The following resources can help you learn more about the Market Opportunity Navigator:
                </div>
                <ul className="overview-list">
                  <li>Order the hand-holding book: <a href="https://www.amazon.com/Where-Play-discovering-valuable-opportunities/dp/1292178922/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1583245022&sr=8-1" target="_blank">Where to Play, 3 steps for discovering your most valuable opportunities</a></li>
                  <li>Register to a free online course on edX: <a href="https://www.edx.org/course/find-the-right-markets-for-your-innovation-a-tool" target="_blank">Find the Right markets for your Innovation</a></li>
                  <li>Watch a <a href="https://www.youtube.com/watch?v=Tl8tpMpAc_M" target="_blank">1 hour webinar</a></li>
                </ul>
              </div>

            </div>

          </div>
          <div className="row faq-row">
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>

      </BasePage>
    )
  }
}

const mapState = (state) => ({
  user: getUserData(state),
})

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(HelpPage)
