import React from "react";

function ArrowHead({ stroke = 1, className = "" }) {
  return (
    <svg className={className} width="52px" height="90px" viewBox="0 0 52 90" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth={stroke} fill="none" fillRule="evenodd">
        <polyline id="Path" stroke="#979797" strokeWidth={stroke} points="1 19 1 3 50 45 1 87 1 71" />
      </g>
    </svg>
  )
};

export default ArrowHead;