import {
  apiGetProjectData,
  apiUpdateProjectData,
  apiCreateProject,
  apiUpdateProjectUser,
  apiDeleteProject,
  apiGetProjects,
  apiSendMail,
  apiAddMember,
  apiGetProjectsCount,
} from "../../api/api";

import {
  getProjectData,
  getProjectStatus,
  getSaveStatus,
  getProjectsCount,
} from "./projectSelectors";
import { getUserData } from "../auth/authSelectors";
import { logout } from "../auth/authActions";
import {
  deleteProjectChatsAndSession,
  generateProjectChatsAndSession,
} from "./chatActions";

export const PROJECT_DATA_FETCH_START = "PROJECT_DATA_FETCH_START";
export const PROJECT_DATA_FETCH_SUCCESS = "PROJECT_DATA_FETCH_SUCCESS";
export const PROJECT_DATA_FETCH_FAIL = "PROJECT_DATA_FETCH_FAIL";

export const ALL_PROJECTS_FETCH_START = "ALL_PROJECTS_FETCH_START";
export const ALL_PROJECTS_FETCH_SUCCESS = "ALL_PROJECTS_FETCH_SUCCESS";
export const ALL_PROJECTS_FETCH_FAIL = "ALL_PROJECTS_FETCH_FAIL";

export const PROJECT_DATA_UPDATE_START = "PROJECT_DATA_UPDATE_START";
export const PROJECT_DATA_UPDATE_SUCCESS = "PROJECT_DATA_UPDATE_SUCCESS";
export const PROJECT_DATA_UPDATE_FAIL = "PROJECT_DATA_UPDATE_FAIL";

export const PROJECT_CREATE_START = "PROJECT_CREATE_START";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_CREATE_FAIL = "PROJECT_CREATE_FAIL";

export const PROJECT_DELETE_START = "PROJECT_DELETE_START";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAIL = "PROJECT_DELETE_FAIL";

export const PROJECT_DELETE_ALL = "PROJECT_DELETE_ALL";

export const CURRENT_PROJECT_SET = "CURRENT_PROJECT_SET";
export const CURRENT_OPPORTUNITY_SET = "CURRENT_OPPORTUNITY_SET";
export const UPDATE_STATUS_SET = "UPDATE_STATUS_SET";
export const SAVE_STATUS_SET = "SAVE_STATUS_SET";
export const PROJECT_STATUS_SET = "PROJECT_STATUS_SET";
export const PROJECT_COUNT_SET = "PROJECT_COUNT_SET";

export const TOGGLE_NOTES = "TOGGLE_NOTES";
export const PDF_STATUS_SET = "PDF_STATUS_SET";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";

let timer;
let timeout = 5000;

const fetchProjectDataStart = () => ({ type: PROJECT_DATA_FETCH_START });
const fetchProjectDataSuccess = (projects) => ({
  type: PROJECT_DATA_FETCH_SUCCESS,
  projects,
});
const fetchProjectDataFail = () => ({ type: PROJECT_DATA_FETCH_FAIL });

const fetchAllProjectsStart = () => ({ type: ALL_PROJECTS_FETCH_START });
const fetchAllProjectsSuccess = (exportData) => ({
  type: ALL_PROJECTS_FETCH_SUCCESS,
  exportData,
});
const fetchAllProjectsFail = () => ({ type: ALL_PROJECTS_FETCH_FAIL });

const createProjectStart = () => ({ type: PROJECT_CREATE_START });
const createProjectSuccess = (project) => ({
  type: PROJECT_CREATE_SUCCESS,
  project,
});
const createProjectFail = () => ({ type: PROJECT_CREATE_FAIL });

const deleteProjectStart = () => ({ type: PROJECT_DELETE_START });
const deleteProjectSuccess = (id) => ({ type: PROJECT_DELETE_SUCCESS, id });
const deleteProjectFail = () => ({ type: PROJECT_DELETE_FAIL });

export const updateProjectDataStart = (projectData) => ({
  type: PROJECT_DATA_UPDATE_START,
  projectData,
});
export const updateProjectDataSuccess = (projectData) => ({
  type: PROJECT_DATA_UPDATE_SUCCESS,
  projectData,
});
const updateProjectDataFail = () => ({ type: PROJECT_DATA_UPDATE_FAIL });

export const setCurrentProject = (project) => ({
  type: CURRENT_PROJECT_SET,
  project,
});
export const setCurrentOpportunity = (id, name, sectionId) => ({
  type: CURRENT_OPPORTUNITY_SET,
  id,
  name,
  sectionId,
});
export const setUpdateStatus = (status) => ({
  type: UPDATE_STATUS_SET,
  status,
});
export const setSaveStatus = (status) => ({ type: SAVE_STATUS_SET, status });
export const setProjectStatus = (status) => ({
  type: PROJECT_STATUS_SET,
  status,
});
export const setProjectsCount = (count) => ({ type: PROJECT_COUNT_SET, count });

export const toggleNotes = (visibility) => ({ type: TOGGLE_NOTES, visibility });

export const deleteAllProjects = () => ({ type: PROJECT_DELETE_ALL });

export const setPdfStatus = (status) => ({ type: PDF_STATUS_SET, status });

export const addMemberSuccess = (projectData) => ({
  type: ADD_MEMBER_SUCCESS,
  projectData,
});

export const createProject = () => (dispatch, getState) => {
  const user = getUserData(getState()).user;

  const state = getState();
  // If some project is already being created - stop execution
  if (state.project.createStatus === "WORKING") return;

  dispatch(createProjectStart());

  return apiCreateProject(user, getState())
    .then((project) => {
      let projectData = project.data;

      if (project.data.attributes) {
        projectData = { ...project.data.attributes, id: project.data.id };
      }

      apiUpdateProjectUser(projectData, user, getState()).then((project) => {
        let projectData = project.data;

        if (project.data.attributes) {
          projectData = { ...project.data.attributes, id: project.data.id };
        }

        projectData = {
          ...projectData,
          chats: [],
        };

        delete projectData.chatId;

        dispatch(createProjectSuccess(projectData));
      });
    })
    .catch((error) => {
      // rollbar.critical(error)
      dispatch(createProjectFail());
    });
};

export const deleteProject = (id) => (dispatch, getState) => {
  dispatch(deleteProjectStart());

  return apiDeleteProject(id, getState())
    .then((project) => {
      dispatch(deleteProjectSuccess(project.data.id));
      dispatch(deleteProjectChatsAndSession(project.data.id));
    })
    .catch((error) => {
      // rollbar.critical(error)
      dispatch(deleteProjectFail());
    });
};

export const fetchProjectData = () => (dispatch, getState) => {
  const userId = getUserData(getState()).user.id;
  const email = getUserData(getState()).user.email;

  dispatch(fetchProjectDataStart());

  return apiGetProjectData(getState(), email, userId)
    .then((projects) => {
      if (projects.length === 0) {
        dispatch(createProject());
      } else {
        projects.forEach((project) => {
          if (project.members && project.members.length > 0) {
            const memberId = project.members.findIndex(
              (m) => m.email === email
            );

            if (memberId !== -1) {
              project.members[memberId].userId = userId.toString();
            }
          }
        });

        dispatch(fetchProjectDataSuccess(projects));
        dispatch(generateProjectChatsAndSession());
      }
    })
    .catch((error) => {
      // rollsbar.critical(error)
      dispatch(fetchProjectDataFail());

      if (error && error.status === 401) {
        dispatch(logout());
      }
    });
};

export const fetchProjectsCount = () => async (dispatch, getState) => {
  return apiGetProjectsCount(getState())
    .then((count) => {
      dispatch(setProjectsCount(count));
    })
    .catch((error) => {
      // rollbar.critical(error)
    });
};

export const fetchAllProjects = () => async (dispatch, getState) => {
  let exportData = [];

  dispatch(fetchAllProjectsStart());

  await apiGetProjects(getState())
    .then((projects) => {
      exportData = projects;
      dispatch(fetchAllProjectsSuccess(exportData));
    })
    .catch((error) => {
      // rollbar.critical(error)
      dispatch(fetchAllProjectsFail());
    });
};

export const setAndSaveProjectData =
  (name, value) => async (dispatch, getState) => {
    const projectData = getProjectData(getState());
    const projectStatus = getProjectStatus(getState());
    const updatedProjectData = { ...projectData };

    timeout = 0;

    if (name) {
      updatedProjectData[name] = value;
      timeout = 4000;
    }

    dispatch(updateProjectDataStart(updatedProjectData));

    dispatch(setSaveStatus("UNSAVED"));

    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setSaveStatus("SAVED"));
      dispatch(setProjectStatus("SAVING"));
      return apiUpdateProjectData(updatedProjectData, getState())
        .then((response) => {
          const saveStatus = getSaveStatus(getState());

          let projectData = response.data;

          if (projectData.attributes) {
            const updatedProjectChats =
              projectData?.attributes?.chatId?.data?.attributes?.chatItems;

            projectData = {
              ...projectData.attributes,
              id: projectData.id,
              chats:
                updatedProjectChats && updatedProjectChats.length
                  ? updatedProjectChats
                  : [],
            };
          }

          delete projectData.chatId;

          // dispatch(updateProjectDataSuccess(response));
          dispatch(updateProjectDataSuccess(projectData));

          if (saveStatus === "UNSAVED") {
            dispatch(setProjectStatus("UNSAVED"));
          } else {
            dispatch(setProjectStatus("SAVED"));
          }
        })
        .catch((error) => {
          // rollbar.critical(error)
          dispatch(setProjectStatus("UNSAVED"));
          dispatch(setSaveStatus("UNSAVED"));
          dispatch(updateProjectDataFail());

          if (error && error.status === 401) {
            dispatch(logout());
          }
        });
    }, timeout);
  };

const countReducer = (arr) =>
  arr.reduce((p, c) => {
    const x = c?.isOnSet ? p + 1 : p;
    return c?.opportunities ? x + countReducer(c.opportunities) : x;
  }, 0);

export const createAndUpdateSegmentData = (segments) => async (dispatch) => {
  await dispatch(setAndSaveProjectData("customerSegment", segments));
};

export const sendMail =
  (email, feUrl, projectTitle, role) => async (dispatch, getState) => {
    const ownerEmail = getUserData(getState()).user.email;
    let memberRole;

    if (role === "canEdit") {
      memberRole = "edit";
    } else {
      memberRole = "view";
    }

    await apiSendMail(
      email,
      feUrl,
      getState(),
      projectTitle,
      memberRole,
      ownerEmail
    );
  };

export const addMember =
  (projectId, email, role) => async (dispatch, getState) => {
    return apiAddMember(projectId, email, role, getState())
      .then((projectData) => {
        dispatch(addMemberSuccess(projectData));
      })
      .catch((error) => {});
  };
