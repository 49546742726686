import React from "react";
import "./index.scss";
import CommentReply from "../../../assets/svgs/CommentReply";
import CommentArrow from "../../../assets/svgs/CommentArrow";
import { getReplyListData } from "../../../store/comment/commentSelectors";
import {
  deleteComment,
  fetchCommentsFromComment, hideReplySection, postCommentToComment
} from "../../../store/comment/commentActions";
import { connect } from "react-redux";
import { useState } from "react";
import { getUserData } from "../../../store/auth/authSelectors";
import Trash from "../../../assets/svgs/Trash";

const bem = "comment-item";
const CommentItem = ({ 
	id, attributes, content, author, tail, fetchReplies, replies, hideReplies, user, addReply, level, deleteReply
}) => {
	const [show, setShow] = useState(false);
	const [value, setValue] = useState("");

	const resetReply = () => {
		setShow(false);
		setValue("");
	};

	const handleShowMore = () => {
		fetchReplies(id);
	};

	const handleShowLess = () => {
		resetReply();
		hideReplies(id);
	};

	const handleShowReply = () => {
		setShow(true);
		handleShowMore();
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	const handleSend = () => {
		addReply(id, user.user.id, value);
		resetReply();
	};

	const handleDelete = () => {
    deleteReply(id);
  };

	return (
		<div className={bem}>
			{level === 0 && (
				<div 
					className={`${bem}__reply`}
					onClick={handleShowReply}
				>
					<CommentReply />
				</div>
			)}

      <div
        className={`${bem}__delete`}
        onClick={handleDelete}
      >
        <Trash />
      </div>

			<div className={`${bem}__left account-icon`}>
				{attributes.author.data.attributes.username[0]}
			</div>

			<div className={`${bem}__right`}>
				<div className={`${bem}__email`}>
					{attributes.author.data.attributes.email}
				</div>
				<div className={`${bem}__content`}>
					{attributes.content}
				</div>
				{(replies && replies[id] ? replies[id].length || show : attributes.tail?.data.length || show) && (
					<>
						<div
							className={`${bem}__more`}
							onClick={() => !!replies[id]
								? handleShowLess()
								: handleShowMore()}
						>
							<span 
								className={`${bem}__arrow`} 
								data-flipped={!!replies[id]}
							>
								<CommentArrow />
							</span>
							<span>
								{!!replies[id]
									? `Hide ${(replies[id] || attributes.tail.data).length} replies`
									: `Show ${(replies[id] || attributes.tail.data).length} replies`}
							</span>
						</div>

						<div className={`${bem}__replies`}>
							{!!replies[id] && replies[id].map(comment => (
								<CommentItem 
									level={level + 1} 
									key={comment.id} 
									{...comment}
                  deleteReply={deleteReply}
								/>
							))}
							{show && (
								<div className={bem}>
									<div className={`${bem}__left account-icon`}>
										{user.user.username[0]}
									</div>

									<div className={`${bem}__right`}>
										<div className={`${bem}__email`}>
											{user.user.email}
										</div>
										<textarea
											className={`${bem}__textarea`} 
											placeholder="Your reply goes here"
											value={value}
											onChange={handleChange}
										/>
										<button 
											className={`${bem}__send`}
											onClick={handleSend}
										>
											Reply
										</button>
									</div>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const mapState = (state) => ({
	replies: getReplyListData(state),
	user: getUserData(state)
});

const mapDispatch = (dispatch) => ({
	fetchReplies: (commentId) =>
		dispatch(fetchCommentsFromComment(commentId)),
	hideReplies: (commentId) =>
		dispatch(hideReplySection(commentId)),
	addReply: (commentId, userId, content) =>
		dispatch(postCommentToComment(commentId, userId, content)),
  deleteReply: (commentId) =>
      dispatch(deleteComment(commentId)),
});

export default connect(mapState, mapDispatch)(CommentItem);
