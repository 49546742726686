import React from "react";

const ChatTypingIndicator = () => {
  return (
    <div className="chat-message chat-message-incoming">
      <div className="chat-message-content">
        <span className="typing typing-1"></span>
        <span className="typing typing-2"></span>
        <span className="typing typing-3"></span>
      </div>
    </div>
  );
};

export default React.memo(ChatTypingIndicator);
