import React from "react";
import { Link } from "react-router-dom";

const ChatHeader = () => {
  const headerText = process.env.REACT_APP_GPT_TOOL_NAME || "";

  return (
    <div className="chat-header">
      <div className="chat-header-icon">
        <img src={"/assets/images/gpt-btn-icon.png"} alt="wtp-gpt-icon" />
      </div>
      <div className="chat-header-content">{headerText}</div>
      <Link to="/gpt-feature" className="btn-help-page">
        <img src="/assets/icons/question-mark.png" alt="question-mark" />
      </Link>
    </div>
  );
};

export default React.memo(ChatHeader);
