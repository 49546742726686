// @flow
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

// import Footer from './Footer'
import Header from "./common/Header";
import LeftNavigation from "./navigation/LeftNavigation";
import { getUserData } from "../../store/auth/authSelectors";
import {
  getProjectData,
  getProjects,
  getProjectStatus,
} from "../../store/project/projectSelectors";
import { setAndSaveProjectData } from "../../store/project/projectActions";

import NotesContainer from "../notes/NotesContainer";
import CommentList from "../comment/List";
import CommentsContainer from "../comments/CommentsContainer";
import ShareContainer from "../share/ShareContainer";
import ProjectMembers from "../projectMembers/ProjectMembers";
import EditWarning from "../common/editWarning/EditWarning";

const isLoading = (currentProject, projects) =>
  hasEverLoaded
    ? false
    : currentProject?.title === "" &&
      (currentProject?.user === null || projects?.length === 0);

let hasEverLoaded = false;

const BasePage = ({
  children,
  user,
  location,
  isWithoutNavigation,
  currentProject,
  projects,
}) => {
  const timeout = useRef();
  const [msgClose, setMsgClose] = useState(false); // Comments - Open/Close
  const [share, setShare] = useState(false); // Share - Open/Close
  const [members, setMembers] = useState(false); // Project members - Open/Close
  const [openModal, setOpenModal] = useState(false); // Edit warning - Open/Close
  const projectsLoading = isLoading(currentProject, projects);

  return (
    <Fragment>
      {user ? (
        <div className="page">
          {!isWithoutNavigation ? (
            <Header
              share={share}
              setShare={setShare}
              msgClose={msgClose}
              setMsgClose={setMsgClose}
              members={members}
              setMembers={setMembers}
              userRole={user && user.user.role && user.user.role.type}
            />
          ) : null}

          <div className={"projects-loading"} data-active={projectsLoading}>
            <div className={"projects-loading__title"}>Loading ..</div>
          </div>

          <main
            className={`page-content ${isWithoutNavigation ? "no-nav" : ""}`}
          >
            {!isWithoutNavigation ? <LeftNavigation /> : null}
            <div
              className={location.pathname === "/" ? "overview-container" : ""}
            >
              {children}
            </div>
            {!isWithoutNavigation && (
              <NotesContainer path={location.pathname} />
            )}

            <CommentList />

            {msgClose && (
              <CommentsContainer
                msgClose={msgClose}
                setMsgClose={setMsgClose}
              />
            )}
            {share && <ShareContainer share={share} setShare={setShare} />}
            {members && (
              <ProjectMembers
                members={members}
                setMembers={setMembers}
                share={share}
                setShare={setShare}
              />
            )}
            {openModal && (
              <EditWarning openModal={openModal} setOpenModal={setOpenModal} />
            )}
          </main>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              source: "BasePage",
              redirect: true,
            },
          }}
        />
      )}
    </Fragment>
  );
};
const mapState = (state) => ({
  user: getUserData(state),
  projectStatus: getProjectStatus(state),
  currentProject: getProjectData(state),
  projects: getProjects(state),
});

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: () => dispatch(setAndSaveProjectData()),
});

export default withRouter(connect(mapState, mapDispatch)(BasePage));
