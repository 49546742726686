import React from "react";

function CloudDownload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      width="100%"
      height="100%"
    >
      <path d="M17.5 21H7A7 7 0 015.276 7.223 5.995 5.995 0 0114.6 4.2l-1.2 1.6a3.994 3.994 0 00-6.324 2.46l-.131.7-.7.106A5 5 0 007 19h10.5a4.5 4.5 0 003.682-7.087l1.636-1.151A6.5 6.5 0 0117.5 21z"></path>
      <path d="M17 16.414l-3.707-3.707 1.414-1.414L17 13.586l2.293-2.293 1.414 1.414L17 16.414z"></path>
      <path d="M16 5h2v10h-2z"></path>
    </svg>
  );
}

export default CloudDownload;
