// @flow
import React from 'react'

import { NavigationData } from '../../../utils/constants'
import NavigationSection from './NavigationSection'

const LeftNavigation = () => (
 
  <div className="navigation-section-container">
    <div className="left-navigation print-hide">
      {
        NavigationData.map((navigation, id) => (
          <NavigationSection
            key={id}
            link={navigation.link}
            title={navigation.title}
            heading={navigation.heading}
            subSection={navigation.subSection}
            imageSrc={navigation.imageSrc}
          />
        ))
      }
      <span className="line"></span>
    </div>
  </div>

)

export default LeftNavigation
