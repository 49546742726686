import React from "react";

function Send() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        className="on-mouse-over-svg"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M14.667 1.333L7.333 8.667M14.667 1.333L10 14.667l-2.667-6-6-2.667 13.334-4.667z"
      ></path>
    </svg>
  );
}

export default Send;
