import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  getUpdateStatus,
  getSelectOpportunitySectionCount,
  getProjectData,
  getCurrentAgileFocusOpportunityIds,
  getCurrentAgileFocusPrimaryOpportunityId,
  getAllSetOpportunities
} from '../../../store/project/projectSelectors'
import { setAndSaveProjectData } from '../../../store/project/projectActions'

import './WorksheetPage3.scss';
import WorksheetPage from './WorksheetPage';
import TextSection from '../../common/worksheet-3/TextSection'
import PrimaryOpportunitySection from '../../common/worksheet-3/PrimaryOpportunitySection'
import WorksheetSection from '../../common/worksheet-3/WorksheetSection'
import TooltipItem from '../../common/worksheet-1/TooltipItem'
import {getMemberRole} from "../../../utils/helpers";
import {getUserData} from "../../../store/auth/authSelectors";

const SECTIONS_MIN = 4;

const scrollToHorizontalEnd = (element, debouncer, ms = 50) => {
  debouncer && clearTimeout(debouncer);

  setTimeout(() => {
    element.scrollTo({
      top: 0,
      left: element.scrollWidth,
      behavior: "smooth"
    });

    clearTimeout(debouncer);
  }, ms);
};

class WorksheetPage3 extends Component {
  constructor(props) {
    super(props);

    const length = props.opportunities?.length;

    this.maxSections = (length && length > SECTIONS_MIN ? length: SECTIONS_MIN) - 1;

    this.opportunityElement = React.createRef();
    this.opportunityDebounce = null;
  }

  handleAddSection = () => {
    const { onSetAndSaveProjectData, projectData } = this.props;
    const count = projectData.selectOpportunitySectionCount;
    const shouldAdd = count < this.maxSections;

    if(shouldAdd) {
      onSetAndSaveProjectData("selectOpportunitySectionCount", projectData.selectOpportunitySectionCount + 1);

      scrollToHorizontalEnd(
        this.opportunityElement.current,
        this.opportunityDebounce
      );
    }
  }

  handleDeleteSection = (sectionId) => {
    const { onSetAndSaveProjectData, projectData } = this.props

    onSetAndSaveProjectData("selectOpportunitySectionCount", projectData.selectOpportunitySectionCount - 1)
  }

  handleOpportunitySet = (opportunity, sectionId) => {
    const { onSetAndSaveProjectData, currentAgileFocusOpportunityIds } = this.props
    const currentOpportunityId = currentAgileFocusOpportunityIds.findIndex(opp => opp && opp.sectionId === sectionId)

    if (opportunity) {
      if (currentOpportunityId === -1) {
        currentAgileFocusOpportunityIds.push({
          opportunityId: opportunity.uid,
          sectionId,
        })
      } else {
        currentAgileFocusOpportunityIds[currentOpportunityId].opportunityId = opportunity.uid
      }
    } else {
      currentAgileFocusOpportunityIds[currentOpportunityId].opportunityId = null
    }

    onSetAndSaveProjectData("currentAgileFocusOpportunityIds", currentAgileFocusOpportunityIds)
  }

  render() {
    const {sectionCount, pdfInitialOpportunityCount, pdfOpportunityCount, isWithoutNavigation, currentProject, user, currentOpportunity} = this.props;
    let opportunitySections = []
    let initialSectionCount = 1
    let opportunitySectionCount = sectionCount

    if (pdfInitialOpportunityCount) {
      initialSectionCount = pdfInitialOpportunityCount
    }

    if (pdfOpportunityCount) {
      opportunitySectionCount = pdfOpportunityCount
    }

    const readonly = getMemberRole(currentProject, user?.user?.email) === 'canView';

    for (var i = initialSectionCount; i <= opportunitySectionCount; i++) {
      opportunitySections.push(
        <div key={i} className="col-sm-4 select-opportunity-section">
          <WorksheetSection
            sectionId={i}
            isLastSection={i === sectionCount}
            onAddSection={this.handleAddSection}
            onDeleteSection={this.handleDeleteSection}
            onSetCurrentOpportunity={this.handleOpportunitySet}
            readonly={readonly}
          />
        </div>
      )
    }

    return (
      <WorksheetPage isWithoutNavigation={isWithoutNavigation} link="/worksheet-3">
        <div className='worksheet-page3'>
          <PrimaryOpportunitySection />
          <div className='select-section row' data-transparent={currentOpportunity === null}>
            <div className='col-sm-3'>
              <TextSection />
            </div>
            <div className="col-sm-9">
              <div className="row opportunities-section" ref={this.opportunityElement}>
                {opportunitySections}
              </div>
              <div className="adder">
                <TooltipItem
                  type="add-section"
                  onClick={() => this.handleAddSection()}
                  readonly={readonly}
                />
                <div className="counter">
                  {sectionCount} / {this.maxSections}
                </div>
              </div>
            </div>
          </div>
        </div>
      </WorksheetPage>
    );
  }
}

const mapState = (state) => ({
  sectionCount: getSelectOpportunitySectionCount(state),
  updateStatus: getUpdateStatus(state),
  projectData: getProjectData(state),
  currentAgileFocusOpportunityIds: getCurrentAgileFocusOpportunityIds(state),
  currentProject: getProjectData(state),
  user: getUserData(state),
  currentOpportunity: getCurrentAgileFocusPrimaryOpportunityId(state),
  opportunities: getAllSetOpportunities(state)
})

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
})

export default connect(mapState, mapDispatch)(WorksheetPage3)
