
export const getUserData = (state) => state.user.userData

export const getRememberedUserEmail = (state) => state.user.userEmail

export const getIsRememberChecked = (state) => state.user.isRememberChecked

export const getFetchStatus = (state) => state.user.fetchStatus

export const getSignupStatus = (state) => state.user.signupStatus
