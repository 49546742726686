// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Footer from '../common/Footer'
import './AuthorizationPage.scss'
import './PrivacyPage.scss'

class PrivacyPage extends Component {

  render() {

    return (
      <div className="privacy-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="heading">
              <span className="title">
              Privacy policy
              </span>
            </div>
            
          </div>
          <div className="row overview-wrapper">
            <div className="overview-block">
                <div className="overview-text">
                  <p>
                  ENTC-EPFL (“ENTC”,”us”, “we”, or “our”) operates the www.app.wheretoplay.co website and any service included therein (hereinafter referred to as the "Website").
                  </p>
                  <p>
                  This Privacy Policy (the "Policy") covers the Website and informs you of our policies regarding the collection, use and disclosure of personal data when you use our Website.
                  </p>
                  <p>
                  Information about the responsible party, the data controller (referred to as the “controller” in the GDPR): 
                  </p>
                  <p>
                  ENTC-EPFL, having its registered office address at Station 5, CH-1015 Lausanne Switzerland
                  </p>
                  <p>
                  Email: <a href="mailto:entc@epfl.ch">entc@epfl.ch</a>
                  </p>
                  <p>
                  By accessing and using the Website, you agree to the collection and use of information in accordance with this policy. 
                  </p>
                  <p>
                  For the purpose of this Policy, <b>Personal Data</b> means any information that can be used to identify, locate or contact an individual.  
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>General</h3>
                </div>
                <div className="overview-text">
                  ENTC is the data controller, as this term is defined under the EU General Data Protection Regulation ("GDPR"), of the Personal Data collected via the Website.
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Personal Data Collection</h3>
                </div>
                <div className="overview-text">
                We collect via the Website the following types of Personal Data:
                </div>
                <ul className="overview-list">
                  <li><b>Contact details you provide to us when you open a "user account" on the website via the registration page:</b> such data may include name and email address.</li>
                  <li><b>Contact details you provide to us when you</b> approach us via the "contact us" page: such data may include name and email address. </li>
                  <li><b>Survey details:</b> Personal data you provide us when filling in surveys provided by the Website. Such data may include your occupation, professional background, expertise and related information.</li>
                  <li><b>Data received from third parties:</b> we may collect further personal data about your occupation, professional background, expertise and related information that is publicly available about you and your company for research studies. We may use such Data for research and analysis purposes, on an aggregated basis and without identifying our users or their business in any way</li>
                  <li><b>Use of Non-personal Data:</b> We may also collect non-personal data which consists of your use of Website's market opportunity navigator, including data you submit when using the navigator's worksheets, including business considerations and business characteristics submitted to the worksheets ("Aggregated Data"), that were uploaded and hosted under your Website account. We may use such Aggregated Data for research and analysis purposes, on an aggregated basis and without identifying our users or their business in any way.
                  For the avoidance of doubt, any non-personal data connected or linked to any Personal Data shall be deemed as Personal Data as long as such connection or linkage exists.
                  </li>
                  <li><b>Data minimization –</b> we request our Users to use the Website only for the intended purpose, and to avoid uploading excessive Personal Data to the Website.  We do not knowingly collect data that includes special categories of data, like personal data about health, ethnicity, political opinions, religious beliefs, etc., please do not upload such data to the Website.</li>
                </ul>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Purposes</h3>
                </div>
                <div className="overview-text">
                  We may use your Personal Data in the following ways:
                </div>
                <ul className="overview-list">
                  <li>To interact with our Users – if you approach us via the "contact us" page, we will use contact details you provided to reply to your message or to contact you by a Website representative.</li>
                  <li>To allow log-in to your user account which allows your use of the market opportunity navigator.</li>
                  <li>Research purposes – we may combine personal data collected about you with data you provide us while using the navigator, for our analysis and research purposes; such analysis will be conducted after your data is anonymized in a form that does not identify or trace to you. </li>
                  <li>To gather analysis or valuable information collected so that we can improve our Website, monitor the usage of our Website, and detect, prevent and address technical issues and fraudulent or illegal activity.</li>
                  <li>Subject to your consent, we may send you newsletters, marketing or promotional materials, product updates and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or the instructions provided in any email we send or by contacting us: <a href="mailto:info@wheretoplay.co">info@wheretoplay.co</a>.</li>
                </ul>
              
              </div>

              
              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Legal Basis for Processing</h3>
                </div>
                <div className="overview-text">
                  If you are from the European Economic Area (EEA), ENTC’s legal basis for collecting and using personal data under the GDPR, are as follows:  
                </div>
                <ul className="overview-list">
                  <li>Consent for the purposes indicated in the previous section in lit. a, b, d, and e.</li>
                  <li>Public task for the purpose indicated in the previous section in lit. c: the processing is necessary to perform a task in the public interest, in particular according to art. 36c and seq. of the Swiss Federal Act on the Federal Institutes of Technology of 4 October 1991, “FIT Act”).</li>
                </ul>
              </div>


              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Disclosure of Personal Data</h3>
                </div>
                <div className="overview-text">
                We may disclose Personal Data to third parties only in the following cases:
                </div>
                <ul className="overview-list">
                  <li>When we use a cloud computing service provider for the storage of data; </li>
                  <li>When we use third party service providers that are required for the performance of our activities, and who are bound by law or contract to protect the Personal Data and only use it in accordance with our instructions; for example - service providers for web development, IT service, sending email notifications, analytics purposes etc.;</li>
                  <li>if we are under a duty to disclose or share Personal Data in order to comply with any legal obligation, such as in response to a subpoena, including to law enforcement agencies and courts;</li>
                  <li>we may also disclose Personal Data where needed to affect the transfer of our assets, to enforce our rights, protect our property, or protect the rights, property or safety of others, or as needed to support external auditing, compliance and corporate governance functions.</li>
                </ul>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Retention of Data</h3>
                </div>
                <div className="overview-text">
                  <p>
                    We will retain your Personal Data only for as long as is necessary for the purposes set out in this Policy. We will retain and use your Personal Data for our research purposes, and to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our policies.
                  </p>
                  <p>
                    We may also continue to retain anonymized Aggregated Data, for research and analysis purposes for as long as necessary.
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Transfer of Data</h3>
                </div>
                <div className="overview-text">
                  <p>
                    Your Personal Data, may be transferred to — and maintained on — computers located outside of your country, where the data protection laws may differ from those of your jurisdiction.
                  </p>
                  <p>
                    ENTC will take all the steps reasonably necessary to ensure that your data is treated in accordance with this Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data, including through adequate contractual means.
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Security of Data</h3>
                </div>
                <div className="overview-text">
                  <p>
                    The security of your data is important to us but remember that no method of transmission over the Internet is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                  </p>
                  <p>
                    User is solely responsible for keeping its account name & password strictly confidential. 
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h3>
                </div>
                <div className="overview-text">
                <p>
                  If you are a resident of the European Economic Area (EEA), you have certain data protection rights. These rights may include the following, depending on the circumstances: right to access data, right to rectify data, right to object to processing, right to erase data, right to data portability. In addition, you have the right to lodge a complaint with a supervisory authority.
                </p>
                <p>
                 <b>You also have the right to withdraw consent from using your personal data and Website user account and/or from receiving promotional and other newsletters</b> at any time.
                </p>
                <p>
                 If you wish to exercise any of your rights, please contact us:<br />
                  <a href="mailto:entc@epfl.ch">entc@epfl.ch</a>
                </p>
                <p>
                 Please note that we may ask you to verify your identity before responding to such requests.
                </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Links to Other Sites</h3>
                </div>
                <div className="overview-text">
                  <p>
                    Our Website may contain links to other sites that are not necessarily operated by us. If you click a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy and Cookie Policy of every site you visit.
                  </p>
                  <p>
                    We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Children’s Privacy</h3>
                </div>
                <div className="overview-text">
                  <p>
                    Our Website does not address anyone under the age of 18 (“Children”).
                  </p>
                  <p>
                    We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                  </p>
                </div>
              </div>

              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Changes to This Policy</h3>
                </div>
                <div className="overview-text">
                  <p>
                    We may update our Privacy and Cookie Policy from time to time. We will notify you of any changes by posting the new Privacy and Cookie Policy on this page.
                  </p>
                  <p>
                    We will let you know via email and/or a prominent notice on our Website, prior to the change becoming effective and update the “effective date” at the top of this Privacy and Cookie Policy.
                  </p>
                  <p>
                    You are advised to review this Privacy and Cookie Policy periodically for any changes. Changes to this Privacy and Cookie Policy are effective when they are posted on this page.
                  </p>
                </div>
              </div>
              <div className="overview-block">
                <div className="overview-heading">
                  <h3>Contact Us</h3>
                </div>
                <div className="overview-text">
                  If you have any questions about this Privacy and Cookie Policy, please email us at <a href="mailto:entc@epfl.ch">entc@epfl.ch</a>.
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default withRouter(PrivacyPage)
