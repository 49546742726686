import React, { Component } from 'react';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { setPdfStatus } from '../../../store/project/projectActions'

class PdfConvertor extends Component {
  constructor(props) {
    super(props);
    this.convertToPdf = this.convertToPdf.bind(this);
    this.targetRef = React.createRef();
  }

  convertToPdf() {
    const { targetRef, filename, x, y, options, onComplete, opportunitiesLength, onSetPdfStatus, additionalPagesCount } = this.props;
    const source = targetRef || this.targetRef;
    const targetComponent = source.current || source;
    let svgElements = document.body.querySelectorAll('svg');
    console.log(svgElements)
    svgElements.forEach((item) => {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height= null;
    });
    let pagesCount = 1
    const pdf = new JsPdf('l', 'px', [1400, 1000])
    let pageCount = 0
    onSetPdfStatus('WORKING')

    window.scrollTo(0,0)

    if (opportunitiesLength > 0) {
      pagesCount = opportunitiesLength + additionalPagesCount
    }

    for (var pageId = 1; pageId <= 6 + pagesCount; pageId++) {
      const page = document.getElementById(`print-page-${pageId}`);

      if (pageId > 1) {
        pdf.addPage(1400, 'l');
      }

      html2canvas(page, {
        logging: false,
        useCORS: true,
        scale: 1.4,
      }).then(canvas => {
          const id = Number(page.id.substring(page.id.lastIndexOf('-') + 1))
          //! This is all just html2canvas stuff
          var srcImg  = canvas;
          var sX      = 0;
          var sY      = 0; // start 980 pixels down for every new page
          var sWidth  = 2500;
          var sHeight = page.clientHeight + 1100;
          var dX      = 0;
          var dY      = 0;
          var dWidth  = 2500;
          var dHeight = page.clientHeight + 1100;

          let onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', 2500);
          onePageCanvas.setAttribute('height', page.clientHeight + 1100);
          let ctx = onePageCanvas.getContext('2d');

          // var width = onePageCanvas.width;
          // var height = onePageCanvas.clientHeight;

          ctx.fillStyle = '#ededed'
          ctx.fillRect(0, 0, 2500, page.clientHeight + 1100);
          ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);

          var canvasDataURL = onePageCanvas.toDataURL("image/jpeg", 1.0);
          pdf.setPage(id);
          pdf.addImage(canvasDataURL, 'JPEG', x, y);
          pageCount++

          if (pageCount === 6 + pagesCount) {
            pdf.save(filename);
            this.props.onSetPdfStatus('SUCCESS')
          }
      });
    }

    if (onComplete) {
        onSetPdfStatus('SUCCESS')
        onComplete();
     }
  }

  render() {
    const { children } = this.props;
    return children({ convertToPdf: this.convertToPdf, targetRef: this.targetRef });
  }
}

PdfConvertor.propTypes = {
  filename: PropTypes.string.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  options: PropTypes.object,
  scale: PropTypes.number,
  children: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

PdfConvertor.defaultProps = {
  filename: 'download.pdf',
  x: 0,
  y: 0,
  scale: 1.4,
  onComplete: undefined,
  targetRef: undefined
};

const mapDispatch = (dispatch) => ({
  onSetPdfStatus: (status) => dispatch(setPdfStatus(status)),
})

export default connect(null, mapDispatch)(PdfConvertor);
