import gqlBackend from "../utils/gqlBackend";

export const apiGetCommentOriginByName = async (originName) => {
  const query = `
    query {
      commentOrigins(filters: { name: {eq: "${originName}"} }) {
        data {
          id
        }
      }
    }
  `;

  return await gqlBackend(query);
};

export const apiGetOriginCommentCount = async (originName) => {
  const originResponse = await apiGetCommentOriginByName(originName);
  let originId = originResponse?.data?.commentOrigins?.data[0]?.id || null;

  if(originId) {
    const origin = await gqlBackend(`
      query {
        commentOrigin(id: "${originId}") {
          data {
            attributes {
              comments {
                data {
                  id
                }
              }
            }
          }
        }
      }
    `);
    const count = origin?.data?.commentOrigin?.data.attributes.comments?.length || 0;

    return count;
  }

  return 0;
};

export const apiGetOriginComments = async (originId) => {
  if(originId) {
    const origin = await gqlBackend(`
      query {
        commentOrigin(id: "${originId}") {
          data {
            attributes {
              comments {
                data {
                  id
                }
              }
            }
          }
        }
      }
    `);

    const comments = origin?.data?.commentOrigin?.data?.attributes?.comments?.data || [];
    let commentsArray = []
    comments.forEach(comment => {
      commentsArray.push(comment.id)
    })

    return commentsArray;
  }

  return 0;
};

export const apiGetCommentItems = async (originId) => {
  if(originId) {
    const origin = await gqlBackend(`
      query {
        commentItem(id: "${originId}") {
          data {
           id
           attributes {
             content
             origin {
               data {
                 id
               }
             }
             author {
               data {
                 attributes {
                   username
                   email
                 }
               }
             }
             tail {
               data {
                 id
               }
             }
             head {
               data {
                 id
               }
             }
           }
         }
       }
      }
    `);

    const comments = origin?.data?.commentItem?.data?.attributes?.tail?.data || [];
    let commentsArray = []
    comments.forEach(comment => {
      commentsArray.push(comment.id)
    })

    return commentsArray;
  }

  return 0;
};

export const apiPostCommentToOrigin = async (originName, userId, content) => {
  const originResponse = await apiGetCommentOriginByName(originName);
  let originId = originResponse?.data?.commentOrigins?.data[0]?.id || null;

  if (!originId) {
    const origin = await gqlBackend(`
      mutation {
        createCommentOrigin(
          data: {
            name: "${originName}"
          }
        ) {
          data {
            id
          }          
        }
      }
    `);
    originId = origin.data.createCommentOrigin.data.id;
  }

  const query = await gqlBackend(`
  mutation {
    createCommentItem(
      data: {
        author: "${userId}"
        origin: "${originId}"
        content: """${content}"""
      }
    ) {
       data {
        id
        attributes {
          content
          origin {
            data {
              id
            }
          }
          author {
            data {
              attributes {
                username
                email
              }
            }
          }
          tail {
            data {
              id
            }
          }
        }
      }
    }
  }
  `);

  const commentId = query.data.createCommentItem.data.id;
  let commentsArray = await apiGetOriginComments(originId);
  commentsArray.push(commentId)

  const comments = await gqlBackend(`
    mutation {
      updateCommentOrigin( id: "${originId}",
        data: {
          comments: [${commentsArray}]
        }
      ) {
        data {
          id
          attributes {
            comments {
              data {
                id
                attributes {
                  content
                  author {
                    data {
                      attributes {
                        username
                        email
                      }
                    }
                  }
                  tail {
                    data {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return query;
};

export const apiGetCommentsFromOrigin = async (originName) => {
  // TODO: update Strapi and get only tail count
  const query = `
    query {
      commentOrigins(filters: { name: { eq: "${originName}" } }) {
        data {
          attributes {
            comments {
              data {
                id
                attributes {
                  content
                  author {
                    data {
                      attributes {
                        username
                        email
                      }
                    }
                  }
                  tail {
                    data {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return await gqlBackend(query);
};

export const apiPostCommentToComment = async (commentId, userId, content) => {
  const query = await gqlBackend(`
  mutation {
    createCommentItem(
      data: {
        author: "${userId}"
        head: "${commentId}"
        content: """${content}"""
      }
    ) {
       data {
        id
        attributes {
          content
          origin {
            data {
              id
            }
          }
          author {
            data {
              attributes {
                username
                email
              }
            }
          }
          tail {
            data {
              id
            }
          }
          head {
            data {
              id
            }
          }
        }
      }
    }
  }
  `);

  const tailCommentId = query.data.createCommentItem.data.id;
  let commentsArray = await apiGetCommentItems(commentId);
  commentsArray.push(tailCommentId)

  const comments = await gqlBackend(`
    mutation {
      updateCommentItem( id: "${commentId}",
        data: {
          tail: [${commentsArray}]
        }
      ) {
        data {
         id
         attributes {
           content
           origin {
             data {
               id
             }
           }
           author {
             data {
               attributes {
                 username
                 email
               }
             }
           }
           tail {
             data {
               id
             }
           }
           head {
             data {
               id
             }
           }
         }
       }
     }
    }
  `);

  return query;
};

export const apiGetCommentsFromComment = async (commentId) => {
  // TODO: update Strapi and get only tail count
  const query = `
    query {
      commentItem(id: "${commentId}") {
        data {
          attributes {
            tail {
              data {
                id
                attributes {
                  content
                  author {
                    data {
                      attributes {
                        username
                        email
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return await gqlBackend(query);
};

export const apiDeleteComment = async (commentId) => {
  const query = `
    mutation {
      deleteCommentItem(id:"${commentId}") {
        data {
          id
        }
      }
    }
  `;

  return await gqlBackend(query);
};
