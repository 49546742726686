import React from 'react';
import HeaderArrow from '../../assets/svgs/HeaderArrow'
import './Dropdowns.scss';


class Dropdown extends React.Component {
  constructor() {
    super();

    this.state = {
      displayMenu: false,
    };

    this.container = React.createRef();
    this.controls = React.createRef();
    this.outside = this.outside.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
  };

  outside(e) {
    if (!this.container.current?.contains(e.target)) {
      document.removeEventListener('click', this.outside)
      this.toggleDropdownMenu(e, true);
    }
  }

  toggleDropdownMenu(event, isOpen) {
    const { isCurrentPrimarySelected, onHandleWarning, primary } = this.props
    const { displayMenu } = this.state

    if (!isCurrentPrimarySelected && onHandleWarning) {
      onHandleWarning()
    }
    event.preventDefault()

    
    this.setState({ displayMenu: !displayMenu });
    if (!isOpen) {
      if (!displayMenu) {
        document.addEventListener("click", this.outside);
      } else {
        document.removeEventListener('click', this.outside)
      }
    } else {
      document.removeEventListener('click', this.outside)
    }
    
    this.setState({ displayMenu: !displayMenu });
  }

  render() {
    const {
      items,
      color,
      onDropdownClick,
      currentItem,
      onChange,
      isProjectDropdown,
      onCreateProject,
      onDeleteProject,
      onBlur,
      onKeyDown,
      projectTitle,
      className,
      isResetOption,
      user,
    } = this.props

    const projectsDropdown = () => {
      const ownerItems = items && items.filter(i => i.members.filter(m => m.userId && m.userId.toString() === user?.user.id.toString() && m.role === 'owner').length);
      const canEditItems = items && items.filter(i => i.members.filter(m => m.userId && m.userId.toString() === user?.user.id.toString() && m.role === 'canEdit').length);
      const canViewItems = items && items.filter(i => i.members.filter(m => m.userId && m.userId.toString() === user?.user.id.toString() && m.role === 'canView').length);

      return <>
        {!!ownerItems.length && <span className={'section'}>Owner</span>}
        {ownerItems
          .map((data, id) => {

              return <li onClick={() => onDropdownClick(data)} key={id}>
                <a className={color} href="#">
                  {data?.app ? `${data.app.title} - ${data.title}`: data.title}
                </a>
                {isProjectDropdown && items.length > 1 &&
                <span>
                          <img className="delete-icon" onClick={() => onDeleteProject(data.id)}
                               src="/assets/icons/basket-icon.png" alt="delete"/>
                        </span>
                }
              </li>
            }
          )
        }
        {!!canEditItems.length && <span className={'section'}>Can edit</span>}
        {canEditItems
          .map((data, id) => {

              return <li onClick={() => onDropdownClick(data)} key={id}>
                <a className={color} href="#">
                  {data?.app ? `${data.app.title} - ${data.title}`: data.title}
                </a>
              </li>
            }
          )
        }
        {!!canViewItems.length && <span className={'section'}>Can view</span>}
        {
          canViewItems
            .map((data, id) => {

                return <li onClick={() => onDropdownClick(data)} key={id}>
                  <a className={color} href="#">
                    {data?.app ? `${data.app.title} - ${data.title}`: data.title}
                  </a>
                </li>
              }
            )
        }</>
    }

    const appTitle = currentItem?.app?.title;
    const currentTitle = currentItem?.title;
    const title = appTitle ? `${appTitle} - ${currentTitle}`: currentTitle;

    return (
      <span  ref={this.container} className={`select-dropdown fix  ${className ? '' : ''}`} style={{
        lineHeight: !isProjectDropdown ? (currentItem ? (currentItem.title.length > 40 ? '1.2rem' : '1.6rem' ) : '2rem') : '3rem'
      }}>
        {isProjectDropdown ? (
          <span className="stay-there ">
            <input spellCheck="false" type="text" className="project-title input-changed input-fixed-height" value={projectTitle} onChange={(e) => onChange(e)} onBlur={onBlur} onKeyDown={onKeyDown}/>
            <span className='header-arrow-style ' onClick={this.showDropdownMenu}><HeaderArrow /></span>
          </span>
        ) : (
          <span onClick={this.toggleDropdownMenu}>
            <span
              className={`dropdown-repair-worksheet3 dropdown-title opportunity-select dropdown-title-${color}`}
              style={{
                fontSize: currentItem ? (currentItem.title.length > 60 ? '1rem' : (currentItem.title.length > 40 ? '1.2rem' : '1.6rem' )) : '1.6rem',
                lineHeight: currentItem ? (currentItem.title.length > 60 ? '1.4rem' : (currentItem.title.length > 40 ? '1.6rem' : '2rem' )) : '2remrem'
              }}
            >
              {title}
              <span style={{marginLeft: '0.5rem'}}><HeaderArrow /></span>
              </span>
          </span>
        )}
        {this.state.displayMenu ? (
            <ul>
              {
                !isProjectDropdown && items.map((data, id) => (
                  <li onClick={() => onDropdownClick(data)} key={id}>
                    <a className={color} href="#">
                      {data?.app ? `${data.app.title} - ${data.title}`: data.title}
                    </a>
                    {isProjectDropdown && items.length > 1 &&
                    <span>
                      <img className="delete-icon" onClick={() => onDeleteProject(data.id)}
                           src="/assets/icons/basket-icon.png" alt="delete"/>
                    </span>
                    }
                  </li>
                ))
              }
              {isProjectDropdown && projectsDropdown()}

              {isResetOption && currentItem &&
              <li onClick={() => onDropdownClick(null)} key="reset">
                <span className="create-text">
                  Reset selection
                </span>
              </li>
              }
            </ul>
          ) :
          null
        }
      </span>

    );
  }
}

export default Dropdown;
