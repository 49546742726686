import React from "react";

const CommentArrow = () => (
  <svg
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
  >
    <g><path d="M7 10l5 5 5-5z" /></g>
  </svg>
);

export default CommentArrow;
