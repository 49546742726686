// @flow
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { forgotPassword, clearError } from '../../../store/auth/authActions'
import './AuthorizationPage.scss'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
})

class ForgotPasswordPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  componentDidMount() {
    this.props.onClearError()
  }

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({ error: '' })
    await this.props.onForgotPassword(values.email)
    if (this.props.forgotPasswordStatus === 'SUCCESS') {
      this.setState({ error: 'Email has been sent' })
    } else {
      this.setState({ error: 'Could not send an email' })
    }
    setSubmitting(false)
  }

  render() {
    return (
      <div className="authorization-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                Forgot Password
              </div>
            </div>
          </div>
          <div className="row">
            <div className="authorization-page-content">
              <Formik
                initialValues={{ email: '' }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={this.onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <label className="email-label" htmlFor="email">Email</label>
                    <Field type="email" name="email" />
                    <ErrorMessage name="email" component="div" />
                    <div>{ this.props.error || this.state.error }</div>
                    <button className="btn send-email-button" type="submit" disabled={isSubmitting}>
                      Send me an email
                    </button>
                  </Form>
                )}
              </Formik>
              <div className="back-to-login">
                Back to
                {' '}
                <Link to="/login">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  forgotPasswordStatus: state.user.fetchStatus,
  error: state.user.error
})

const mapDispatch = (dispatch) => ({
  onForgotPassword: (email) => dispatch(forgotPassword(email)),
  onClearError: () => dispatch(clearError()),
})

export default withRouter(connect(mapState, mapDispatch)(ForgotPasswordPage))
