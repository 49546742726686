import React from 'react';
import { connect } from 'react-redux'
import './NotesContainer.scss';

import { getNotesVisibility, getProjectNotes } from '../../store/project/projectSelectors'
import { setAndSaveProjectData, setUpdateStatus } from '../../store/project/projectActions'
import { toggleNotes } from '../../store/project/projectActions'
import { getUserData } from '../../store/auth/authSelectors'

import NotesComment from './NotesComment'

import NotesPage from '../../assets/svgs/NotesPage'
import Cross from '../../assets/svgs/Cross'

class NotesContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hidden: props.hidden,
      notes: props.projectNotes,
      noteText: '',
    }

    this.handleClosingNotes = this.handleClosingNotes.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectNotes !== this.props.projectNotes) {
      this.setState({
        notes: this.props.projectNotes,
      })
    }
  }

  handleClosingNotes = () => {
    const { onToggleNotes } = this.props

    onToggleNotes(true)
  }

  handleSubmitButton = () => {
    const { notes, noteText } = this.state
    const { path, onSetAndSaveProjectData } = this.props

    notes.push({
      noteText,
      pathname: path,
    })

    onSetAndSaveProjectData("notes", notes)

    this.setState({
      noteText: '',
    })
  }

  handleTextareaChange = (e) => {
    this.setState({
      noteText: e.target.value,
    })
  }

  render() {
    const { hidden, path, user } = this.props
    const { notes, noteText } = this.state
    let filteredNotes = null

    if (notes) {
      filteredNotes = notes.filter(note => note.pathname === path)
    }

    return (
      //Notes menu
      <div className="notes-container">
        <div className={`${hidden ? '' : 'notes-only'}`}></div>

        <div className={`notes-section ${hidden ? 'hidden-notes' : ''}`}>
          <div className="cross ">
            <div className="close-notes__btn">
              <span onClick={() => this.handleClosingNotes()} >
                <Cross />
              </span>
            </div>
          </div>
          <div className="notes-flex-base-container ">
            <div className="notes-flex-item note">
              <NotesPage />
            </div>
            <div className="notes-flex-item text">
              Notes
            </div>
          </div>

          <div className="rect-container">
            <div className="rect" />
          </div>

          <div className="notes-content-comment">
            {filteredNotes && filteredNotes.map(note => (
                <>
                  <NotesComment text={note.noteText} user={user.user.username} />
                  <div className="rect-container in-list">
                    <div className="rect" />
                  </div>
                </>
            ))}
          </div>

          <div className="notes-content-textarea">
            <div className="notes-content">
              <textarea
                onChange={(e) => this.handleTextareaChange(e)}
                value={noteText}
                name="notes"
                id="notes"
                cols="40"
                rows="10"
                placeholder="Your note goes here">
              </textarea>
              <button onClick={() => { this.handleSubmitButton() }} className="styled-button">Add note</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  hidden: getNotesVisibility(state),
  projectNotes: getProjectNotes(state),
  user: getUserData(state),
})

const mapDispatch = (dispatch) => ({
  onToggleNotes: (visibility) => dispatch(toggleNotes(visibility)),
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
  onSetUpdateStatus: (status) => dispatch(setUpdateStatus(status)),
})

export default connect(mapState, mapDispatch)(NotesContainer)
