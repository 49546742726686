// @flow
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUpdateStatus, getAllAgileFocusOpportunities } from '../../../../store/project/projectSelectors'
import { getAgileFocusItems } from '../../../../utils/helpers'
import ViewPage from '../ViewPage'
import TooltipItem from '../../../common/worksheet-1/TooltipItem'
import './AgileFocusPage.scss'

class AgileFocusPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opportunities: props.opportunities,
      // boxCount: getBoxCount(props.opportunities),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({
        opportunities: this.props.opportunities,
        // boxCount: getBoxCount(this.props.opportunities),
      })
    }
  }

  render() {
    const { opportunities } = this.state
    const { isWithoutNavigation } = this.props
    let keepOpenItems = getAgileFocusItems(opportunities, 'keepOpen')
    let pursueNowItems = getAgileFocusItems(opportunities, 'pursueNow')
    let placeInStorageItems = getAgileFocusItems(opportunities, 'placeInStorage')

    return (
      <ViewPage isWithoutNavigation={isWithoutNavigation} link='/agile-focus-dartboard'>
          <div className="row">
            <div className="agile-focus-content">
              {!isWithoutNavigation &&
                <Fragment>
                  {/* <Link to={'/market-opportunity-set'} className="first-screen-element">
                      <img src={'/assets/images/opportunity-set-big.png'} alt={'opportunity-set-big'} />
                    </Link> */}
                    <Link to={'/attractiveness-map'} className="previous-screen-element">
                      <img src={'/assets/images/attractiveness-map-big.png'} alt={'attractiveness-map-big'} />
                    </Link>
                </Fragment>
              }
              <div className="agile-focus-element">
                <img src={'/assets/images/agile-focus-big.png'} alt={'agile-focus-big'} />
                {placeInStorageItems.map((item, id) => {
                  let positionClassName

                  if (id < 5) {
                    positionClassName = 'left'
                  } else if (id >= 5 && id < 9) {
                    positionClassName = 'bottom'
                  } else if (id >= 9 && id < 14 ) {
                    positionClassName = 'right'
                  } else {
                    positionClassName = `top-right-${id - 13}`
                  }

                  const application = item?.app?.title || null;

                  if (id >= 14) {
                    return (
                      <TooltipItem
                        key={id}
                        type="agile-focus-boxes"
                        onClick={null}
                        segmentId={null}
                        opportunities={placeInStorageItems}
                        opportunity={item}
                        agileFocusId={id}
                      >
                        <div id={"Tooltip-" + item.agileFocusOption} className={`box place-in-storage ${positionClassName}`}>
                          <div className="title">
                            {item.title}
                          </div>
                        </div>
                      </TooltipItem>
                    )
                  } else {
                    return (
                      <div key={id} className={`box place-in-storage ${positionClassName}
                        ${id === 1 || id === 6 || id === 10 ? 'second' :
                          id === 2 || id === 7 || id === 11 ? 'third' :
                          id === 3 || id === 8 || id === 12 ? 'fourth' :
                          id === 4 || id === 13 ? 'fifth' : ''
                        }
                      `}
                      >
                        <div className="title">
                          <span className={"sticky-note__app"}>{application}</span>
                          <span className={"sticky-note__title"}>{item.title}</span>
                        </div>
                      </div>
                    )
                  }
                })}
                {keepOpenItems.map((item, id) => {
                  let positionClassName

                  if (id < 3) {
                    positionClassName = 'left'
                  } else if (id >= 3 && id < 5) {
                    positionClassName = 'bottom'
                  } else if (id >= 5 && id < 8 ) {
                    positionClassName = 'right'
                  } else {
                    positionClassName = `top-right-${id - 7}`
                  }

                  const application = item?.app?.title || null;

                  if (id >= 8) {
                    return (
                      <TooltipItem
                        key={id}
                        type="agile-focus-boxes"
                        onClick={null}
                        segmentId={null}
                        opportunities={keepOpenItems}
                        opportunity={item}
                        agileFocusId={id}
                      >
                        <div id={"Tooltip-" + item.agileFocusOption} className={`box keep-open ${positionClassName}`}>
                          <div className="title">
                            {item.title}
                          </div>
                        </div>
                      </TooltipItem>
                    )
                  } else {
                    return (
                      <div key={id} className={`box keep-open ${positionClassName}
                        ${id === 1 || id === 4 || id === 6 ? 'second' :
                          id === 2 || id === 7 ? 'third' : ''
                        }
                      `}
                      >
                        <div className="title">
                          <span className={"sticky-note__app"}>{application}</span>
                          <span className={"sticky-note__title"}>{item.title}</span>
                        </div>
                      </div>
                    )
                  }
                })}
                {pursueNowItems.map((item, id) => {
                  const positionClassName = `middle-${id + 1}`
                  const application = item?.app?.title || null;

                  return (
                    <TooltipItem
                      key={id}
                      type="agile-focus-boxes"
                      onClick={null}
                      segmentId={null}
                      opportunities={pursueNowItems}
                      opportunity={item}
                      agileFocusId={id}
                    >
                      <div id={"Tooltip-" + item.agileFocusOption} className={`box pursue-now ${positionClassName}`}>
                        <div className="title">
                          <span className={"sticky-note__app"}>{application}</span>
                          <span className={"sticky-note__title"}>{item.title}</span>
                        </div>
                      </div>
                    </TooltipItem>
                  )
                })}
              </div>
            </div>
          </div>
      </ViewPage>
    )
  }
}

const mapState = (state) => ({
  opportunities: getAllAgileFocusOpportunities(state),
  updateStatus: getUpdateStatus(state),
})


export default connect(mapState)(AgileFocusPage)
