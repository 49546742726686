import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

import { getUserData } from "../../store/auth/authSelectors";
import { updateUserProfile } from "../../store/auth/authActions";
import {
  getProjectData,
  getProjects,
} from "../../store/project/projectSelectors.js";

import App from "../App.jsx";
import All from "../pages/pdf/All.jsx";
import ChatBot from "../chatbot";
import AnnouncementModal from "../common/announcement-modal/AnnouncementModal.jsx";

const isLoading = (currentProject, projects) =>
  hasEverLoaded
    ? false
    : currentProject?.title === "" &&
      (currentProject?.user === null || projects?.length === 0);

let hasEverLoaded = false;

const RootPage = ({ user, onUpdateUserProfile, currentProject, projects }) => {
  const currentRoute = useLocation();
  const projectsLoading = isLoading(currentProject, projects);

  const [dismissAnnouncementModal, setDismissAnnouncementModal] =
    useState(true);

  const closeAnnouncementModal = () => {
    // RETURN IF ALREADY DISABLED
    if (dismissAnnouncementModal) return;
    setDismissAnnouncementModal(true);
    onUpdateUserProfile({ dismissedAnnouncementModal: true });
  };

  useEffect(() => {
    if (user?.user?.dismissedAnnouncementModal) {
      setDismissAnnouncementModal(true);
    } else {
      setDismissAnnouncementModal(false);
    }
  }, [user?.user?.id]);

  return (
    <>
      <App />
      <All />
      {user?.user?.id ? (
        <>
          {currentRoute?.pathname === "/worksheet-1" && (
            <ChatBot key={currentProject.id} />
          )}
          {!dismissAnnouncementModal && !projectsLoading && (
            <AnnouncementModal onRequestClose={closeAnnouncementModal} />
          )}
        </>
      ) : null}
    </>
  );
};

const mapState = (state) => ({
  user: getUserData(state),
  currentProject: getProjectData(state),
  projects: getProjects(state),
});

const mapDispatch = (dispatch) => ({
  onUpdateUserProfile: (updatedUser) =>
    dispatch(updateUserProfile(updatedUser)),
});

export default connect(mapState, mapDispatch)(RootPage);
