import BasePage from "../BasePage";

const AITermsAndConditions = () => {
  return (
    <BasePage>
      <div className="gpt-feature-page container">
        <div id={"tooltip"} />
        <div className="row">
          <div className="col-lg text-center">
            <h1 className="h1 font-weight-bold mb-5">
              <u>AI Terms and Conditions</u>
            </h1>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">1. Introduction</h3>
            <p>
              WhereToPlay website and any associated application ("Website",
              "We") integrates the OpenAI API ("API", "API Feature") to provide
              various functionalities and services utilizing artificial
              intelligence capabilities.
            </p>
            <p>
              These Terms and Conditions (the "Terms") outline the terms and
              conditions under which the API is used and informs users about the
              associated risks and limitations. These Terms form an integral
              part of the Website's Terms.
            </p>
            <p>
              BY USING THE API FEATURE, YOU ACKNOWLEDGE THAT IT HAS READ,
              UNDERSTOOD AND AGREED TO BE BOUND BY THESE AI TERMS. YOU REPRESENT
              AND WARRANT THAT YOU HAVE THE FULL LEGAL AUTHORITY TO ENTER INTO
              THESE TERMS AND IF YOU ARE ACTING ON BEHALF OF AN ORGANIZATION OR
              COMPANY, YOU HAVE THE LEGAL AUTHORITY TO BIND SUCH ORGANIZATION OR
              COMPANY TO THESE API TERMS.
            </p>
            <p>
              IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE THE API
              FEATURE.
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">2. Use of the API</h3>
            <p>
              The Website utilizes the API to deliver potential business
              directions for users that choose to interact with the AI bot. The
              API is a product of OpenAI and is used under license.
            </p>
            <p>
              We hereby grant you a limited, revocable, non-exclusive,
              non-transferable and non-sublicensable license to use the API
              Feature when you log-in to the services unless otherwise agreed by
              the parties, and solely for the intended purpose as set forth
              herein. The use of the API Feature is subject to your compliance
              with any and all applicable laws and these API Terms.
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">3. Accuracy and Reliability</h3>
            <ul>
              <li>
                You may use the content provided by the API ("Content") for any
                legal and lawful purpose only, at your own risk.
              </li>
              <li>
                <strong>No Guarantee of Accuracy:</strong> The information and
                responses generated by the API are based on artificial
                intelligence and machine learning models. While we strive to
                provide accurate and relevant information, we do not guarantee
                the accuracy, completeness, or reliability of any content
                produced by the API.
              </li>
              <li>
                <strong>No Professional Advice:</strong> The Content should not
                be construed as a sole professional advice. Content may not
                always be accurate, and you should not rely on content from our
                API as a sole source of truth or factual information, or as a
                substitute for professional advice.
              </li>
              <li>
                Due to the nature of machine learning, the Content may{" "}
                <strong>not</strong> be unique and the API feature may generate
                the same or similar output for other users.
              </li>
              <li>
                You must evaluate the Content for accuracy and appropriateness
                for your use case, including using human review as appropriate,
                before using the Content.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">4. Acceptable Use Policy</h3>
            <p>
              You hereby represent and warrant that you will not, nor will you
              authorize anyone on your behalf to:
            </p>
            <p>
              (a) mislead anyone that the Content generated by human-generated;
            </p>
            <p>
              (b) use the API feature to generate content that expresses or
              promotes hate, harassment, or violence, exploits or harms
              children, encourages self-harm, presents illegal, sexual,
              political, harmful, false, deceiving or misleading information,
              misuses personal data, contains malware, unsolicited bulk content,
              ransomware, viruses, or other software;
            </p>
            <p>
              (c) use the API feature in a way that infringes, misappropriates
              or violates any third party rights;
            </p>
            <p>
              (d) reverse assemble, reverse compile, decompile, translate or
              otherwise attempt to discover the source code or underlying
              components of models, algorithms, and systems of the API feature;
            </p>
            <p>
              (e) use the API Feature to develop foundation models that compete
              with Us or OpenAI; and
            </p>
            <p>
              (f) use any method to extract data from the API feature, including
              web scraping, web harvesting, or web data extraction methods,
              other than as permitted through the API.
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">5. Third Party Providers</h3>
            <p>
              The API feature is provided by artificial intelligence technology
              developed and provided by OpenAI LLC or affiliated companies (“
              <strong>OpenAI</strong>”). Any input you submit may be used by
              OpenAI for model training and improvement. You acknowledge and
              understand that by using the API feature the OpenAI Terms of
              Service also govern your use of the API feature (available{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://openai.com/policies/service-terms/"
              >
                here
              </a>
              , as may be updated from time to time) (“
              <strong>OpenAI Terms</strong>”). The inputs may be shared with and
              manually reviewed by a person, including by third party
              contractors located around the world.
            </p>
            <p>
              Therefore, you shall not use or provide any information that is
              sensitive or that should not be viewed by others, including
              personal data. We have no control over the use of the Input, thus,
              any use of such is at your own risk and We do not represent,
              undertake or warrant to any security or control of or to the
              input.
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">6. User Responsibilities</h3>
            <ul>
              <li>
                <strong>Compliance with Laws:</strong> Users are responsible for
                ensuring that their use of the API complies with all applicable
                laws and regulations.
              </li>
              <li>
                <strong>Acceptable Use:</strong> Users must not use the API for
                any illegal or unauthorized purposes, including but not limited
                to spamming, harassment, or distribution of harmful content.
              </li>
              <li>
                <strong>Content:</strong> The output insights generated and
                returned by the API feature (“<strong>Output</strong>”) are
                based on the metadata, provided you (“<strong>Input</strong>”),
                the Input and Output shall collectively be referred herein as “
                <strong>Content</strong>”. You are solely responsible for
                ensuring that the Input you provide through the API feature
                complies with applicable laws and these API Terms.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">7. Limitations of Liability</h3>
            <ul>
              <li>
                <strong>No Liability for Errors:</strong> We do not accept any
                liability for errors or omissions in the content generated by
                the API. Users are responsible for verifying the information and
                using it at their own risk.
              </li>
              <li>
                <strong>No Liability for Disruption:</strong> We are not
                responsible for any disruptions, downtime, or failures of the
                API. The API is provided "as is," and we do not warrant that it
                will be available at all times or without interruption.
              </li>
              <li>
                IN ADDITION TO ANY DISCLAIMERS SET FORTH HEREIN, THE API FEATURE
                IS PROVIDED ON AN “AS IS” AND AS “AVAILABLE” BASIS. WE MAKE NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                AS TO THE OPERATION OF THE API FEATURE, OR THE INFORMATION,
                TEXT, AND CONTENT INCLUDED IN THE OUTPUT, OR THE USE OF THE
                INPUT, INCLUDING WITHOUT LIMITATION, ACCURACY OF THE RESULTS,
                AVAILABILITY, SUITABILITY, RELIABILITY, OR CONTENT OF ANY
                INFORMATION PROVIDED THROUGH THE AI FEATURE. WE WILL HAVE NO
                LIABILITY OR RESPONSIBILITY ARISING IN ANY WAY FROM THE USE OF
                THE API FEATURE OR ANY ERRORS OR OMISSIONS CONTAINED IN THE
                CONTENT.
              </li>
              <li>
                NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE
                FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS,
                GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE LIMITATIONS IN
                THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">8. Data Privacy and Security</h3>
            <ul>
              <li>
                <strong>Data Handling:</strong> The API may collect and process
                data as part of its operation. We are committed to protecting
                your privacy and handling your data in accordance with our
                Privacy Policy and therefore you are required to{" "}
                <strong>
                  avoid sharing any personal data whatsoever during your
                  interactions with the API.
                </strong>
              </li>
              <li>
                <strong>No Guarantee of Security:</strong> While we take
                reasonable measures to protect data, we cannot guarantee the
                absolute security of data transmitted through or processed by
                the API.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">9. Changes to the API</h3>
            <ul>
              <li>
                <strong>Modification or Discontinuation:</strong> The API and
                its functionalities may be modified or discontinued at any time
                by OpenAI or by us. We do not guarantee continued availability
                or support for any particular feature.
              </li>
              <li>
                The API feature is currently provided free of charge, however,
                we reserve the right to charge fees at any time.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">10. Contact Information</h3>
            <p>
              If you have any questions or concerns about the use of the API on
              this Website, please contact us at:{" "}
              <a target="_blank" href="mailto:info@wheretoplay.co">
                info@wheretoplay.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default AITermsAndConditions;
