import React, { Component } from 'react';
import { connect } from 'react-redux'

import BasePage from '../BasePage';
import { withRouter, Link } from 'react-router-dom';
import { NavigationData } from '../../../utils/constants';
import { toggleNotes } from '../../../store/project/projectActions'
import './ViewPage.scss';

class ViewPage extends Component {

    handleOpenNotes = () => {
        const { onToggleNotes } = this.props
        onToggleNotes(false)
    }

    render() {
        let section;

        NavigationData.map(sectionData => {
            if (this.props.link === sectionData.link) {
                return section = sectionData;
            }
            return null
        });

        return (
            <BasePage isWithoutNavigation={this.props.isWithoutNavigation}>
                <div className="view-page">
                    <div className="heading">
                        <div className="title">
                            <span>
                                {section.heading}
                            </span>
                        </div>
                        <div className={`sheet-icon ${section.link === '/agile-focus-dartboard' ? 'smaller' : ''}`}>
                            <img src={section.iconSrc} alt={'sheet-icon'} />
                        </div>
                        <div className="description">
                          {section.textPart1}
                          {' '}
                          <Link className="link" to={section.subSection.link}>
                            {section.subSection.heading}
                          </Link>
                          {' '}
                          {section.textPart2}
                        </div>
                    </div>
                    {this.props.children}
                    {section.bottomText &&
                        <div className="row">
                            <div className="footer">
                                <div className="row">
                                    <div className="box-icon">
                                        <img src={section.bottomIconSrc} alt={'box-icon'} />
                                    </div>
                                    <div className="hint">
                                        {section.bottomText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </BasePage>
        );
    }
}

const mapDispatch = (dispatch) => ({
    onToggleNotes: (visibility) => dispatch(toggleNotes(visibility)),
})
  
export default withRouter(connect(null, mapDispatch)(ViewPage))

// export default withRouter(ViewPage)
