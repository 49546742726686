import React from "react";
import "./index.scss";
import BasePage from "../BasePage";
import { Link } from "react-router-dom";

const GPTFeature = () => {
  const appVersion = process.env.REACT_APP_BUILD_VER || "";

  return (
    <BasePage>
      <div className="gpt-feature-page container">
        <div id={"tooltip"} />
        <div className="row">
          <div className="col-lg">
            <h1 className="h1 font-weight-bold mb-5">
              How the Opportunity Discovery Assistant works for you?
            </h1>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">
              What is the Opportunity Discovery Assistant?
            </h3>
            <p>
              Identifying groundbreaking market opportunities stemming from your
              unique abilities is a challenging task. The Opportunity Discovery
              Assistant is a free powerful tool to help you break the ice of
              this discovery process. You will find it under Worksheet 1.
            </p>
            <p>
              The tool was developed on GPT-4, and optimized for the
              identification phase. It will go over your unique abilities- once
              characterized on the upper part of Worksheet 1- and suggest
              different applications stemming from these abilities and different
              customers who may need them (i.e. what can you offer and for
              whom).
            </p>
            <p>
              Think about the Opportunity Discovery Assistant as another member
              in your team, with a 360° views. In a click of a button, you can
              uncover opportunities that you have never thought of before.
            </p>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">
              How to work with the Opportunity Discovery Assistant?
            </h3>
            <p>
              The Chat GPT button (on the lower right corner of Worksheet 1)
              will only be activated once you characterize your unique abilities
              (on the upper part of this worksheet). You can also think about
              abilities that you may not have developed yet (e.g., new
              technologies).
            </p>
            <p>
              Once you click the Chat GPT button, you will be asked to summarize
              your innovation in one sentence (to provide the context of the
              abilities you described), and the assistant will automatically
              suggest a list of applications and customers for you.
            </p>
            <p>
              You can then ask for further clarifications about any of these
              suggestions or dive deeper into a specific domain.
            </p>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">Scope and limitations</h3>
            <p>
              1) The Opportunity Discovery Assistant is a free tool, and
              therefore the number of tokens are capped. You will see your
              progress bar on the upper part of the Assistant. If you need more
              assistance once the conversation had reached its limit, you can
              download it and continue the interaction with Chat GPT on your
              own.
            </p>
            <p>
              2) The assistant is optimized to suggest a varied set of
              opportunities for you, but it cannot assess how promising these
              ideas are. It is your job to screen and evaluate which ones you
              wish to further explore. Please note that some ideas suggested by
              the assistant might be totally irrelevant.
            </p>
            <p>
              3) As this tool is optimized for the discovery process,
              non-relevant requests are blocked. For example, it will not
              provide data about the size of a market, related patents, specific
              companies etc. For this information, please run your own
              conversations with Chat GPT or other LLM models.
            </p>
            <p>
              4) Only project owners can interact with the Opportunity Discovery
              Assistant. Other team members can view the interaction.
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <h3 className="font-weight-bold">Ending the conversation</h3>
            <p>
              When you are done or reached the limit of your tokens, you can
              download the conversation and save it on your computer. Once you
              log out, the interaction will be saved but a new conversation will
              start. If you will try to reload or close the tab of the app, a
              warning dialog will appear to note you will \"lose your work\".
              This message is there to alert you to save the chat in case you
              forgot to do so.
            </p>
            <p>
              For further assistance in the discovery process, learn more about
              our{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wheretoplay.co/training/"
              >
                live training possibilities
              </a>
              .
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <p>
              <Link to="/ai-terms-and-conditions">AI Terms and Conditions</Link>
            </p>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg">
            <p className="build-version">* Version {appVersion}</p>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default GPTFeature;
