import React from 'react';
import './Projects.scss';
import EditIcon from "../../assets/svgs/EditIcon";
import Trash from "../../assets/svgs/Trash";

const membersFilter = (project, userId, role) => {
  if (project.members) {
    if (role === 'owner' && !project.members.filter(m => m.userId === userId).length) {
      return true
    } else {
      return project.members.filter(m => m.userId === userId && m.role === role).length || false
    }
  } else if (!project.members && role === 'owner') {
    return true
  } else return false
  // if (role === 'owner' && project.members && !project.members.filter(m => m.userId === userId).length) return true;
  // else return project.members.filter(m => m.userId === userId && m.role === role).length || false;
}

const filterRole = (userId, projects, role) => {
  if (projects) {
    if (role === "owner") return projects.filter((p) => {
      if (p.members && p.members.length) return membersFilter(p, userId, role);
      else return true;
    })

    else return projects.filter((p) => membersFilter(p, userId, role));
  }
};

const Controls = ({ that, project }) => (
  <div className="controls">
    <div
      className="control control-edit"
      onClick={() => that.rename(project)}>
      <EditIcon />
    </div>
    <div
      className="control control-delete"
      onClick={() => that.deleteDialog(project["id"])}>
      <Trash />
    </div>
  </div>
);

const VIEWS = {
  default: 0,
  add: 1,
  rename: 2,
  success: 3
};

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      value: null,
      view: VIEWS.default,
      confirm: null,
      dialog: false,
    };

    this.debouncer = null;
    this.container = React.createRef();
    this.controls = React.createRef();

    this.outside = this.outside.bind(this);
  };

  success() {
    this.setState({ view: VIEWS.success });

    this.debouncer && clearTimeout(this.debouncer);

    this.debouncer = setTimeout(() => {
      this.setState({ view: VIEWS.default });
      clearTimeout(this.debouncer);
    }, 1000);
  }

  outside(e) {
    !this.container.current?.contains(e.target) && this.close();
  }

  open(e) {
    if(!this.controls.current?.contains(e.target)) {
      this.setState({ opened: true });
      document.addEventListener("click", this.outside);
    }
  }

  close() {
    this.setState({ opened: false });
    document.removeEventListener("click", this.outside);
  }

  add(value = null) {
    this.close();
    value === null && this.props.createProject();
    this.setState({ view: value ? VIEWS.rename: VIEWS.add, value: value || "" });
  }

  update() {
    this.props.updateProject("title", this.state.value);
    this.setState({ value: null });
    this.success();
  }

  reset() {
    const { currentProject } = this.props;

    this.rename(currentProject);

    this.setState({
      value: null,
      opened: false,
      view: VIEWS.default
    });
  }

  current(project) {
    this.props.setCurrent(project);
  }

  value(e) {
    const { currentProject } = this.props;
    const title = currentProject.title;
    const value = e.target.value;

    e.key === "Enter" && !!value && this.update();
    e.key === "Escape" && !!title && title !== "Undefined Project" && this.reset();
  }

  change(e) {
    const value = e.target.value;
    this.setState({ value });
  }

  cancel() {
    this.setState({
      confirm: null,
      dialog: false
    });
  }

  delete(id) {
    this.cancel();
    this.props.deleteProject(id);
    this.close();
  }

  deleteDialog(id) {
    if(this.props.projects.length > 1) this.setState({ confirm: id });
    else this.setState({ dialog: true });
  }

  rename(project) {
    this.current(project);
    this.add(project.title);
  }

  render() {
    const { user, projects, currentProject, readonly } = this.props;
    const { opened, value, view } = this.state;
    const userId = user?.user?.id.toString() || null;
    const id = currentProject && currentProject["id"];

    view === 0 && currentProject.title === "Untitled Project" && this
      .rename({...currentProject, title: ""});

    const roleView = filterRole(userId, projects, "canView");
    const roleEdit = filterRole(userId, projects, "canEdit");
    const roleOwner = filterRole(userId, projects, "owner");

    const lists = [
      ["View", roleView],
      ["Edit", roleEdit],
      ["Own", roleOwner]
    ];

    return (
      <>
        {this.state.confirm !== null && (
          <div className={"delete-dialog"}>
            <div
              className={"dialog-background"}
              onClick={() => this.cancel()}
            />

            <div className={"dialog-card"}>
              <div className={"dialog-title"}>
                Do you really want to delete project "{projects.find((project) => project.id === this.state.confirm)?.title || `ID: ${this.state.confirm}`}"?
              </div>
              <div className="dialog-options">
                <div
                  className={"dialog-button yes"}
                  onClick={() => this.delete(this.state.confirm)}
                >
                  Yes
                </div>

                <div
                  className={"dialog-button no"}
                  onClick={() => this.cancel()}
                >
                  No
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.dialog === true && (
          <div className={"delete-dialog"}>
            <div
              className={"dialog-background"}
              onClick={() => this.cancel()}
            />

            <div className={"dialog-card"}>
              <div className={"dialog-title"}>
                Last project cannot be deleted. Please add a new one and then delete.
              </div>

              <div className="dialog-options">
                <div
                  className={"dialog-button yes"}
                  onClick={() => this.cancel()}
                >
                  Ok
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={"project-dropdown"} ref={this.container}>
          <div className="content">
            <div className={"input-wrapper"}
                 onClick={(e) => opened
                   ? this.close(e): this.open(e)}
            >
              {value !== null ? (
                <input
                  className={"input input-new"}
                  placeholder={"Name your project"}
                  onKeyUp={(e) => this.value(e)}
                  onChange={(e) => this.change(e)}
                  autoFocus={true}
                  value={this.state.value}
                />
              ): (
                <>
                  <div className={"input-arrow"}>
                    {opened ? "▲": "▼"}
                  </div>

                  <div className={"input input-current"}>
                    {currentProject?.title || ""}
                  </div>
                  {!readonly &&
                    <div ref={this.controls}>
                      <Controls that={this} project={currentProject} />
                    </div>
                  }
                </>
              )}
            </div>

            <div
              className="button-wrapper"
              data-fixed={value !== null}
              data-success={this.state.view === VIEWS.success}
            >
              {value !== null ? (
                <button
                  className={"button button-apply"}
                  onClick={() => this.update()}
                >
                <span className={"label"}>
                  {this.state.view === VIEWS.add ? "Add": "Rename"}
                </span>
                </button>
              ): (
                <button
                  className={"button button-add"}
                  onClick={() => this.state.view !== VIEWS.success && this.add()}
                >
                <span className={"icon icon-" + (this.state.view === VIEWS.success ? "success": "default")}>
                  {this.state.view === VIEWS.success ? "✓": "+"}
                </span>
                </button>
              )}
            </div>
          </div>

          { opened && (
            <div className="projects">
              {lists.filter(([, v]) => !!v?.length).map(([k, v], i) => (
                <div className={"list"} key={k + i}>
                  <div className={"role"}>{k}</div>
                  {v.map(project => (
                    <div
                      className={"project"}
                      data-active={project["id"] === id}
                      key={project["id"]}
                    >
                      <div className="title" onClick={() => this.current(project)}>
                        {project.title}
                      </div>
                      {(k !== 'View') && <Controls that={this} project={project} />}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) }
        </div>
      </>
    );
  }
}

export default Projects;
