// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUpdateStatus, getAllAttractivenessOpportunities } from '../../../../store/project/projectSelectors'
import { getTooltipOpportunities } from '../../../../utils/helpers'
import ViewPage from '../ViewPage'
import TooltipItem from '../../../common/worksheet-1/TooltipItem'
import './AttractivenessMapPage.scss'

class AttractivenessMapPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opportunities: props.opportunities,
      tooltipOpportunities: getTooltipOpportunities(props.opportunities),
      currentClickedOpportunityId: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunities !== this.props.opportunities) {
      this.setState({
        opportunities: this.props.opportunities,
        tooltipOpportunities: getTooltipOpportunities(this.props.opportunities),
      })
    }
  }

  handleBoxClick = (id) => {
    this.setState({
      currentClickedOpportunityId: id,
    })
  }

  render() {
    const { opportunities, tooltipOpportunities, currentClickedOpportunityId } = this.state
    const { isWithoutNavigation } = this.props
    let opportunityCount = [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ]

    return (
      <ViewPage isWithoutNavigation={isWithoutNavigation} link='/attractiveness-map'>
          <div className="row">
            <div className="attractiveness-map-content">
              {!isWithoutNavigation &&
                <Link to={'/market-opportunity-set'} className="previous-screen-element">
                  <img src={'/assets/images/opportunity-set-big.png'} alt={'opportunity-set-big'} />
                </Link>
              }
              <div className="attractiveness-map-element">
                <img src={'/assets/images/attractiveness-map-big.png'} alt={'attractiveness-map-big'} />
                {opportunities.map((opportunity, key) => {
                  const { overallChallenge, overallPotential, app, title } = opportunity
                  const application = app && app?.title ? app.title: null;

                  opportunityCount[overallPotential][overallChallenge]++

                  return (
                    <TooltipItem
                      key={key}
                      type="boxes"
                      onClick={null}
                      segmentId={null}
                      opportunities={tooltipOpportunities}
                      opportunity={opportunity}
                    >
                      <div
                        className={`
                        ${overallChallenge === 1 ? 'xlowmid' :
                          overallChallenge === 2 ? 'xmid' :
                          overallChallenge === 3 ? 'xmidhigh' :
                          overallChallenge === 4 ? 'xhigh' :
                          overallChallenge === 5 ? 'xhighsuper' :
                          overallChallenge === 6 ? 'xsuper' : ''
                        }
                        ${overallPotential === 1 ? 'ylowmid' :
                          overallPotential === 2 ? 'ymid' :
                          overallPotential === 3 ? 'ymidhigh' :
                          overallPotential === 4 ? 'yhigh' :
                          overallPotential === 5 ? 'yhighsuper' :
                          overallPotential === 6 ? 'ysuper' : ''
                        }
                        box
                        ${opportunityCount[overallPotential][overallChallenge] === 2 ? 'second' :
                          opportunityCount[overallPotential][overallChallenge] === 3 ? 'third' : ''
                        }
                        ${currentClickedOpportunityId === opportunity.uid && 'highlighted'}`}
                        id={"Tooltip-" + overallPotential + overallChallenge}
                        onClick={() => this.handleBoxClick(opportunity.uid)}
                      >
                        <div className="title">
                          {application && <span className={"sticky-note__app"}>{application}</span>}
                          <span className={"sticky-note__title"}>{title}</span>
                        </div>
                      </div>
                    </TooltipItem>
                  )
                })}
              </div>
              {!isWithoutNavigation &&
                <Link to={'/agile-focus-dartboard'} className="next-screen-element">
                    <img src={'/assets/images/agile-focus-big.png'} alt={'agile-focus-big'} />
                </Link>
              }
            </div>
          </div>
      </ViewPage>
    )
  }
}

const mapState = (state) => ({
  opportunities: getAllAttractivenessOpportunities(state),
  updateStatus: getUpdateStatus(state),
})


export default connect(mapState)(AttractivenessMapPage)
