import React from "react";
import Markdown from "react-markdown";

const ChatMessage = ({ message }) => {
  const { messageType, messageText } = message;

  return (
    <div
      className={`chat-message chat-message-${
        messageType === "outgoing" ? "outgoing" : "incoming"
      }`}
    >
      <span
        className={`chat-message-tail ${
          messageType === "outgoing" ? "tail-out" : "tail-in"
        }`}
      ></span>
      {messageType === "outgoing" && (
        <div
          className="chat-message-content"
          dangerouslySetInnerHTML={{ __html: messageText }}
        ></div>
      )}

      {messageType === "incoming" && (
        <div className="chat-message-content">
          <Markdown
            components={{
              a({ node, children, ...props }) {
                const location = window.location;
                let url = new URL(props.href ?? "", location.href);

                if (url.origin !== location.origin) {
                  props.target = "_blank";
                  props.rel = "noopener noreferrer";
                }

                return <a {...props}>{children}</a>;
              },
            }}
          >
            {messageText}
          </Markdown>
        </div>
      )}
    </div>
  );
};

export default React.memo(ChatMessage);
