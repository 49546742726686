import React, { useState } from 'react'
import './Comment.scss'
import Reply from '../../assets/svgs/Reply'
import Trash from "../../assets/svgs/Trash";

const Comment = ({ userName, comment, replies, onUpdateComment, onDeleteComment, onUpdateReply, lineIndent }) => {

  const [replyF, setReplyF] = useState(false)
  const [reply, setReply] = useState('')
  const [indent, setIndent] = useState(lineIndent + 4)

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  }

  const handleSubmitReply = () => {
    const updatedReplies = [...replies]

    if (reply !== '') {
      updatedReplies.push({
        commentText: reply,
        replies: [],
        user: {
          userName,
        },
        isReply: true,
      })

      comment.replies = [...updatedReplies]

      if (comment.isReply) {
        onUpdateReply(comment, comment.id)
      } else {
        onUpdateComment(comment, comment.id)
      }

      setReply('')
      setReplyF(false)
    }
  }

  const handleReplyUpdate = (reply, id) => {
    const updatedReplies = [...replies]
    const replyId = updatedReplies.findIndex(reply => reply && reply.id === id)

    updatedReplies[replyId] = {...reply}
    comment.replies = [...updatedReplies]

    if (comment.isReply) {
      onUpdateReply(comment, comment.id)
    } else {
      onUpdateComment(comment, comment.id)
    }
  }

  return (
    <div className="comment">
      <div className="comment-column" style={{ marginLeft: `${indent + 'rem'}` }}>
        {comment.isReply &&
          <div className="rect-container in-reply">
            <div className="rect" />
          </div>
        }
        <div className="comment-data">
          <div className="account-icon">
            {comment.user.userName.split('')[0].toUpperCase()}
          </div>
          <div className="comments-name-item">
            {comment.user.userName}
          </div>
          {!comment.isReply && (
            <div className="reply">
              <Reply replyF={replyF} setReplyF={setReplyF} className="reply-svg" />
            </div>
          )}
          <div
            className="delete"
            onClick={() => onDeleteComment(comment.id, comment.commentText)}
          >
            <Trash />
          </div>
        </div>
        <div className="column text-field">
          <div className="comments-text-item">
            {comment.commentText}
          </div>
        </div>
        {/* If you reply*/}
      </div>
      {replies && replies.length !== 0 &&

          replies.map((reply) =>(
            <Comment
              userName={userName}
              comment={reply}
              replies={reply.replies}
              onUpdateReply={handleReplyUpdate}
              onDeleteComment={onDeleteComment}
              lineIndent={indent}
            />
          ))
      }
      {/* If you click on reply*/}
      {replyF &&
        <div className="reply-container" style={{ marginLeft: `${indent + 'rem'}` }}>
          <div className="comment-column">
            <div className="rect-container in-reply">
              <div className="rect" />
            </div>
            <div className="comment-data">
              <div className="account-icon">
                {userName.split('')[0].toUpperCase()}
              </div>
              <div className="comments-name-item">
                {userName}
              </div>
            </div>
          </div>
          <div className="reply-message">
            <textarea
              name="reply"
              onChange={(e) => handleReplyChange(e)}
              className="reply-textarea"
              value={reply}
              placeholder="Your reply goes here">
            </textarea>
            <button onClick={() => handleSubmitReply()} className="styled-button">Reply</button>
          </div>
        </div>
      }
    </div>
  )
}

export default Comment
