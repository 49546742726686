import React, { Component } from 'react';
import './SelectionBoxes.scss';
import CircleImage from './CircleImage';

class SelectionBoxes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeBox: this.props.currentSelectionValue - 1,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSelectionValue !== this.props.currentSelectionValue) {
      this.setState({
        activeBox: this.props.currentSelectionValue - 1,
      })
    }
  }

  handleSelect = (index, type, value) => {
    const { onClick, name } = this.props
    const { activeBox } = this.state

    if (activeBox === index) {
      this.setState({
        activeBox: null,
      })
      onClick(name, null)
    } else {
      this.setState({
        activeBox: index
      })

      if (type === 'text') {
        onClick(name, value)
      } else {
        onClick(name, index + 1)
      }
    }
  }

  render() {
    const { activeBox } = this.state;
    const { data, readonly } = this.props;

    return (
      <div className='selection-boxes'>
        {data.map((box, i) => (
          <div
            key={i}
            className={`box ${activeBox === i && 'active'} ${readonly ? 'readonly' : ''}`}
            onClick={() => this.handleSelect(i, box.type, box.name)}
          >
            {box.type === 'text' ? (
              <span>{box.text}</span>
            ) : (
              <CircleImage
                activeBox={activeBox}
                imgClass={box.imgClass}
                index={i}
                readonly={readonly}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default SelectionBoxes;
