// @flow
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { login, loginGoogle, clearError } from '../../../store/auth/authActions'
import { getIsRememberChecked, getRememberedUserEmail, getFetchStatus, getSignupStatus } from '../../../store/auth/authSelectors'
import { BACKEND_URL } from '../../../utils/constants'
import Footer from '../common/Footer'
import './AuthorizationPage.scss'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .required('Required')
})

class LoginPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      isChecked: this.props.isRememberChecked,
      userEmail: this.props.userEmail,
      isModalOpen: true,
    }
  }

  async componentDidMount () {
    if (this.props.location.search) {
      await this.props.onLoginGoogle(this.props.location.search)
      if (this.props.loginStatus === 'SUCCESS') {
        this.props.history.push('/')
      } else {
        // Tell the user his google login failed
      }
    }

    this.props.onClearError()
  }

  onSubmit = async (values, { setSubmitting }) => {
    this.setState({ error: '' })

    const res = await this.props.onLogin(values.email, values.password, this.state.isChecked)

    if (this.props.loginStatus === 'SUCCESS') {
      this.props.history.push('/')
    } else {
      this.setState({ error: 'Login failed' })
    }
    setSubmitting(false)
  }

  handleCheck = (name) => {
    this.setState({ isChecked: !this.state.isChecked })
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  render() {
    const { isChecked, userEmail, isModalOpen } = this.state
    const { signupStatus } = this.props

    return (
      <div className="authorization-page">
        <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title">
                Login
              </div>
            </div>
          </div>
          {signupStatus === 'SUCCESS' &&
            <Modal
              isOpen={isModalOpen}
              toggle={this.toggleModal}
              centered
            >
              <ModalHeader toggle={this.toggleModal}>
                Signup success
              </ModalHeader>
              <ModalBody>
                Thank you for singing up. Please check your email and validate your email. Check your spam folder if you didn't receive confirmation email.
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={this.toggleModal}
                >
                  Ok
                </Button>
              </ModalFooter>
            </Modal>
          }
          <div className="row">
            <div className="authorization-page-content">
              <Formik
                initialValues={{ email: userEmail, password: '' }}
                validationSchema={LoginSchema}
                onSubmit={this.onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="email-label" htmlFor="email">Email</label>
                        <Field  className="email-input" type="email" name="email" />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="password-label" htmlFor="password">Password</label>
                        <Field className="password-input" type="password" name="password" />
                        <ErrorMessage name="password" component="div" />
                      </div>
                    </div>
                    <div>{ this.props.error || this.state.error }</div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="remember-me">
                          <div className='checkbox-element'>
                            <input
                              type='checkbox'
                              className='checkbox'
                              checked={isChecked}
                              readOnly
                            />
                            <span onClick={this.handleCheck}></span>
                          </div>
                          <span className='label'>Remember me</span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="forgot-password-link">
                          <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                      </div>
                    </div>
                    <button className="login-button btn" type="submit" disabled={isSubmitting}>
                      Login
                    </button>
                  </Form>
                )}
              </Formik>
              <a href={`${BACKEND_URL}/connect/google`}>
                <button className="google-button btn">Login with Gmail</button>
              </a>
              <div className="account-text">
                Don't have an account?
              </div>
              <div className="signup-link">
                <Link to="/signup">Sign up here</Link>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

const mapState = (state) => ({
  loginStatus: getFetchStatus(state),
  signupStatus: getSignupStatus(state),
  error: state.user.error,
  isRememberChecked: getIsRememberChecked(state),
  userEmail: getRememberedUserEmail(state),
})

const mapDispatch = (dispatch) => ({
  onLogin: (email, password, isRememberChecked) => dispatch(login(email, password, isRememberChecked)),
  onLoginGoogle: (search) => dispatch(loginGoogle(search)),
  onClearError: () => dispatch(clearError()),
})

export default withRouter(connect(mapState, mapDispatch)(LoginPage))
