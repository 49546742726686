export default async (method, url, body = null, headers = {}) => {
  if(body) body = JSON.stringify(body);
  
  headers = {
    ...headers,
    'Content-Type': 'application/json'
  };

  try {
    const options = { method, body, headers };
    const res = await fetch(url, options);
    const json = await res.json();

    return json;
  } catch(e) {
    throw new Error(e.message);
  }
};