import React, { useEffect } from "react";
import "./index.scss";
import NoteIcon from "../../../assets/svgs/NoteIcon";
import { connect } from "react-redux";
import { getProjectData } from "../../../store/project/projectSelectors";
import { getOriginCommentCount, setCommentOriginName } from "../../../store/comment/commentActions";
import { getCommentCounts } from "../../../store/comment/commentSelectors";

const bem = "comment-origin";
const CommentOrigin = ({ name, title, project, opportunity, onSetCommentOriginName, getOriginCount, counts }) => {
	const originName = `${project?.uid}.${opportunity.originNameId}.${name}`;

	useEffect(() => {
		getOriginCount(originName);
	}, [originName, getOriginCount]);
	
	return (
		<div
			className={bem}
			onClick={() => onSetCommentOriginName({
				name: originName, title
			})}
		>
			<div className={`${bem}__icon`}>
				<NoteIcon />
				{(counts[originName] && counts[originName] > 0) ? (
					<div className={`${bem}__dot`} />
				): null}
			</div>
			<div className={`${bem}__text`}>
				Add a note
			</div>
		</div>
	);
};

const mapState = (state) => ({
	project: getProjectData(state),
	counts: getCommentCounts(state)
});

const mapDispatch = (dispatch) => ({
	onSetCommentOriginName: (data) => 
		dispatch(setCommentOriginName(data)),
  getOriginCount: (originName) =>
    dispatch(getOriginCommentCount(originName))
});

export default connect(mapState, mapDispatch)(CommentOrigin);
