// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ViewPage from '../ViewPage'
import './OpportunitySetPage.scss'
import {
  getProjectCustomerSegments,
  getSectionCount,
  getUpdateStatus,
  getAllSetOpportunities,
  getProjectData
} from '../../../../store/project/projectSelectors'
import { setAndSaveProjectData, createAndUpdateSegmentData } from '../../../../store/project/projectActions'
import { createUniqueId } from '../../../../utils/helpers'
import OpportunityContainer from './OpportunityContainer'
import {getMemberRole} from "../../../../utils/helpers";
import {getUserData} from "../../../../store/auth/authSelectors";

class OpportunitySetPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opportunities: props.opportunities,
      customerSegments: props.customerSegments,
      isNextOpportunityVisible: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerSegments !== this.props.customerSegments) {
      this.setState({
        customerSegments: this.props.customerSegments,
        opportunities: this.props.opportunities,
      })
    }
  }

  handleDeleteOpportunity = (opptId) => {
    const { onCreateAndUpdateSegmentData } = this.props
    const { customerSegments } = this.state
    let segmentId = customerSegments.findIndex(segment => segment && segment.uid === opptId)
    let opportunityId = -1

    if (segmentId !== -1) {
      if (!customerSegments[segmentId].isInList) {
        customerSegments.splice(segmentId, 1)
      } else {
        customerSegments[segmentId].isOnSet = false
      }
    } else {
      customerSegments.map(segment => {
        if (opportunityId === -1) {
          opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === opptId))
          segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
        }
      })

      if (customerSegments[segmentId].opportunities[opportunityId]) {
        if (!customerSegments[segmentId].opportunities[opportunityId].isInList) {
          customerSegments[segmentId].opportunities.splice(opportunityId, 1)
        } else {
          customerSegments[segmentId].opportunities[opportunityId].isOnSet = false
        }
      }
    }

    onCreateAndUpdateSegmentData(customerSegments)
  }

  handleOpportunityChange = ({ target: { value } }, opptId) => {
    const { opportunities } = this.state
    const opportunityId = opportunities.findIndex((oppt => oppt && oppt.uid === opptId))

    opportunities[opportunityId].title = value

    this.setState({
      opportunities,
    })
  }

  handleUpdateOpportunity = ({ target: { value } }, opptId) => {
    const { onCreateAndUpdateSegmentData } = this.props
    const { customerSegments } = this.state
    let segmentId = customerSegments.findIndex(segment => segment && segment.uid === opptId)
    let opportunityId = -1

    if (value === '') {
      this.handleDeleteOpportunity(opptId)
    } else {
      if (segmentId !== -1) {
        customerSegments[segmentId].title = value;
      } else {
        customerSegments.map(segment => {
          if (opportunityId === -1) {
            opportunityId = segment.opportunities.findIndex((oppt => oppt && oppt.uid === opptId))
            segmentId = customerSegments.findIndex(seg => seg && seg.uid === segment.uid)
          }
        })

        customerSegments[segmentId].opportunities[opportunityId].title = value
      }

      onCreateAndUpdateSegmentData(customerSegments)
    }
  }

  handleNewOpportunity = ({ target: { value } }) => {
    const { onCreateAndUpdateSegmentData } = this.props
    const { customerSegments, opportunities } = this.state

    if (value !== '') {
      let newOpportunity = {
        title: value,
        isInList: false,
        isOnSet: true,
        opportunities: [],
        sectionId: -1,
        uid: createUniqueId(),
        originNameId: createUniqueId(),
      }

      customerSegments.push(newOpportunity)
      opportunities.push(newOpportunity)

      onCreateAndUpdateSegmentData(customerSegments)
    }
  }

  toggleNewOpportunity = (value) => {
    this.setState({
      isNextOpportunityVisible: value,
    })
  }

  render() {
    const { opportunities, isNextOpportunityVisible } = this.state
    const { isWithoutNavigation } = this.props

    const readonly = getMemberRole(this.props.currentProject, this.props?.user?.user?.email) === 'canView';

    return (
        <ViewPage isWithoutNavigation={isWithoutNavigation} link='/market-opportunity-set'>
          <div className="row">
            <div className="opportunity-set-content">
              <div className="opportunity-set-element">
                <img src={'/assets/images/opportunity-set-big.png'} alt={'opportunity-set-big'} />
                { opportunities.length === 0 && !isNextOpportunityVisible ? (
                  <div className="opportunity-set-controls empty">
                    <div className="no-opportunity">
                      <div className="no-opportunity-text">
                        Select the market opportunities from
                        <br />
                        <Link className="no-opportunity-text link" to={'/worksheet-1'}>Worksheet 1</Link>
                        {' '}
                        to appear here. You can also add opportunities or edit them manually
                      </div>

                      <div onClick={() => this.toggleNewOpportunity(true)}
                           className={`plus-sign empty ${readonly ? 'readonly' : ''}`}>
                        +
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="opportunity-set-controls">
                    <div className="opportunity-wrapper">
                      <div className="has-opportunity">
                        {
                          opportunities.map((opportunity, key) => {
                            if(opportunity.isOnSet) {
                              return (
                                <OpportunityContainer
                                  key={key}
                                  opportunity={opportunity}
                                  onDeleteOpportunity={this.handleDeleteOpportunity}
                                  onOpportunityChange={this.handleOpportunityChange}
                                  onUpdateOpportunity={this.handleUpdateOpportunity}
                                  isWithoutNavigation={isWithoutNavigation}
                                />
                                )
                              }
                            }
                          )
                        }
                        {isNextOpportunityVisible &&
                          <OpportunityContainer
                            opportunity={null}
                            onDeleteOpportunity={this.handleDeleteOpportunity}
                            onOpportunityChange={this.handleOpportunityChange}
                            onUpdateOpportunity={this.handleUpdateOpportunity}
                            onNewOpportunity={this.handleNewOpportunity}
                            onToggleNewOpportunity={this.toggleNewOpportunity}
                            isWithoutNavigation={isWithoutNavigation}
                          />
                        }
                        <div onClick={() => this.toggleNewOpportunity(true)}
                            className={`plus-sign ${readonly ? 'readonly' : ''}`}>
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                ) }
              </div>
              {!isWithoutNavigation &&
                <Link to={'/attractiveness-map'} className="next-screen-element">
                  <img src={'/assets/images/attractiveness-map-big.png'} alt={'/assets/images/attractiveness-map-big'} />
                </Link>
              }
              {!isWithoutNavigation &&
                <Link to={'/agile-focus-dartboard'} className="third-screen-element">
                  <img src={'/assets/images/agile-focus-big.png'} alt={'agile-focus-big'} />
                </Link>
              }
            </div>
          </div>
        </ViewPage>
    )
  }
}

const mapState = (state) => ({
  opportunities: getAllSetOpportunities(state),
  customerSegments: getProjectCustomerSegments(state),
  sectionCount: getSectionCount(state),
  updateStatus: getUpdateStatus(state),
  currentProject: getProjectData(state),
  user: getUserData(state),
})

const mapDispatch = (dispatch) => ({
  onSetAndSaveProjectData: (name, value) => dispatch(setAndSaveProjectData(name, value)),
  onCreateAndUpdateSegmentData: (segments) => dispatch(createAndUpdateSegmentData(segments)),
})


export default connect(mapState, mapDispatch)(OpportunitySetPage)
