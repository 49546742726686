import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="80"
      fill="none"
      version="1.1"
      viewBox="0 0 40 80"
      className="timer-half-circle"
    >
      <g
        stroke="#cdd1d7"
        strokeOpacity="1"
        strokeWidth="4"
        transform="translate(0 40)"
      >
        <path d="M40 38A38 38 0 012 0"></path>
        <path d="M2 0a38 38 0 0138-38"></path>
      </g>
    </svg>
  );
}

export default Icon;
