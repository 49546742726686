// helpers
import { debounce } from "../../../utils/helpers";
import React from "react";
import CloudDownload from "../../../assets/svgs/CloudDownload";

const ChatDownload = ({ chats }) => {
  const formatChats = () => {
    const txt = chats.map((chat) => {
      const user = chat.user.username;
      return `[${user}]: ${chat.messageText}`;
    });

    return txt;
  };

  const generateLink = () => {
    // RETURN IF NO CHATS ARE PRESENT
    if (!chats.length) return;

    const chatFileUrl = new Blob([formatChats().join("\n\n")], {
      type: "text/plain;charset=utf-8",
    });

    return URL.createObjectURL(chatFileUrl);
  };

  const downloadFile = debounce({
    callback: () => {
      const chatFileUrl = generateLink();
      const anchor = document.createElement("a");
      anchor.href = chatFileUrl;
      anchor.download = `chats_${Date.now()}.txt`;
      anchor.style.display = "none";
      document.body.appendChild(anchor);

      anchor.click(); // TRIGGER DOWNLOAD

      // CLEANUP
      document.body.removeChild(anchor);
      URL.revokeObjectURL(chatFileUrl);
    },
    delay: 1000,
  });

  return (
    <button
      onClick={downloadFile}
      className="p-0 border-0 mr-3 bg-transparent chat-download-btn"
    >
      <CloudDownload />
    </button>
  );
};

export default React.memo(ChatDownload);
