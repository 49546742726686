// @flow
import React, {Component, useEffect, useState} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import {signup, clearError} from '../../../store/auth/authActions'
import './AuthorizationPage.scss'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Too Short!')
    .required('Required'),
  select: Yup.string()
    .required('Required')
})

const SignupViaInvitePage = ({error, onSignup, history, onClearError, match}) => {

  const [stateError, setStateError] = useState(null);

  useEffect(() => {
    onClearError()
  }, [])

  const onSubmit = async (values, {setSubmitting}) => {
    setStateError('');
    const res = await onSignup(values.email, values.password, values.select);

    if (!error) {
      history.push('/login')
      // TODO: VERZE 2
      // this.setState({ error: 'Verification email has been sent to your email.' })
    } else {
      setStateError('Signup failed');
    }
    setSubmitting(false)
  }


  return (
    <div className="authorization-page">
      <a href="https://wheretoplay.co/">
          <img className="login-image" src='/assets/images/login-image.png' alt="login" />
        </a>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title">
              Sign Up via invite
            </div>
          </div>
        </div>
        <div className="row">
          <div className="authorization-page-content">
            <Formik
              initialValues={{email: match.params.email, password: '', select: ''}}
              validationSchema={SignupSchema}
              onSubmit={onSubmit}
            >
              {({isSubmitting}) => (
                <Form>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="email-label" htmlFor="email">Email</label>
                      <Field type="email" name="email" disabled={true}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="password-label" htmlFor="password">Password</label>
                      <Field type="password" name="password"/>
                      <ErrorMessage name="password" component="div"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="password-label" htmlFor="select">About you</label>
                      <Field as="select" name="select">
                        <option defaultValue value="">-- Please select --</option>
                        <option value="Entrepreneur">Entrepreneur</option>
                        <option value="Business manager">Business manager</option>
                        <option value="Educator / mentor">Educator / mentor</option>
                        <option value="Want to be entrepreneur">Want to be entrepreneur</option>
                        <option value="Tech transfer team member">Tech transfer team member</option>
                        <option value="Investor">Investor</option>
                        <option value="Consultant">Consultant</option>
                        <option value="Other">Other</option>
                      </Field>
                      <ErrorMessage name="select" component="div"/>
                    </div>
                  </div>
                  <div>{error || stateError}</div>
                  <button className="btn signup-button" type="submit" disabled={isSubmitting}>
                    Signup
                  </button>
                </Form>
              )}
            </Formik>
            <div className="back-to-login">
              Already have an account?
              {' '}
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  signupStatus: state.user.fetchStatus,
  error: state.user.error
})

const mapDispatch = (dispatch) => ({
  onSignup: (email, password, select) => dispatch(signup(email, password, select)),
  onClearError: () => dispatch(clearError()),
})

export default withRouter(connect(mapState, mapDispatch)(SignupViaInvitePage))
