import { getOverallIndex, getProjectChatIndex } from "../../utils/helpers";
import { defaultChat, defaultChatSession } from "../../utils/constants";

export const getProjects = (state) => state.project.projects;

export const getProjectData = (state) => state.project.currentProject;

export const getProjectTitle = (state) => state.project.currentProject.title;

export const getProjectCustomerSegments = (state) =>
  state.project.currentProject.customerSegment
    ? [...state.project.currentProject.customerSegment]
    : [];

export const getSectionCount = (state) =>
  state.project.currentProject.sectionCount;

export const getSelectOpportunitySectionCount = (state) =>
  state.project.currentProject.selectOpportunitySectionCount;

export const getUpdateStatus = (state) => state.project.updateStatus;

export const getDeleteStatus = (state) => state.project.deleteStatus;

export const getFetchStatus = (state) => state.project.fetchStatus;

export const getFetchAllStatus = (state) => state.project.fetchAllStatus;

export const getSaveStatus = (state) => state.project.saveStatus;

export const getProjectStatus = (state) => state.project.projectStatus;

export const getAbilities = (state) =>
  state.project.currentProject.abilities
    ? state.project.currentProject.abilities
    : [];

export const getAbilitiesSectionCount = (state) =>
  state.project.currentProject.abilitiesSectionCount;

export const getCurrentAttractivenessOpportunityId = (state) =>
  state.project.currentAttractivenessOpportunityId;

export const getCurrentAgileFocusPrimaryOpportunityId = (state) =>
  state.project.currentProject.currentAgileFocusPrimaryOpportunityId;

export const getCurrentAgileFocusOpportunityIds = (state) =>
  state.project.currentProject.currentAgileFocusOpportunityIds;

export const getNotesVisibility = (state) => state.project.notesVisibility;

export const getProjectNotes = (state) => state.project.currentProject.notes;

export const getComments = (state) => state.project.currentProject.comments;

export const getExportData = (state) => state.project.exportData;

export const getApplications = (state) =>
  state.project.currentProject.applications
    ? state.project.currentProject.applications
    : [];

export const getPdfStatus = (state) => state.project.pdfStatus;

export const getProjectsCount = (state) => state.project.projectsCount;

export const getAllSetOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  const applications = getApplications(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      const app =
        applications.find((v) => v.sectionId === segment.sectionId) || null;

      if (segment && segment.isOnSet) {
        opportunities.push({
          ...segment,
          app,
          segmendUid: null,
        });
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (opportunity && opportunity.isOnSet) {
            opportunities.push({
              ...opportunity,
              app,
              segmendUid: segment.uid,
            });
          }
        });
      }
    });
  }

  return opportunities;
};

export const getAllOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      if (segment) {
        opportunities.push(segment);
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (opportunity) {
            opportunities.push(opportunity);
          }
        });
      }
    });
  }

  return opportunities;
};

export const getAllAttractivenessOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  const applications = getApplications(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      const app = segment
        ? applications.find((v) => v.sectionId === segment.sectionId)
        : null;

      if (
        segment &&
        segment.isOnSet &&
        segment.overallPotential > 0 &&
        segment.overallChallenge > 0
      ) {
        let item = {};

        item.overallPotential = getOverallIndex(segment.overallPotential);
        item.overallChallenge = getOverallIndex(segment.overallChallenge);
        item.title = segment.title;
        item.uid = segment.uid;

        opportunities.push({
          ...item,
          app,
        });
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (
            opportunity &&
            opportunity.isOnSet &&
            opportunity.overallPotential > 0 &&
            opportunity.overallChallenge > 0
          ) {
            let item = {};

            item.overallPotential = getOverallIndex(
              opportunity.overallPotential
            );
            item.overallChallenge = getOverallIndex(
              opportunity.overallChallenge
            );
            item.title = opportunity.title;
            item.uid = opportunity.uid;

            opportunities.push({
              ...item,
              app,
            });
          }
        });
      }
    });
  }

  return opportunities;
};

export const isOpportunityTaken = (opportunity, state) => {
  const currentPrimaryOpportunityId =
    getCurrentAgileFocusPrimaryOpportunityId(state);
  const currentAgileFocusOpportunityIds =
    getCurrentAgileFocusOpportunityIds(state);
  let isTaken = false;

  if (opportunity.uid === currentPrimaryOpportunityId) {
    isTaken = true;
  } else if (currentAgileFocusOpportunityIds) {
    currentAgileFocusOpportunityIds.map((currentOpportunity) => {
      if (opportunity.uid === currentOpportunity.opportunityId) {
        isTaken = true;
      }
    });
  }

  return isTaken;
};

export const getNonTakenAgileFocusDropdownOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  const applications = getApplications(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      const app = segment
        ? applications.find((v) => v.sectionId === segment.sectionId)
        : null;

      if (segment && segment.isOnSet) {
        if (!isOpportunityTaken(segment, state)) {
          opportunities.push({
            ...segment,
            app,
          });
        }
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (opportunity && opportunity.isOnSet) {
            if (!isOpportunityTaken(opportunity, state)) {
              opportunities.push({
                ...opportunity,
                app,
              });
            }
          }
        });
      }
    });
  }

  return opportunities;
};

export const getAgileFocusDropdownOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  const applications = getApplications(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      const app =
        applications.find((v) => v.sectionId === segment.sectionId) || null;

      if (segment && segment.isOnSet) {
        opportunities.push({
          ...segment,
          app,
        });
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (opportunity && opportunity.isOnSet) {
            opportunities.push({
              ...opportunity,
              app,
            });
          }
        });
      }
    });
  }

  return opportunities;
};

export const getAllAgileFocusOpportunities = (state) => {
  const customerSegments = getProjectCustomerSegments(state);
  const applications = getApplications(state);
  let opportunities = [];

  if (customerSegments) {
    customerSegments.map((segment) => {
      const app =
        applications.find((v) => v.sectionId === segment.sectionId) || null;

      if (
        segment &&
        segment.isOnSet &&
        segment.agileFocusOption &&
        isOpportunityTaken(segment, state)
      ) {
        let item = {};

        item.agileFocusOption = segment.agileFocusOption;
        item.title = segment.title;

        opportunities.push({ ...item, app });
      }

      if (segment && segment.opportunities) {
        segment.opportunities.map((opportunity) => {
          if (
            opportunity &&
            opportunity.isOnSet &&
            opportunity.agileFocusOption &&
            isOpportunityTaken(opportunity, state)
          ) {
            let item = {};

            item.agileFocusOption = opportunity.agileFocusOption;
            item.title = opportunity.title;

            opportunities.push({ ...item, app });
          }
        });
      }
    });
  }

  return opportunities;
};

export const getCurrentOpportunityId = (
  currentAgileFocusOpportunityIds,
  sectionId
) => {
  if (currentAgileFocusOpportunityIds) {
    const currentOpportunity = currentAgileFocusOpportunityIds.find(
      (opportunity) => opportunity.sectionId === sectionId
    );

    if (currentOpportunity) {
      return currentOpportunity.opportunityId;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getChats = (state) => {
  const chats = state.project.currentProject?.chats;
  return chats && chats.length ? chats : [];
};

export const getCurrentChatSession = (state) => {
  const currentProjectId = state.project.currentProject.id;
  const allProjectChatSessions = state.project.chatSessions;

  const currentProjectChatIndex = getProjectChatIndex(
    currentProjectId,
    allProjectChatSessions
  );

  if (currentProjectChatIndex !== -1) {
    const currentProjectChatSession =
      allProjectChatSessions[currentProjectChatIndex];

    return currentProjectChatSession;
  }

  return {
    ...defaultChatSession,
  };
};

export const getAllProjectsChatSessionState = (state) =>
  state.project?.chatSessions ?? [];

export const getAllProjectsChatState = (state) =>
  state.project?.projectChats ?? [];

export const getCurrentChat = (state) => {
  const currentProjectId = state.project.currentProject.id;
  const allProjectsChat = state.project.projectChats;

  const currentProjectChatIndex = getProjectChatIndex(
    currentProjectId,
    allProjectsChat
  );

  if (currentProjectChatIndex !== -1) {
    const currentProjectChat = allProjectsChat[currentProjectChatIndex];
    return currentProjectChat;
  }

  return {
    ...defaultChat,
  };
};
