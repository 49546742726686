import {
  apiDeleteComment,
  apiGetCommentsFromComment,
  apiGetCommentsFromOrigin,
  apiGetOriginCommentCount,
  apiPostCommentToComment,
  apiPostCommentToOrigin
} from "../../api/comment";

export const SET_COMMENT_ORIGIN_NAME = "SET_COMMENT_ORIGIN_NAME";

export const HIDE_REPLY_SECTION = "HIDE_REPLY_SECTION";

export const COMMENT_FETCH_START = "COMMENT_FETCH_START";
export const COMMENT_FETCH_SUCCESS = "COMMENT_FETCH_SUCCESS";
export const COMMENT_FETCH_FAIL = "COMMENT_FETCH_FAIL";

export const COMMENT_POST_START = "COMMENT_POST_START";
export const COMMENT_POST_SUCCESS = "COMMENT_POST_SUCCESS";
export const COMMENT_POST_FAIL = "COMMENT_POST_FAIL";

export const COMMENT_FROM_COMMENT_FETCH_START = "COMMENT_FROM_COMMENT_FETCH_START";
export const COMMENT_FROM_COMMENT_FETCH_SUCCESS = "COMMENT_FROM_COMMENT_FETCH_SUCCESS";
export const COMMENT_FROM_COMMENT_FETCH_FAIL = "COMMENT_FROM_COMMENT_FETCH_FAIL";

export const COMMENT_POST_TO_COMMENT_START = "COMMENT_POST_TO_COMMENT_START";
export const COMMENT_POST_TO_COMMENT_SUCCESS = "COMMENT_POST_TO_COMMENT_SUCCESS";
export const COMMENT_POST_TO_COMMENT_FAIL = "COMMENT_POST_TO_COMMENT_FAIL";

export const ORIGIN_COMMENT_COUNT_START = "ORIGIN_COMMENT_COUNT_START";
export const ORIGIN_COMMENT_COUNT_SUCCESS = "ORIGIN_COMMENT_COUNT_SUCCESS";
export const ORIGIN_COMMENT_COUNT_FAIL = "ORIGIN_COMMENT_COUNT_FAIL";

export const COMMENT_DELETE_START = "COMMENT_DELETE_START";
export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS";
export const COMMENT_DELETE_FAIL = "COMMENT_DELETE_FAIL";

const fetchCommentsStart = () => 
  ({ type: COMMENT_FETCH_START });
const fetchCommentsSuccess = (comments) => 
  ({ type: COMMENT_FETCH_SUCCESS, comments });
const fetchCommentsFail = () => 
  ({ type: COMMENT_FETCH_FAIL });

const postCommentStart = () => 
  ({ type: COMMENT_POST_START });
const postCommentSuccess = (name, comment) => 
  ({ type: COMMENT_POST_SUCCESS, name, comment });
const postCommentFail = () => 
  ({ type: COMMENT_POST_FAIL });

const fetchCommentsFromCommentStart = () => 
  ({ type: COMMENT_FROM_COMMENT_FETCH_START });
const fetchCommentsFromCommentSuccess = (id, comments) => 
  ({ type: COMMENT_FROM_COMMENT_FETCH_SUCCESS, id, comments });
const fetchCommentsFromCommentFail = () => 
  ({ type: COMMENT_FROM_COMMENT_FETCH_FAIL });

const postCommentToCommentStart = () => 
  ({ type: COMMENT_POST_TO_COMMENT_START });
const postCommentToCommentSuccess = (id, comment) => 
  ({ type: COMMENT_POST_TO_COMMENT_SUCCESS, id, comment });
const postCommentToCommentFail = () => 
  ({ type: COMMENT_POST_TO_COMMENT_FAIL });

const originCommentCountStart = () => 
  ({ type: ORIGIN_COMMENT_COUNT_START });
const originCommentCountSuccess = (name, count) => 
  ({ type: ORIGIN_COMMENT_COUNT_SUCCESS, name, count });
const originCommentCountFail = () => 
  ({ type: ORIGIN_COMMENT_COUNT_FAIL });

const commentDeleteStart = () =>
  ({ type: COMMENT_DELETE_START });
const commentDeleteSuccess = (id) =>
  ({ type: COMMENT_DELETE_SUCCESS, id });
const commentDeleteFail = () =>
  ({ type: COMMENT_DELETE_FAIL });

export const setCommentOriginName = (data) => ({ 
  type: SET_COMMENT_ORIGIN_NAME, data });

export const hideReplySection = (id) => ({
  type: HIDE_REPLY_SECTION, id }); 

export const fetchCommentsFromOrigin = (name) => 
  async (dispatch) => {
    dispatch(fetchCommentsStart());

    return apiGetCommentsFromOrigin(name)
      .then((res) => {
        const comments = res?.data?.commentOrigins.data[0]?.attributes.comments.data || [];

        dispatch(fetchCommentsSuccess(comments))
      })
      .catch(() => {
        dispatch(fetchCommentsFail());
      });
  };

export const fetchCommentsFromComment = (commentId) =>
  async (dispatch) => {
    dispatch(fetchCommentsFromCommentStart());

    return apiGetCommentsFromComment(commentId)
      .then((res) => {
        const comments = res?.data?.commentItem?.data.attributes.tail.data || [];
        dispatch(fetchCommentsFromCommentSuccess(commentId, comments));
      })
      .catch((e) => {
        dispatch(fetchCommentsFromCommentFail());
      });
  }

export const postCommentToOrigin = (originName, userId, content) => 
  async (dispatch) => {
    dispatch(postCommentStart());

    return apiPostCommentToOrigin(originName, userId, content)
      .then((res) => {
        const comment = res?.data?.createCommentItem?.data || null;

        if(comment) dispatch(postCommentSuccess(originName, {
          ...comment,
          content
        }));

        else dispatch(postCommentFail());
      })
      .catch(() => {
        dispatch(postCommentFail());
      });
  };

export const postCommentToComment = (commentId, userId, content) =>
  async (dispatch) => {
    dispatch(postCommentToCommentStart());

    return apiPostCommentToComment(commentId, userId, content)
      .then((res) => {
        const comment = res?.data?.createCommentItem?.data || null;

        if(comment) dispatch(postCommentToCommentSuccess(commentId, {
          ...comment,
          content
        }));

        else dispatch(postCommentToCommentFail());
      })
      .catch((e) => {
        console.log(e)
        dispatch(postCommentToCommentFail())
      });
  };

export const getOriginCommentCount = (originName) =>
  async (dispatch) => {
    dispatch(originCommentCountStart());

    return apiGetOriginCommentCount(originName)
      .then((count) => {
        dispatch(originCommentCountSuccess(originName, count));
      })
      .catch(() => {
        dispatch(originCommentCountFail());
      });
  }

export const deleteComment = (commendId) =>
  async (dispatch) => {
    dispatch(commentDeleteStart());

    return apiDeleteComment(commendId)
      .then(() => {
        dispatch(commentDeleteSuccess(commendId));
      })
      .catch((e) => {
        console.log(e)
        dispatch(commentDeleteFail());
      });
  };