import React, {Fragment, useEffect, useState} from 'react'

import TooltipItem from './TooltipItem'
import './OpportunitySection.scss'

import Trash from '../../../assets/svgs/Trash'
import Send from '../../../assets/svgs/Send'
import {Tooltip} from "reactstrap";

const OpportunitySection = ({
  sectionId,
  customerSegments,
  onNewSegment,
  onUpdateSegment,
  onSegmentChange,
  onUpdateOpportunity,
  onNewOpportunity,
  onOpportunityChange,
  onSubmitFromKeyboard,
  onDeleteOpportunity,
  onSetOpportunity,
  onSetSegment,
  onDeleteSegment,
  onDeleteSection,
  onToggleSubSegmentVisibility,
  isSubSegmentVisibleObject,
  onUpdateApplication,
  onApplicationChange,
  application,
  readonly,
  opportunityCount,
  pdf,
}) => {
  const [tooltipActive, setTooltipActive] = useState({});

  const toggleTooltip = (id) => {
    setTooltipActive({
      ...tooltipActive,
      [id]: tooltipActive[id] ? !tooltipActive[id]: true
    });
  };

  return <div className="opportunity-section">
    <div className="applications-section">
      <div className='arrow' />
      <img src="/assets/icons/box.png" alt="box"/>

      <input
        className="application-input"
        onBlur={e => onUpdateApplication(e, sectionId)}
        value={application && application.title ? application.title : ''}
        onChange={e => onApplicationChange(e, sectionId)}
        onKeyDown={onSubmitFromKeyboard}
        disabled={readonly}
        maxLength="50"
      />

      {sectionId > 3 && (
        <img
          className="delete-icon"
          src="/assets/icons/add-icon.png"
          alt="delete"
          onClick={() => onDeleteSection()}
        />
      )}
    </div>

    <div className="customer-section">
      <img src="/assets/icons/customers-icon.png" alt="customers"/>

      {customerSegments.map((segment, segmentId) => {
        if (segment && segment.isInList) {
          return (
            <Fragment key={segment.uid}>
              <div className="customer-input segment-input set">
                <input
                  className={`${segment.isOnSet ? 'is-on-set' : ''}`}
                  placeholder="Type customer segment here"
                  onBlur={e => onUpdateSegment(e, segment.uid)}
                  value={segment.title ? segment.title : ''}
                  onChange={e => onSegmentChange(e, segment.uid)}
                  onKeyDown={onSubmitFromKeyboard}
                  disabled={readonly}
                  maxLength="50"
                />

                <div className="segment-menu">
                  {!segment.isOnSet && opportunityCount < 9 && (
                    <>
                      {!pdf &&
                        <Tooltip
                          target={`sendOpportunity${segment.uid}`}
                          placement="auto"
                          isOpen={tooltipActive[segment.uid] || false}
                          toggle={() => toggleTooltip(segment.uid)}
                          trigger="hover"
                        >
                          Send to Market Opportunity Set
                        </Tooltip>
                      }

                      <div
                        id={`sendOpportunity${segment.uid}`}
                        onClick={() => onSetSegment(segment.uid)}
                        className={`send-section ${readonly ? 'readonly' : ''}`}
                      >
                        <Send/>
                      </div>
                    </>
                  )}

                  <div onClick={() => onDeleteSegment(segment.uid)}
                       className={`delete-section ${readonly ? 'readonly' : ''}`}>
                    <Trash/>
                  </div>
                </div>
              </div>

              {segment.opportunities && segment.opportunities.map((opportunity, id) => {
                if (opportunity && opportunity.isInList) {

                  return (
                    <div key={opportunity.uid} className="sub segment-input">
                      <input
                        className={`${opportunity.isOnSet ? 'is-on-set' : ''}`}
                        placeholder="Type sub segment here"
                        onBlur={e => onUpdateOpportunity(e, opportunity.uid, segment.uid)}
                        value={opportunity.title ? opportunity.title : ''}
                        onChange={e => onOpportunityChange(e, opportunity.uid, segment.uid)}
                        onKeyDown={onSubmitFromKeyboard}
                        disabled={readonly}
                        maxLength="50"
                      />

                      {
                        isSubSegmentVisibleObject.length > 0 &&
                        isSubSegmentVisibleObject[isSubSegmentVisibleObject.findIndex(x => x.id === segment.uid)] &&
                        !isSubSegmentVisibleObject[isSubSegmentVisibleObject.findIndex(x => x.id === segment.uid)].isSubSegmentVisible &&
                        segment.opportunities.length > 0 &&
                        id === segment.opportunities.length - 1 &&
                        <TooltipItem
                          type="toggle-sub-segment"
                          onClick={() => onToggleSubSegmentVisibility(segment.uid, true)}
                          segmentId={segmentId}
                          readonly={readonly}
                        />
                      }

                      <div className="segment-menu">
                        {!opportunity.isOnSet && opportunityCount < 9 && (
                          <>
                          {!pdf &&
                            <Tooltip
                              target={`sendSubOpportunity${opportunity.uid}`}
                              placement="auto"
                              isOpen={tooltipActive[opportunity.uid] || false}
                              toggle={() => toggleTooltip(opportunity.uid)}
                              trigger="hover"
                            >
                              Send to Market Opportunity Set
                            </Tooltip>
                          }
                            <div
                              id={`sendSubOpportunity${opportunity.uid}`}
                              onClick={() => onSetOpportunity(opportunity.uid, segment.uid)}
                              className={`send-section ${readonly ? 'readonly' : ''}`}
                            >
                              <Send/>
                            </div>
                          </>
                        )}

                        <div onClick={() => onDeleteOpportunity(opportunity.uid, segment.uid)}
                             className={`delete-section ${readonly ? 'readonly' : ''}`}>
                          <Trash/>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
              {
                isSubSegmentVisibleObject.length > 0 &&
                isSubSegmentVisibleObject[isSubSegmentVisibleObject.findIndex(x => x.id === segment.uid)] &&
                isSubSegmentVisibleObject[isSubSegmentVisibleObject.findIndex(x => x.id === segment.uid)].isSubSegmentVisible &&
                <div className="sub segment-input">
                  <input
                    placeholder="Type sub segment here"
                    onBlur={e => {
                      onNewOpportunity(e, segment.uid)
                      e.target.value = ''
                    }}
                    onKeyDown={onSubmitFromKeyboard}
                    disabled={readonly}
                    maxLength="50"
                  />
                </div>
              }
            </Fragment>
          )
        }
      })}

      <div className="customer-input segment-input">
        <input
          placeholder="Type customer segment here"
          onBlur={e => {
            onNewSegment(e, sectionId)
            e.target.value = ''
          }}
          onKeyDown={onSubmitFromKeyboard}
          disabled={readonly}
          maxLength="50"
        />
      </div>
    </div>
  </div>
}

export default OpportunitySection
