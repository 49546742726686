import React, { Component } from 'react'

import './AttractivenessSection.scss'

class ArrowComponent extends Component {
  render() {
    const { currentSectionId, previousSectionId, name, id, onClick, readonly } = this.props
    let onClickId
    let isFilled = false

    // if (previousSectionId > currentSectionId) {
    //   if ((currentSectionId >= id - 1 && currentSectionId < id + 1) || (currentSectionId === 8 && id === 7)) {
    //     onClickId = 0
    //     isFilled = true
    //   } else {
    //     onClickId = id
    //     isFilled = false
    //   }
    // }

    if ((currentSectionId >= id && currentSectionId < id + 2) || (currentSectionId === 1 && id === 2) || (currentSectionId === 8 && id === 7)) {
      onClickId = 0
      isFilled = true
    } else {
      onClickId = id
      isFilled = false
    }

    if (currentSectionId === 0) {
      isFilled = false
      onClickId = id
    }

    return (
      <div onClick={() => onClick(name, onClickId)} key={id} className={`arrow-section ${isFilled  ? 'filled' : ''} ${readonly ? 'readonly' : ''}`} />
    )
  }
}

export default ArrowComponent
